import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States

import {
  apiError,
  getProfileList,
  profileListSuccessful,
  permissionsListSuccessful,
  profileByIdSuccessful,
} from "./profileSlice";

// AUTH related methods
import {
  postAdd,
  getList,
  getParamsList,
  postEdit,
  patchEdit,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

function* profileList({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/users/groups/", params);
    yield put(profileListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* permissionsList() {
  try {
    const response = yield call(getParamsList, "/users/apps/permissions/", {});
    yield put(permissionsListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* profileSubmit({ payload }) {
  try {
    if (payload.profileObj.id == 0) {
      const response = yield call(
        postAdd,
        "/users/groups/",
        payload.profileObj
      );

      if (response.status == 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Profile Added Successfully.!
              </p>
            </div>
          ),
        });
        payload.history.push("/pages/settings/manage-profile");
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to add profile. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response2 = yield call(
        postEdit,
        "/users/groups/" + payload.profileObj.id,
        payload.profileObj
      );

      if (response2.status == 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                profile Edited Successfully.!
              </p>
            </div>
          ),
        });
        payload.history.push("/pages/settings/manage-profile");
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit profile. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    yield put(apiError(error));
    if (error.response.status == 400) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.name[0]}
            </p>
          </div>
        ),
      });
    } else if (error.response.status == 500) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
  }
}
function* profilebyID({ payload }) {
  try {
    const response = yield call(getList, "/users/groups/" + payload, {});
    yield put(profileByIdSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* deleteProfile({ payload }) {
  try {
    const response = yield call(postDelete, "/users/groups/" + payload);

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Profile has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getProfileList({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Profile has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

export function* watchGetProfileList() {
  yield takeEvery("manageProfile/getProfileList", profileList);
}
export function* watchGetPermissionsList() {
  yield takeEvery("manageProfile/getPermissionsList", permissionsList);
}
export function* watchSubmitProfile() {
  yield takeEvery("manageProfile/submitProfile", profileSubmit);
}

export function* watchGetProfilebyID() {
  yield takeEvery("manageProfile/getProfilebyID", profilebyID);
}
export function* watchProfileDelete() {
  yield takeEvery("manageProfile/profileDelete", deleteProfile);
}

function* ManageProfileSaga() {
  yield all([
    fork(watchGetProfileList),
    fork(watchGetPermissionsList),
    fork(watchSubmitProfile),
    fork(watchGetProfilebyID),
    fork(watchProfileDelete),
  ]);
}

export default ManageProfileSaga;
