import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  apiError,
  getPurchaseTransactionList,
  purchaseTransactionListSuccessful,
  saleTransactionListSuccessful,
  transactionApprovalScreen,
  transationCountListSuccessful,
} from "./transactionApproveSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

//If user is login then dispatch redux action's are directly from here.
function* purchaseTransactionListGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/purchase/approvalto/", params);
    yield put(purchaseTransactionListSuccessful({ response }));
    if(response.results.length != 0){
      if(payload.page == 1 ){
        yield put(transactionApprovalScreen({
          Type: "purchase",
          id:response.results[0]?.id,
        }));
      }
      
    }
    else{
      yield put(transactionApprovalScreen({
        Type: "none",
        id:0,
      }));
    }
    
  }
  catch (error) {
    yield put(apiError(error));
  }
}


function* SaleTransactionListGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/sale/approvalto/", params);
    yield put(saleTransactionListSuccessful({ response }));
    if(response.results.length != 0){
      if(payload.page == 1 ){
        yield put(transactionApprovalScreen({
          Type: "sale",
          id:response.results[0]?.id,
        }));
      }
      
    }
    else{
      yield put(transactionApprovalScreen({
        Type: "none",
        id:0,
      }));
    }
    

  }
  catch (error) {
    yield put(apiError(error));
  }
}

function* GetTransationCount({ payload }) {
  console.log("GetTransationCount", payload);
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/sale/salescount/", params);
    yield put(transationCountListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetPurchaseTransactionList() {
  yield takeEvery("transactionApprove/getPurchaseTransactionList", purchaseTransactionListGet);
}

export function* watchGetSaleTransactionList() {
  yield takeEvery("transactionApprove/getSaleTransactionList", SaleTransactionListGet);
}
export function* watchTransationCount() {
  yield takeEvery("transactionApprove/transationCount", GetTransationCount);
}
function* transactionApproveSaga() {
  yield all([
    fork(watchGetPurchaseTransactionList),
    fork(watchGetSaleTransactionList),
    fork(watchTransationCount),
  ]);
}

export default transactionApproveSaga;