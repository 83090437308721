import { createSlice } from "@reduxjs/toolkit";

export const organizationSlice = createSlice({
  name: "organization",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    organizationList: [],
    model: false,
    error_msg: null,
    rowdata: {
      editable: false
    },
    listCount: 0,
   organizationParams: {
      no_of_pages: 0,  
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getOrganization: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    organizationListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.organizationParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        organizationList: action.payload.response.results,
        loading: false,
        organizationParams: {
          ...state.organizationParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetOrganizationParams: (state, action) => {
      return {
        ...state,
        organizationParams: action.payload,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    organizationAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getOrganizationByID: (state) => {
      return {
        ...state,
        loading: true,
        rowdata: {}
      };
    },
    organizationByIdSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        model: true,
        rowdata: {
          id: action.payload.id,
          name: action.payload.name,
          authkey: action.payload.authkey,
          is_active: action.payload.is_active,
        },
      };
    },
    organizationDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getOrganization,
  organizationListSuccessful,
  SetOrganizationParams,
  InputChangeValue,
  isModelVisible,
  organizationAdd,
  getOrganizationByID,
  organizationByIdSuccessful,
  organizationDelete,
  apiError,
} = organizationSlice.actions;

export default organizationSlice.reducer;
