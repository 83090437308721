import { createSlice } from "@reduxjs/toolkit";

export const repairTypesSlice = createSlice({
  name: "repairTypes",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    repairTypeslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    repairTypesParams: {
      no_of_pages: 0,
      listCount: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getRepairTypes: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    repairTypesListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.repairTypesParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        repairTypeslist: action.payload.response.results,
        loading: false,
        repairTypesParams: {
          ...state.repairTypesParams,
          no_of_pages: noofpages,
        },
      };
    },
    repairTypesAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    repairTypesAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    repairTypesEdit: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: action.payload,
      };
    },
    repairTypesEditSuccessful: (state) => {
      return { ...state, loading: false, rowdata: {} };
    },
    repairTypesDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetRepairTypesParams: (state, action) => {
      return {
        ...state,
        repairTypesParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getRepairTypes,
  repairTypesListSuccessful,
  repairTypesAdd,
  repairTypesAddSuccessful,
  repairTypesEdit,
  repairTypesEditSuccessful,
  repairTypesDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetRepairTypesParams
} = repairTypesSlice.actions;

export default repairTypesSlice.reducer;
