import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
export const idealStockSlice = createSlice({
  name: "idealStock",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    model: false,
    drawer: false,
    apiError: {},
    listCount: 0,
    selectStockVersion: [],
    idealStockVersionList: [],
    idealStockVersionParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    idealStockData: {},
    idealStockList: [],
    idealStockParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    updateStockList : [],
    updateStockParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    numberOfItems: 0
  },
  reducers: {
    clearData: (state) => {
      return {
        ...state,
        loading: false,
        idealStockVersionList: [],
        idealStockData: {},
        selectStockVersion: [],
        idealStockList: [],
        updateStockList:[],
        updateStockParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        idealStockParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
      };
    },

    getIdealStockList: (state, action) => {
      if (state.idealStockParams?.page == 1) {
        return {
          ...state,
          loading: true,
          idealStockList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },

    idealStockListSuccessful: (state, action) => {
      var numberOfItems = state.numberOfItems
      var response = action.payload;
      var stockVersionList = response.map((item) => {
        numberOfItems += 1;
        return {
          _id: numberOfItems,
          version_id: item.version?.id,
          id: item.id,
          name: item.version?.name,
          brand: item.version?.brand,
          brandfamily: item.version?.brandfamily,
          model: item.version?.model,
          limit: item.limit,
          year: moment(item.year, "YYYY"),
          dodelete: false,
        };
      });
      return {
        ...state,
        loading: false,
        numberOfItems: numberOfItems,
        selectStockVersion: stockVersionList,
      };

      // var list = [];
      // if (state.idealStockParams?.page == 1) {
      //   list = response;
      // } else {
      //   list = response.results;
      // }
      // var noofpages = Math.ceil(
      //   action.payload.count / state.idealStockParams?.page_size
      // );
      // return {
      //   ...state,
      //   idealStockList: list,
      //   listCount: action.payload.count,
      //   loading: false,
      //   idealStockParams: {
      //     ...state.idealStockParams,
      //     no_of_pages: noofpages,
      //   },
      // };
    },
    setIdealStockParams: (state, action) => {
      return {
        ...state,
        idealStockParams: action.payload,
      };
    },

    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        idealStockParams: {
          ...state.idealStockParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    // Ideal Stock Brand / Versio List
    getIdealStockVersion: (state, action) => {
      if (state.idealStockVersionParams?.page == 1) {
        return {
          ...state,
          loading: true,
          idealStockVersionList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    idealStockVersionListSuccessful: (state, action) => {
      var response = action.payload;
      var versionList = response.results;
      // var selectedVersion_ids = state.selectStockVersion.map((i) => {
      //   return i.version_id;
      // });
      // versionList = response.results.filter((item) => {
      //   return selectedVersion_ids.indexOf(item.id) == -1;
      // });
      var list = [];
      if (state.idealStockVersionParams?.page == 1) {
        list = versionList;
      } else {
        list = [...state.idealStockVersionList, ...versionList];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.idealStockVersionParams?.page_size
      );
      return {
        ...state,
        idealStockVersionList: list,
        loading: false,
        model: true,
        idealStockVersionParams: {
          ...state.idealStockVersionParams,
          no_of_pages: noofpages,
        },
      };
    },
    setIdealStockVersionParams: (state, action) => {
      return {
        ...state,
        idealStockVersionParams: action.payload,
      };
    },
    isModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          model: action.payload,
        };
      } else {
        return {
          ...state,
          model: action.payload,
          idealStockVersionList: [],
          idealStockVersionParams: {
            start_date: "",
            end_date: "",
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
          },
        };
      }
    },
    addVersionItem: (state, action) => {
      return {
        ...state,
        loading: false,
        numberOfItems : state.numberOfItems + 1,
        selectStockVersion: [
          ...state.selectStockVersion,
          {
            _id: state.numberOfItems + 1,
            name: action.payload.name,
            brand: action.payload.brand,
            brandfamily: action.payload.brandfamily,
            model: action.payload.model,
            version_id: action.payload.id,
            limit: null,
            year: "",
            dodelete: false,
          },
        ],
        model: false,
      };
    },
    stockInputChangeValue: (state, action) => {
      // Old Code

      // var selectedItem = state.selectStockVersion.map((item, index) => {
      //   if (index == action.payload.index) {
      //     if (
      //       item.version_id == action.payload.item.version_id &&
      //       item.year == action.payload.item.year
      //     ) {
      //       return {
      //         ...item,
      //         [action.payload.key]: action.payload.value,
      //       };
      //     }
      //   }
      //   return item;
      // });
      // return {
      //   ...state,
      //   selectStockVersion: selectedItem,
      // };



      var selectedItem = state.selectStockVersion.map((item) => {
        console.log("(item._id", item._id);
          if (item._id == action.payload.item._id) {
              return {
                ...item,
                [action.payload.key]: action.payload.value,
              };
          }
        return item;
      });
      return {
        ...state,
        selectStockVersion: selectedItem,
      };
    },
    removeItem: (state, action) => {
      // Old Code
      // var selectedRow = [];
      // if (action.payload.id) {
      //   selectedRow = state.selectStockVersion.map((item, index) => {
      //     if (index == action.payload.index - 1) {
      //       if (
      //         item.version_id == action.payload.version_id &&
      //         item.year == action.payload.year
      //       ) {
      //         return { ...item, dodelete: true };
      //       }
      //     }
      //     return item;
      //   });
      // } else {
      //   selectedRow = state.selectStockVersion.filter((item, index) =>
      //     index == action.payload.index - 1
      //       ? !(
      //           item.version_id == action.payload.version_id &&
      //           item.year == action.payload.year
      //         )
      //         ? null
      //         : item
      //       : item
      //   );
      // }
      // return {
      //   ...state,
      //   selectStockVersion: selectedRow,
      // };

      //
      // var selectedRow = [];
      // if (action.payload.id) {
      //   selectedRow = state.selectStockVersion.map((item, index) => {
      //     if (index == action.payload.index - 1) {
      //         return { ...item, dodelete: true };
      //     }
      //     return item;
      //   });
      // } else {
      //   selectedRow = state.selectStockVersion.filter((item, index) =>
      //     index != action.payload.index - 1 
      //   );
      // }
      // return {
      //   ...state,
      //   selectStockVersion: selectedRow,
      // };

      var selectedRow = [];
      if (action.payload.id == 0 || action.payload.id == null || action.payload.id == undefined) {
        selectedRow = state.selectStockVersion.filter((item) => {
          return item._id != action.payload._id;
        });
      } else {
        selectedRow = state.selectStockVersion.map((item) => {
          if (item.id == action.payload.id) {
            return { ...item, dodelete: true };
          }
          return item;
        });
      }
      return {
        ...state,
        selectStockVersion: selectedRow,
      };
    },

    addIdealStock: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        idealStockData: {
          ...state.idealStockData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    updateIdealStockList: (state, action) => {
      if (state.updateStockParams?.page == 1) {
        return {
          ...state,
          loading: true,
          updateStockList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    updateIdealStockListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.updateStockParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.updateStockParams?.page_size
      );
      return {
        ...state,
        updateStockList: list,
        listCount: action.payload.count,
        loading: false,
        updateStockParams: {
          ...state.updateStockParams,
          no_of_pages: noofpages,
        },
      };
    },
    setUpdateStockParams: (state, action) => {
      return {
        ...state,
        updateStockParams: action.payload,
      }
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getIdealStockList,
  idealStockListSuccessful,
  setIdealStockParams,
  clearData,
  isDrawerVisible,
  FilterInputChangeValue,
  stockInputChangeValue,
  getIdealStockVersion,
  idealStockVersionListSuccessful,
  setIdealStockVersionParams,
  isModelVisible,
  addVersionItem,
  removeItem,
  addIdealStock,
  InputChangeValue,
  updateIdealStockList,
  updateIdealStockListSuccessful,
  setUpdateStockParams,
  apiError,
} = idealStockSlice.actions;

export default idealStockSlice.reducer;
