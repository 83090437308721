import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States

import {
  apiError,
  manageCompanySuccessful,
  manageCompanyAddSuccessful,
  getManageCompany,
  isModelVisible,
  companyLogoUploadSuccessful,
} from "./manageCompanySlice";

// AUTH related methods
import {
  postAdd,
  getList,
  getParamsList,
  postEdit,
  patchEdit,
  postDelete,
  postFormData,
  putImg,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

function* ManageCompany() {
  try {
    const response = yield call(getList, "/system/dynamicsettings/");
    yield put(manageCompanySuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddManageCompany({ payload }) {
  try {
    const response = yield call(postAdd, "/system/dynamicsettings/", payload);
    if (response.status === 200) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Manage Company Updated Successfully.!
            </p>
          </div>
        ),
      });
      yield put(getManageCompany());
      yield put(isModelVisible(false));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to Updated Manage Company. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

// function* AddManageCompany({ payload }) {
//   try {
//     const response = yield call(
//       postEdit,
//       "/system/dynamicsettings/" + payload.id,
//       payload.obj
//     );
//     if (response.status === 200) {
//       Modal.success({
//         icon: (
//           <span className="remix-icon">
//             <RiCheckboxCircleLine />
//           </span>
//         ),
//         title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
//         content: (
//           <div>
//             <p className="hp-p1-body hp-text-color-black-80">
//               Your Manage Company Updated Successfully.!
//             </p>
//           </div>
//         ),
//       });
//       yield put(getManageCompany({ page: 1, search: "", page_size: 10 }));
//       yield put(manageCompanyAddSuccessful(false));
//     } else {
//       Modal.error({
//         icon: (
//           <span className="remix-icon">
//             <RiAlertLine />
//           </span>
//         ),
//         title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
//         content: (
//           <div>
//             <p className="hp-p1-body hp-text-color-black-80">
//               Sorry! Unable to  Update. Please try again!
//             </p>
//           </div>
//         ),
//       });

//     }
//   } catch (error) {
//     Modal.error({
//       icon: (
//         <span className="remix-icon">
//           <RiAlertLine />
//         </span>
//       ),

//       title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
//       content: (
//         <div>
//           <p className="hp-p1-body hp-text-color-black-80">
//             Sorry! Getting from server side issue!
//           </p>
//         </div>
//       ),
//     });
//     yield put(apiError(error));
//   }
// }

function* CompanyLogoUpload({ payload }) {
  console.log("imageType", payload);
  try {
    const response = yield call(
      putImg,
      "/system/dynamicsettings/" + payload.imageType,
      {
        value: payload.file,
      }
    );
    yield put(getManageCompany( ));


    yield put(companyLogoUploadSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetManageCompany() {
  yield takeEvery("manageCompany/getManageCompany", ManageCompany);
}

export function* watchManageCompanyAdd() {
  yield takeEvery("manageCompany/manageCompanyAdd", AddManageCompany);
}

export function* watchGetCompanyLogoUpload() {
  yield takeEvery("manageCompany/getCompanyLogoUpload", CompanyLogoUpload);
}

function* ManageCompanySaga() {
  yield all([
    fork(watchGetManageCompany),
    fork(watchManageCompanyAdd),
    fork(watchGetCompanyLogoUpload),
  ]);
}

export default ManageCompanySaga;
