import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  saleAgentListSuccessful,
  getSaleAgentList,
  setSaleAgentListParams,
  saleAgentTargetSuccessfully,
  saleAgentByIDSuccessful,
  counterListSuccessful,
  counterByIDSuccessful,
  getCounterList,
  setCounterListParams,
  counterTargetSuccessfully,
  achievedSaleAgentListSuccessful,
  achievedCounterListSuccessful,
  saleAgentAchieveCountSuccessful,
  InputChangeValue,
} from "./targetSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

//If user is login then dispatch redux action's are directly from here.

function* SaleAgentList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k === "from_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "to_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else if (k === "date") {
            params[k] = moment(data[k]).startOf("month").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/employee_target/",
      params
    );
    yield put(saleAgentListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* SaleAgentByID({ payload }) {
  try {
    const response = yield call(
      getList,
      "/masters/employee_target/" + payload,
      {}
    );
    yield put(saleAgentByIDSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddSaleAgentTarget({ payload }) {
  try {
    if (payload.id === 0 || payload.id === null || payload.id === undefined) {
      const response = yield call(
        postAdd,
        "/masters/employee_target/",
        payload
      );
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Sale Agent Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(saleAgentTargetSuccessfully());
        yield put(getSaleAgentList({ page: 1, page_size: 10 }));
        yield put(
          setSaleAgentListParams({
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
          })
        );
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Sale Agent. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response = yield call(
        postEdit,
        "/masters/employee_target/" + payload.id,
        payload
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Sale Agent Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(saleAgentTargetSuccessfully());
        yield put(getSaleAgentList({ page: 1, page_size: 10 }));
        yield put(
          setSaleAgentListParams({
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
          })
        );
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Sale Agent. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    console.log("response", error.response);
    if (error.response.status === 400) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}
function* DeleteSaleAgent({ payload }) {
  try {
    const response = yield call(
      postDelete,
      "/masters/employee_target/" + payload,
      {}
    );
    yield put(getSaleAgentList({ page: 1, page_size: 10 }));
    yield put(
      setSaleAgentListParams({
        no_of_pages: 0,
        page_size: 10,
        page: 1,
        search: "",
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

function* CounterList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k === "from_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "to_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else if (k === "date") {
            params[k] = moment(data[k]).startOf("month").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/counter_target/",
      params
    );
    yield put(counterListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddCounterTarget({ payload }) {
  try {
    if (payload.id === 0 || payload.id === null || payload.id === undefined) {
      const response = yield call(postAdd, "/masters/counter_target/", payload);
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Counter Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(counterTargetSuccessfully());
        yield put(getCounterList({ page: 1, page_size: 10 }));
        yield put(
          setCounterListParams({
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
          })
        );
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Counter. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response = yield call(
        postEdit,
        "/masters/counter_target/" + payload.id,
        payload
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Counter Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(counterTargetSuccessfully());
        yield put(getCounterList({ page: 1, page_size: 10 }));
        yield put(
          setCounterListParams({
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
          })
        );
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Counter. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    console.log("response", error.response);
    if (error.response.status === 400) {
      // yield put(InputChangeValue({ key: "", value: "" }));
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}

function* CounterID({ payload }) {
  try {
    const response = yield call(
      getList,
      "/masters/counter_target/" + payload,
      {}
    );
    yield put(counterByIDSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* DeleteCounter({ payload }) {
  try {
    const response = yield call(
      postDelete,
      "/masters/counter_target/" + payload,
      {}
    );
    yield put(getCounterList({ page: 1, page_size: 10 }));
    yield put(
      setCounterListParams({
        no_of_pages: 0,
        page_size: 10,
        page: 1,
        search: "",
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

// Target Achieved

function* AchievedSaleAgentList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k === "from_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "to_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else if (k === "date") {
            params[k] = moment(data[k]).startOf("month").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      // "/masters/achievetargetssaleagent_count/",
      "/masters/saleagenttargetachieve_count/",
      params
    );
    yield put(achievedSaleAgentListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* AchievedCounterList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k === "from_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "to_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else if (k === "date") {
            params[k] = moment(data[k]).startOf("month").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/achievetargetscounter_count/",
      params
    );
    yield put(achievedCounterListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* SaleAgentAchieveCount({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k === "from_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "to_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else if (k === "date") {
            params[k] = moment(data[k]).startOf("month").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "masters/saleagenttargetachieve_overallcount", params);
    yield put(saleAgentAchieveCountSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetSaleAgentList() {
  yield takeEvery("target/getSaleAgentList", SaleAgentList);
}
export function* watchSaleAgentTargetAdd() {
  yield takeEvery("target/saleAgentTargetAdd", AddSaleAgentTarget);
}
export function* watchGetSaleAgentByID() {
  yield takeEvery("target/getSaleAgentByID", SaleAgentByID);
}
export function* watchGetSaleAgentDelete() {
  yield takeEvery("target/SaleAgentDelete", DeleteSaleAgent);
}
export function* watchGetCounterList() {
  yield takeEvery("target/getCounterList", CounterList);
}
export function* watchCounterTargetAdd() {
  yield takeEvery("target/counterTargetAdd", AddCounterTarget);
}
export function* watchGetCounterByID() {
  yield takeEvery("target/getCounterByID", CounterID);
}
export function* watchGetCounterDelete() {
  yield takeEvery("target/CounterDelete", DeleteCounter);
}

// Target Achieve

export function* watchGetAchievedSaleAgentList() {
  yield takeEvery("target/getAchievedSaleAgentList", AchievedSaleAgentList);
}
export function* watchGetAchievedCounterList() {
  yield takeEvery("target/getAchievedCounterList", AchievedCounterList);
}
export function* watchGetSaleAgentAchieveCount() {
  yield takeEvery("target/getSaleAgentAchieveCount", SaleAgentAchieveCount);
}
function* targetSaga() {
  yield all([
    fork(watchGetSaleAgentList),
    fork(watchSaleAgentTargetAdd),
    fork(watchGetSaleAgentByID),
    fork(watchGetSaleAgentDelete),
    fork(watchGetCounterList),
    fork(watchCounterTargetAdd),
    fork(watchGetCounterByID),
    fork(watchGetCounterDelete),
    fork(watchGetAchievedSaleAgentList),
    fork(watchGetAchievedCounterList),
    fork(watchGetSaleAgentAchieveCount),
  ]);
}

export default targetSaga;
