import { createSlice } from "@reduxjs/toolkit";

export const occupationsSlice = createSlice({
  name: "occupations",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    occupationslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    occupationsParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getOccupations: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    occupationListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.occupationsParams.page_size
      );

      return {
        ...state,
        listCount: action.payload.response.count,
        occupationslist: action.payload.response.results,
        loading: false,
        occupationsParams: {
          ...state.occupationsParams,
          no_of_pages: noofpages,
        },
      };
    },
    occupationAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    occupationAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    occupationEdit: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: action.payload,
      };
    },
    occupationEditSuccessful: (state) => {
      return { ...state, loading: false, rowdata: {} };
    },
    occupationDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetOccupationsParams: (state, action) => {
      return {
        ...state,
        occupationsParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getOccupations,
  occupationListSuccessful,
  occupationAdd,
  occupationAddSuccessful,
  occupationEdit,
  occupationEditSuccessful,
  occupationDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetOccupationsParams
} = occupationsSlice.actions;

export default occupationsSlice.reducer;
