import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  getInsuranceList,
  insuranceListSuccessful,
  insuranceImageUploadSuccessful,
  isModelVisible,
  isInsuranceViewModelVisible,
  getVersionInsuranceMasterSuccessful,
  insuranceByIdSuccessful,
  insurerListSuccessful,
  insurerImageUploadSuccessful,
  getInsurerList,
  isInsurerAddModelVisible,
  InsurerInputChangeValue,
  clearInsuranceData,
} from "./newInsuranceSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
import { lgamma } from "mathjs";

//If user is login then dispatch redux action's are directly from here.
function* InsuranceListGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/insurancerto/insurances/list/",
      params
    );
    yield put(insuranceListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* InsuranceImageUpload({ payload }) {
  try {
    const response = yield call(postFormData, "/system/attachment/", {
      file: payload.file,
    });

    yield put(
      insuranceImageUploadSuccessful({ response, ImageType: payload.imageType })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

// function* InsuranceAdd({ payload }) {
//   try {
//     if (payload.id == 0) {
//       const response = yield call(
//         postAdd,
//         "/insurancerto/insurances/create/direct", payload
//       );
//       if (response.status === 201) {
//         Modal.success({
//           icon: (
//             <span className="remix-icon">
//               <RiCheckboxCircleLine />
//             </span>
//           ),
//           title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
//           content: (
//             <div>
//               <p className="hp-p1-body hp-text-color-black-80">
//                 Your Insurance Added Successfully.!
//               </p>
//             </div>
//           ),
//         });
//         yield put(getInsuranceList({ page: 1, search: "", page_size: 10 }));
//         yield put(isModelVisible(false));
//       } else {
//         Modal.error({
//           icon: (
//             <span className="remix-icon">
//               <RiAlertLine />
//             </span>
//           ),
//           title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
//           content: (
//             <div>
//               <p className="hp-p1-body hp-text-color-black-80">
//                 Sorry! Unable to added Insurance. Please try again!
//               </p>
//             </div>
//           ),
//         });
//       }
//     } else {
//       const response = yield call(
//         postEdit,
//         "/insurancerto/insurances/" + payload.id,
//       );
//       if (response.status === 200) {
//         Modal.success({
//           icon: (
//             <span className="remix-icon">
//               <RiCheckboxCircleLine />
//             </span>
//           ),
//           title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
//           content: (
//             <div>
//               <p className="hp-p1-body hp-text-color-black-80">
//                 Your Insurance Edited Successfully.!
//               </p>
//             </div>
//           ),
//         });
//         yield put(getInsuranceList({ page: 1, search: "", page_size: 10 }));
//         yield put(isModelVisible(false));
//       } else {
//         Modal.error({
//           icon: (
//             <span className="remix-icon">
//               <RiAlertLine />
//             </span>
//           ),
//           title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
//           content: (
//             <div>
//               <p className="hp-p1-body hp-text-color-black-80">
//                 Sorry! Unable to edit Insurance. Please try again!
//               </p>
//             </div>
//           ),
//         });
//       }
//     }
//   } catch (error) {
//     Modal.error({
//       icon: (
//         <span className="remix-icon">
//           <RiAlertLine />
//         </span>
//       ),

//       title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
//       content: (
//         <div>
//           <p className="hp-p1-body hp-text-color-black-80">
//             Sorry! Getting from server side issue!
//           </p>
//         </div>
//       ),
//     });
//     yield put(apiError(error));
//   }
// }

function* InsuranceAdd({ payload }) {
  try {
    if (payload.insuranceObj.id == 0) {
      const response = yield call(
        postAdd,
        "/insurancerto/insurances/create/direct",
        payload.insuranceObj
      );
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Insurance has been created Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getInsuranceList({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to create Insurance. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response = yield call(
        postEdit,
        "/insurancerto/insurances/" + payload.insuranceObj.id,
        payload.insuranceObj
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Insurance has been Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getInsuranceList({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Insurance. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* insuranceById({ payload }) {
  try {
    const response = yield call(
      getList,
      "/insurancerto/insurances/" + payload,
      {}
    );
    yield put(insuranceByIdSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* ApproveInsurance({ payload }) {
  try {
    const response = yield call(
      postEdit,
      "/insurancerto/insurances/reviewer/" + payload.id,
      payload.approveObj
    );
    if (response.status === 200) {
      if (payload.approveObj.insurancestatus == 2) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Insurance has been Approved successfully!
              </p>
            </div>
          ),
        });
      } else if (payload.approveObj.insurancestatus == 4) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Insurance has been Rejected successfully!
              </p>
            </div>
          ),
        });
      }

      yield put(getInsuranceList({ page: 1, search: "", page_size: 10 }));
      yield put(isInsuranceViewModelVisible(false));
    } else {
      if (payload.approveObj.insurancestatus == 2) {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Your Insurance has been not Approved!
              </p>
            </div>
          ),
        });
      } else if (payload.approveObj.insurancestatus == 4) {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Your Insurance has been not Rejected!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

function* VersionInsuranceMasterGet({ payload }) {
  try {
    const response = yield call(
      getList,
      "/masters/versions/insurances/" + payload,
      {}
    );
    yield put(getVersionInsuranceMasterSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

//  Insurer

function* InsurerListGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/insurancerto/insurances/byinsurer",
      params
    );
    yield put(insurerListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* InsurerImageUpload({ payload }) {
  try {
    const response = yield call(postFormData, "/system/attachment/", {
      file: payload.file,
    });

    yield put(
      insurerImageUploadSuccessful({ response, ImageType: payload.imageType })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

function* InsurerAdd({ payload }) {
  try {
    const response = yield call(
      postEdit,
      "/insurancerto/insurances/update/byinsurer/" + payload.id,
      payload.insurerObj
    );
    if (response.status === 200) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Insurer Updated Successfully.!
            </p>
          </div>
        ),
      });

      yield put(getInsurerList({ page: 1, search: "", page_size: 10 }));
      yield put(isInsurerAddModelVisible(false));
      yield put(clearInsuranceData());
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to Update. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* InsuranceValidate({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/purchase/insurancenumber/validation/",
      payload
    );
  } catch (error) {
    if (error.response.status == 400) {
      yield put(
        InsurerInputChangeValue({
          key: "number",
          value: "",
        })
      );
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response?.data?.error[0]}
            </p>
          </div>
        ),
      });
    } else if (error.response.status == 500) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}
export function* watchGetInsuranceList() {
  yield takeEvery("newInsurance/getInsuranceList", InsuranceListGet);
}
export function* watchGetInsuranceImageUpload() {
  yield takeEvery("newInsurance/getInsuranceImageUpload", InsuranceImageUpload);
}
export function* watchAddInsurance() {
  yield takeEvery("newInsurance/addNewInsurance", InsuranceAdd);
}
export function* watchGetInsuranceById() {
  yield takeEvery("newInsurance/getInsuranceById", insuranceById);
}

export function* watchGetInsuranceApprove() {
  yield takeEvery("newInsurance/insuranceApprove", ApproveInsurance);
}

export function* watchGetVersionInsuranceMaster() {
  yield takeEvery(
    "newInsurance/getVersionInsuranceMaster",
    VersionInsuranceMasterGet
  );
}

export function* watchGetInsurerList() {
  yield takeEvery("newInsurance/getInsurerList", InsurerListGet);
}

// export function* watchInsurerEdit() {
//   yield takeEvery("newInsurance/insurerEdit", getInsurerEdit);
// }

export function* watchGetInsurerImageUpload() {
  yield takeEvery("newInsurance/getInsurerImageUpload", InsurerImageUpload);
}

export function* watchAddInsurerData() {
  yield takeEvery("newInsurance/addInsurerData", InsurerAdd);
}
export function* watchInsuranceNumberValidate() {
  yield takeEvery("newInsurance/insuranceNumberValidate", InsuranceValidate);
}
function* newInsuranceSaga() {
  yield all([
    fork(watchGetInsuranceList),
    fork(watchGetInsuranceImageUpload),
    fork(watchAddInsurance),
    fork(watchGetInsuranceById),
    fork(watchGetInsuranceApprove),
    fork(watchGetVersionInsuranceMaster),
    fork(watchGetInsurerList),
    // fork(watchInsurerEdit),
    fork(watchGetInsurerImageUpload),
    fork(watchAddInsurerData),
    fork(watchInsuranceNumberValidate),
  ]);
}

export default newInsuranceSaga;
