import { createSlice } from "@reduxjs/toolkit";

export const rejectreasonsSlice = createSlice({
  name: "rejectreasons",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    rejectreasonslist: [],
    model: false,
    error_msg: null,
    rowdata: {
      editable: false
    },
    listCount: 0,
    rejectreasonsParams: {
      no_of_pages: 0,  
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getRejectreasons: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    rejectreasonsListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.rejectreasonsParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        rejectreasonslist: action.payload.response.results,
        loading: false,
        rejectreasonsParams: {
          ...state.rejectreasonsParams,
          no_of_pages: noofpages,
        },
      };
    },
    rejectreasonsAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    rejectreasonsAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    rejectreasonsEdit: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: action.payload,
      };
    },
    rejectreasonsEditSuccessful: (state) => {
      return { ...state, loading: false, rowdata: {} };
    },
    rejectreasonsDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetRejectreasonsParams: (state, action) => {
      return {
        ...state,
        rejectreasonsParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getRejectreasons,
  rejectreasonsListSuccessful,
  rejectreasonsAdd,
  rejectreasonsAddSuccessful,
  rejectreasonsEdit,
  rejectreasonsEditSuccessful,
  rejectreasonsDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetRejectreasonsParams
} = rejectreasonsSlice.actions;

export default rejectreasonsSlice.reducer;
