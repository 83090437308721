import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  apiError,
  getDealers,
  dealersListSuccessful,
  isModelVisible,
  InputChangeValue,
} from "./dealersSlice";

import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
// AUTH related methods
import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
} from "../../../helpers/Helper";
import Swal from "sweetalert2";

function* DealersGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/dealers/list", params);
    yield put(dealersListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* AddDealer({ payload }) {
  try {
    if (payload.id == 0) {
      const response = yield call(postAdd, "/masters/dealers/", payload);
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Dealer Add Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getDealers({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Dealer. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response = yield call(
        postEdit,
        "/masters/dealers/" + payload.id,
        payload
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Dealer Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getDealers({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Dealer. Please try again!
              </p>
            </div>
          ),
        });
      }
    }

    // } catch (error) {
    //   Swal.fire({
    //     title: "Sorry! Permission Denied!",
    //     icon: "error",
    //   });
    //   yield put(apiError(error));
    // }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

function* DeleteDealer({ payload }) {
  var dealerID = payload;
  try {
    const response = yield call(postDelete, "/masters/dealers/" + dealerID);

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Dealer has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getDealers({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Dealer has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* checkUserMobile({ payload }) {
  try {
    yield call(postAdd, "/users/usermobile/validation/", payload);
  } catch (error) {
    console.log("response", error.response);
    if (error.response.status === 400) {
      yield put(InputChangeValue({ key: "phone", value: "" }));
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}
// function* checkUserEmail({ payload }) {
//   console.log("checkUserEmail", payload);
//   try {
//     yield call(postAdd, "/users/useremail/validation/", payload);
//   } catch (error) {
//     console.log("response__________", error);
//     if (error.response.status === 400) {
//       yield put(InputChangeValue({ key: "email", value: "" }));
//       Modal.error({
//         icon: (
//           <span className="remix-icon">
//             <RiAlertLine />
//           </span>
//         ),
//         title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
//         content: (
//           <div>
//             <p className="hp-p1-body hp-text-color-black-80">
//               {error.response.data.error[0]}
//             </p>
//           </div>
//         ),
//       });
//     } else {
//       Modal.error({
//         icon: (
//           <span className="remix-icon">
//             <RiAlertLine />
//           </span>
//         ),
//         title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
//         content: (
//           <div>
//             <p className="hp-p1-body hp-text-color-black-80">
//               Sorry! Getting from server side issue!
//             </p>
//           </div>
//         ),
//       });
//     }
//     yield put(apiError(error));
//   }
// }


function* checkUserEmail({ payload }) {
  try {
    yield call(postAdd, "/users/useremail/validation/", payload);
  } catch (error) {
    console.log("error...........", error);
    if (error.response.status === 400) {
      yield put(InputChangeValue({ key: "email", value: "" }));
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}

export function* watchGetDealers() {
  yield takeEvery("dealers/getDealers", DealersGet);
}
export function* watchDealerAdd() {
  yield takeEvery("dealers/dealerAdd", AddDealer);
}

export function* watchDealerDelete() {
  yield takeEvery("dealers/dealerDelete", DeleteDealer);
}
export function* watchUserMobileValidation() {
  yield takeEvery("dealers/userMobileValidation", checkUserMobile);
}
export function* watchUserEmailValidation() {
  yield takeEvery("dealers/userEmailValidation", checkUserEmail);
}

function* DealersSaga() {
  yield all([
    fork(watchGetDealers),
    fork(watchDealerAdd),
    fork(watchDealerDelete),
    fork(watchUserMobileValidation),
    fork(watchUserEmailValidation),
  ]);
}

export default DealersSaga;
