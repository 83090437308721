import { createSlice } from "@reduxjs/toolkit";

export const systemSlice = createSlice({
  name: "system",
  initialState: {
    masterMenuItemsList: [],
    sideMenuItemsList: [],
    reportsMenuItemsList: [],
    userAccessList: [],
    quickMenuItemsList: [],
    importMenuItemsList: [],
    profileMenuItemsList: [],
    exportMenuItemsList: [],
    filterData: [],
    active: false,
    quickMenuactive: false,
    menuactive: false,
    systemFormulasList: [],
    filterStatus: false,
  },
  reducers: {
    getSideMenuItems: (state) => {
      return {
        ...state,
        loading: true,
        sideMenuItemsList: [],
      };
    },
    sideMenuItemsSuccessful: (state, action) => {
      // action.payload.menuitems = action.payload.menuitems.sort((a, b) =>
      // a.sequence > b.sequence ? 1 : -1
      // );
      // action.payload.submenus = action.payload.submenus.sort((a, b) =>
      //   a.sequence > b.sequence ? 1 : -1
      // );
      // action.payload.submenus.map((sideMenu) => {
      //   sideMenu.menuitems = sideMenu.menuitems.sort((a, b) =>
      //     a.sequence > b.sequence ? 1 : -1
      //   );
      //   return sideMenu;
      // });
      action.payload.submenus = action.payload.submenus.map((submenus) => {
        submenus.type = "SUBMENU";
        return submenus;
      });
      action.payload.submenus.map((sideMenu) => {
        sideMenu.menuitems = sideMenu.menuitems.sort((a, b) =>
          a.sequence > b.sequence ? 1 : -1
        );
        return sideMenu;
      });
      action.payload.menuitems = action.payload.menuitems.map((menuitem) => {
        menuitem.type = "MENUITEM";
        return menuitem;
      });
      var items = [...action.payload.submenus, ...action.payload.menuitems];
      items = items.sort((a, b) => (a.sequence > b.sequence ? 1 : -1));
      return {
        ...state,
        sideMenuItemsList: items,
        loading: false,
      };
    },
    getMasterMenuItems: (state) => {
      return {
        ...state,
        loading: true,
        masterMenuItemsList: [],
      };
    },
    MasterMenuItemsSuccessful: (state, action) => {
      action.payload.menuitems = action.payload.menuitems.sort((a, b) =>
        a.sequence > b.sequence ? 1 : -1
      );
      return {
        ...state,
        masterMenuItemsList: action.payload,
        filterData: action.payload?.menuitems,
        loading: false,
      };
    },
    getReportsMenuItems: (state) => {
      return {
        ...state,
        loading: true,
        reportsMenuItemsList: [],
      };
    },
    ReportsMenuItemsSuccessful: (state, action) => {
      action.payload.menuitems = action.payload.menuitems.sort((a, b) =>
        a.sequence > b.sequence ? 1 : -1
      );
      return {
        ...state,
        reportsMenuItemsList: action.payload,
        loading: false,
      };
    },
    getUserPermissions: (state) => {
      return {
        ...state,
        userAccessList: [],
        loading: true,
      };
    },
    userPermissionsSuccessful: (state, action) => {
      return {
        ...state,
        userAccessList: action.payload,
        loading: false,
      };
    },

    // Quick Menu

    getQuickMenuItems: (state) => {
      return {
        ...state,
        loading: true,
        quickMenuItemsList: [],
      };
    },
    QuickMenuItemsSuccessful: (state, action) => {
      action.payload.menuitems = action.payload.menuitems.sort((a, b) =>
        a.sequence > b.sequence ? 1 : -1
      );
      return {
        ...state,
        quickMenuItemsList: action.payload,
        loading: false,
      };
    },
    getImportMenuItems: (state) => {
      return {
        ...state,
        loading: true,
        quickMenuItemsList: [],
      };
    },
    ImportMenuItemsSuccessful: (state, action) => {
      action.payload.menuitems = action.payload.menuitems.sort((a, b) =>
        a.sequence > b.sequence ? 1 : -1
      );
      return {
        ...state,
        importMenuItemsList: action.payload,
        loading: false,
      };
    },
    notificationActive: (state, action) => {
      return {
        ...state,
        loading: true,
        active: action.payload,
      };
    },
    quickMenuActive: (state, action) => {
      return {
        ...state,
        quickMenuactive: action.payload,
      };
    },
    userMenuActive: (state, action) => {
      return {
        ...state,
        menuactive: action.payload,
      };
    },
    menuItemSearch: (state, action) => {
      return {
        ...state,
        // loading: true,
        filterData: action.payload,
      };
    },
    getProfileMenuItems: (state) => {
      return {
        ...state,
        loading: true,
        profileMenuItemsList: [],
      };
    },
    profileMenuItemsSuccessful: (state, action) => {
      action.payload.menuitems = action.payload.menuitems.sort((a, b) =>
        a.sequence > b.sequence ? 1 : -1
      );
      action.payload.submenus = action.payload.submenus.sort((a, b) =>
        a.sequence > b.sequence ? 1 : -1
      );
      action.payload.submenus.map((profileMenu) => {
        profileMenu.menuitems = profileMenu.menuitems.sort((a, b) =>
          a.sequence > b.sequence ? 1 : -1
        );
        return profileMenu;
      });
      return {
        ...state,
        profileMenuItemsList: action.payload,
        loading: false,
      };
    },
    getExportMenuItems: (state) => {
      return {
        ...state,
        loading: true,
        exportMenuItemsList: [],
      };
    },
    ExportMenuItemsSuccessful: (state, action) => {
      action.payload.menuitems = action.payload.menuitems.sort((a, b) =>
        a.sequence > b.sequence ? 1 : -1
      );
      return {
        ...state,
        exportMenuItemsList: action.payload,
        loading: false,
      };
    },
    getSystemFormulas: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    systemFormulasSuccessful: (state, action) => {
      return {
        ...state,
        systemFormulasList: action.payload,
        loading: false,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
    checkFilterStatus: (state, action) => {
      return {
        ...state,
        filterStatus: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getSideMenuItems,
  sideMenuItemsSuccessful,
  getMasterMenuItems,
  MasterMenuItemsSuccessful,
  getReportsMenuItems,
  ReportsMenuItemsSuccessful,
  getUserPermissions,
  userPermissionsSuccessful,
  getQuickMenuItems,
  QuickMenuItemsSuccessful,
  getImportMenuItems,
  ImportMenuItemsSuccessful,
  notificationActive,
  apiError,
  getProfileMenuItems,
  profileMenuItemsSuccessful,
  getExportMenuItems,
  ExportMenuItemsSuccessful,
  menuItemSearch,
  quickMenuActive,
  userMenuActive,
  getSystemFormulas,
  systemFormulasSuccessful,
  checkFilterStatus,
} = systemSlice.actions;

export default systemSlice.reducer;
