import { createSlice } from "@reduxjs/toolkit";
import moment, { min } from "moment";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    purchaseCount: {},
    directSaleCount: {},
    financeSaleCount: {},
    ovfSaleCount: {},
    reairCount: {},
    stockTransferCount: {},
    activeTab: "1",
    enquiryCount: [],
    QuotationCount: [],
    targetsCount: [],
    purchaseAnalyticsData: [],
    purchaseCategoty: [],
    analyticsData: {},
    totalSales: 0,
    drawer: false,
    dashboardParams: {
      dateType: "date",
      start_date: moment(),
      end_date: moment(),
    },
    typeDatePicker: "date",
    totalStockTransferCount: {},
    collectionCount: {},
    recoveryCount: {},
  },
  reducers: {
    clearData: (state, action) => {
      return {
        ...state,
        loading: false,
        purchaseCount: {},
        directSaleCount: {},
        financeSaleCount: {},
        ovfSaleCount: {},
        reairCount: {},
        stockTransferCount: {},
        enquiryCount: [],
        QuotationCount: [],
        targetsCount: [],
        purchaseAnalyticsData: [],
        purchaseCategoty: [],
        analyticsData: {},
        // dashboardParams: {},
        dashboardParams: {
          dateType: "date",
          start_date: moment(),
          end_date: moment(),
        },
      };
    },
    clearFilterData: (state, action) => {
      return {
        ...state,
        loading: false,
        dashboardParams: {
          dateType: "date",
          start_date: moment(),
          end_date: moment(),
        },
      };
    },
    getPurchaseCount: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    purchaseCountSuccessful: (state, action) => {
      return {
        ...state,
        purchaseCount: {
          total: action.payload.total_purchases,
          pending: action.payload.pending_purchases,
          approved: action.payload.approved_purchases,
          rejected: action.payload.rejected_purchases,
        },
        loading: false,
      };
    },

    getSaleCount: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    saleCountSuccessful: (state, action) => {
      return {
        ...state,
        directSaleCount: {
          total: action.payload.total_directsales,
          pending: action.payload.pending_directsales,
          approved: action.payload.approved_directsales,
          rejected: action.payload.rejected_directsales,
        },
        financeSaleCount: {
          total: action.payload.total_financesales,
          pending: action.payload.pending_financesales,
          approved: action.payload.approved_financesales,
          rejected: action.payload.rejected_financesales,
        },
        ovfSaleCount: {
          total: action.payload.total_ovfsales,
          pending: action.payload.pending_ovfsales,
          approved: action.payload.approved_ovfsales,
          rejected: action.payload.rejected_ovfsales,
        },
        totalSales: action.payload.total_sales,
        loading: false,
      };
    },
    getRepairCount: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    repairCountSuccessful: (state, action) => {
      return {
        ...state,
        reairCount: {
          total: action.payload.total_repairs,
          pending: action.payload.pending_repairs,
          approved: action.payload.approved_repairs,
          rejected: action.payload.rejected_repairs,
        },
        loading: false,
      };
    },

    getStockTransferCount: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    stockTransferCountSuccessful: (state, action) => {
      return {
        ...state,
        stockTransferCount: {
          total: action.payload.total_stocktranfers,
          pending: action.payload.pending_stocktranfers,
          approved: action.payload.approved_stocktranfers,
          rejected: action.payload.rejected_stocktranfers,
        },
        loading: false,
      };
    },
    saleActiveTab: (state, action) => {
      return {
        ...state,
        loading: true,
        activeTab: action.payload,
      };
    },
    getStockTransferCount: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    stockTransferCountSuccessful: (state, action) => {
      return {
        ...state,
        stockTransferCount: {
          total: action.payload.total_stocktranfers,
          pending: action.payload.pending_stocktranfers,
          approved: action.payload.approved_stocktranfers,
          rejected: action.payload.rejected_stocktranfers,
          accepted: action.payload.accepted_stocktranfers,
        },
        loading: false,
      };
    },
    getEnquiryCount: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    enquiryCountSuccessful: (state, action) => {
      var value = [];
      var data = action.payload;
      // Object.keys(data).forEach((key) => {
      //   value.push({ title: key, total: data[key] });
      //   // value.splice(1, 0, { title: key, total: data[key] });
      // });
      value = [
        {
          title: "Total Purchases",
          total: data.total_purchases,
          color: "#B473EF",
        },
        {
          title: "Total Direct Sale",
          total: data.total_directsales,
          color: "#FCA15C",
        },
        {
          title: "Total Finance Sale",
          total: data.total_financesales,
          color: "#3B9CE3",
        },
        {
          title: "Total OVF Sale",
          total: data.total_ovfsales,
          color: "#7B3DB9",
        },
      ];
      return {
        ...state,
        enquiryCount: value,
        loading: false,
      };
    },
    getQuotationCount: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    QuotationCountSuccessful: (state, action) => {
      var value = [];
      var data = action.payload;
      value = [
        {
          title: "Total Direct Sale",
          total: data.total_directsales,
          color: "#FCA15C",
        },
        {
          title: "Total Finance Sale",
          total: data.total_financesales,
          color: "#3B9CE3",
        },
        {
          title: "Total OVF Sale",
          total: data.total_ovfsales,
          color: "#7B3DB9",
        },
      ];
      return {
        ...state,
        QuotationCount: value,
        loading: false,
      };
    },
    getTargetsCount: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    targetsCountSuccessful: (state, action) => {
      var value = [];
      var data = action.payload;
      value = [
        {
          title: "Purchase Target",
          total: data.total_purchases,
          color: "#FCA15C",
        },
        { title: "Sale Target", total: data.total_sales, color: "#3B9CE3" },
        {
          title: "OVF Sale Target",
          total: data.total_ovfsales,
          color: "#7B3DB9",
        },
      ];
      return {
        ...state,
        targetsCount: value,
        loading: false,
      };
    },
    getPurchaseAnalytics: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    purchaseAnalyticSuccessful: (state, action) => {
      var value = [
        {
          name: "Direct",
          data: [],
        },
        {
          name: "Dealer",
          data: [],
        },
        {
          name: "Exchange",
          data: [],
        },
        {
          name: "Seizing",
          data: [],
        },
        {
          name: "Return",
          data: [],
        },
        // {
        //   name: "Vehicle",
        //   data: [],
        // },
      ];
      var categoty = [];
      var data = action.payload;
      Object.keys(data).forEach((key) => {
        categoty.push(key);
        value[0].data.push(data[key].direct_count);
        value[1].data.push(data[key].dealer_count);
        value[2].data.push(data[key].exchange_count);
        value[3].data.push(data[key].seizing_count);
        value[4].data.push(data[key].return_count);
        // value[5].data.push(data[key].frvehicle_count);
      });
      return {
        ...state,
        purchaseAnalyticsData: value,
        purchaseCategoty: categoty,
        loading: false,
      };
    },
    getSaleAnalytics: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    saleAnalyticSuccessful: (state, action) => {
      var value = [
        {
          name: "Direct Sale",
          data: [],
        },
        {
          name: "Finance Sale",
          data: [],
        },
        {
          name: "OVF Sale",
          data: [],
        },
      ];
      var categoty = [];
      var data = action.payload;
      Object.keys(data).forEach((key) => {
        categoty.push(key);
        value[0].data.push(data[key].directsale_count);
        value[1].data.push(data[key].financesale_count);
        value[2].data.push(data[key].ovfsale_count);
      });
      return {
        ...state,
        purchaseAnalyticsData: value,
        purchaseCategoty: categoty,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        analyticsData: {
          ...state.analyticsData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isDrawerVisible: (state, action) => {
      return {
        ...state,
        drawer: action.payload,
      };
    },
    FilterInputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "counter__zone":
          return {
            ...state,
            dashboardParams: {
              ...state.dashboardParams,
              [action.payload.key]: action.payload.value,
              counter__cluster: null,
              counter__division: null,
              counter: null,
            },
          };

        case "counter__division":
          return {
            ...state,
            dashboardParams: {
              ...state.dashboardParams,
              [action.payload.key]: action.payload.value,
              counter__cluster: null,
              counter: null,
            },
          };

        case "counter__cluster":
          return {
            ...state,
            dashboardParams: {
              ...state.dashboardParams,
              [action.payload.key]: action.payload.value,
              counter: null,
            },
          };
        default:
          return {
            ...state,
            dashboardParams: {
              ...state.dashboardParams,
              [action.payload.key]: action.payload.value,
            },
          };
      }
    },
    getTotalStockTransferCount: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    totalStockTransferCountSuccessful: (state, action) => {
      return {
        ...state,
        totalStockTransferCount: {
          total: action.payload.totalstock,
          repair: action.payload.repairout,
          temporary: action.payload.temporaryout,
          available: action.payload.availablestock,
        },
        loading: false,
      };
    },
    changeDatePicker: (state, action) => {
      return {
        ...state,
        loading: true,
        typeDatePicker: action.payload,
      };
    },
    getVisitCount: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    visitCountSuccessful: (state, action) => {
      return {
        ...state,
        collectionCount: {
          total: action.payload.collection_total,
          done: action.payload.collection_done,
          not_done: action.payload.collection_notdone,
        },
        recoveryCount: {
          total: action.payload.recovery_total,
          done: action.payload.recovery_done,
          not_done: action.payload.recovery_notdone,
        },
        loading: false,
      };
    },
    loadingStatus: (state, action) => {
      return { ...state, loading: action.payload };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  clearData,
  getPurchaseCount,
  purchaseCountSuccessful,
  getSaleCount,
  saleCountSuccessful,
  getRepairCount,
  repairCountSuccessful,
  getStockTransferCount,
  stockTransferCountSuccessful,
  saleActiveTab,
  getEnquiryCount,
  enquiryCountSuccessful,
  getQuotationCount,
  QuotationCountSuccessful,
  getTargetsCount,
  targetsCountSuccessful,
  getPurchaseAnalytics,
  purchaseAnalyticSuccessful,
  getSaleAnalytics,
  saleAnalyticSuccessful,
  InputChangeValue,
  isDrawerVisible,
  FilterInputChangeValue,
  SelectInputChangeValue,
  clearFilterData,
  changeDatePicker,
  getTotalStockTransferCount,
  totalStockTransferCountSuccessful,
  getVisitCount,
  visitCountSuccessful,
  loadingStatus,
  apiError,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
