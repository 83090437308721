import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const managersSlice = createSlice({
  name: "managers",
  initialState: {
    listCount: 0,
    managerslist: [],
    managerData: {},
    model: false,
    managesParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getManagers: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    managersSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.count / state.managesParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.count,
        managerslist: action.payload.results,
        loading: false,
        managesParams: {
          ...state.managesParams,
          no_of_pages: noofpages,
        },
      };
    },
    managerAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    managerbyId: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    managerbyIdSuccessful: (state, action) => {
      return {
        ...state,
        managerData: {
          ...action.payload,
        },
      };
    },

    InputChangeValue: (state, action) => {
      return {
        ...state,
        managerData: {
          ...state.managerData,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          managerData: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    setManagersParams: (state, action) => {
      return {
        ...state,
        managesParams: action.payload,
      };
    },
    managerDelete: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },

    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getManagers,
  managersSuccessful,
  managerAdd,
  managerbyId,
  managerbyIdSuccessful,
  InputChangeValue,
  isModelVisible,
  setManagersParams,
  managerDelete,
  apiError,
} = managersSlice.actions;

export default managersSlice.reducer;
