import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  getAssetRequestList,
  assetRequestListSuccessful,
  isModelVisible,
  InputChangeValue,
  assetRequestByIDSuccessful,
  assetImageUploadSuccessful,
  feedBackModelVisible,
  feedBackSuccessful,

  assetApproveListSuccessful,
  assetApproveByIDSuccessful,
  getAssetApproveList,
  isApproveModelVisible,
  assetApproveSuccessful,
} from "./assetRequestSlice";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
// Auth Related Methods
import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
  getList,
  postFormData, 
} from "../../../helpers/Helper";



function* AssetRequestList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/asset/asset/", params);
    yield put(assetRequestListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddAssetRequest({ payload }) {
  try {
    if (payload.id === 0 || payload.id === null || payload.id === undefined) {
      const response = yield call(postAdd, "/asset/asset/", payload);
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Asset Request Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getAssetRequestList({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Asset Request. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response = yield call(
        postEdit,
        "/asset/asset/" + payload.id,
        payload
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Asset Request Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getAssetRequestList({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Asset Request. Please try again!
              </p>
            </div>
          ),
        });
      }
      // yield put(vehiclesAddSuccessful(null));
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* AssetRequestByID({ payload }) {
  try {
    const response = yield call(
      getParamsList,
      "/asset/asset/" +  payload.row.id,
      {}
    );
    yield put(assetRequestByIDSuccessful({ response, type: payload.type }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* DeleteAssetRequest({ payload }) {
  var areaID = payload;
  try {
    const response = yield call(postDelete, "/asset/asset/" + areaID);

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your  Asset Request has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getAssetRequestList({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your  Asset Request has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

function* AssetImageUpload({ payload }) {
  try {
    const response = yield call(postFormData, "/system/attachment/", {
      file: payload.file,
    });

    yield put(
      assetImageUploadSuccessful({ response, ImageType: payload.imageType })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}



function* AssetApproveList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/asset/asset/approved/list",
      params
    );
    yield put(assetApproveListSuccessful( response ));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AssetApproveByID({ payload }) {
  try {
    const response = yield call(
      getParamsList,
      "/asset/asset/approved/" +  payload.id,
      {}
    );
    yield put(assetApproveByIDSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* ApproveAsset({ payload }) {
  try {
    const response = yield call(
      postEdit,
      "/asset/asset/approved/" + payload.id,
      payload.approveObj
    );
    if (response.status === 200) {
      if (payload.approveObj.approvalstatus == 2) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Asset has been Approved successfully!
              </p>
            </div>
          ),
        });
      } else if (payload.approveObj.approvalstatus == 3) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Asset has been Rejected successfully!
              </p>
            </div>
          ),
        });
      }
      yield put(getAssetRequestList({ page: 1, search: "", page_size: 10 }));
      yield put(getAssetApproveList({ page: 1, search: "", page_size: 10 }));
      yield put(assetApproveSuccessful(response));
    } else {
      if (payload.approveObj.insurancestatus == 2) {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Your Asset has been not Approved!
              </p>
            </div>
          ),
        });
      } else if (payload.approveObj.insurancestatus == 4) {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Your Insurance has been not Rejected!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

function* FeedBackAdd({ payload }) {
  try {
    const response = yield call(
      postEdit,
      "/asset/asset/feedback/" + payload.id,
      payload.feedObj
    );
    if (response.status === 200) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Asset Feedback has been sent successfully!
            </p>
          </div>
        ),
      });
      yield put(getAssetRequestList({ page: 1, search: "", page_size: 10 }));
      yield put(getAssetApproveList({ page: 1, search: "", page_size: 10 }));
      yield put(feedBackSuccessful(response));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Asset Feedback has been not sent!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

function* watchGetAssetRequestList() {
  yield takeEvery("assetRequest/getAssetRequestList", AssetRequestList);
}
export function* watchAssetRequestAdd() {
  yield takeEvery("assetRequest/assetRequestAdd", AddAssetRequest);
}
export function* watchGetAssetRequestByID() {
  yield takeEvery("assetRequest/getAssetRequestByID", AssetRequestByID);
}
export function* watchAssetRequestDelete() {
  yield takeEvery("assetRequest/assetRequestDelete", DeleteAssetRequest);
}

export function* watchGetAssetImageUpload() {
  yield takeEvery("assetRequest/getAssetImageUpload", AssetImageUpload);
}
function* watchGetAssetApproveList() {
  yield takeEvery("assetRequest/getAssetApproveList", AssetApproveList);
}
function* watchGetAssetApproveByID() {
  yield takeEvery("assetRequest/getAssetApproveByID", AssetApproveByID);
}
function* watchAssetApprove() {
  yield takeEvery("assetRequest/assetApprove", ApproveAsset);
}
function* watchAddFeedBack() {
  yield takeEvery("assetRequest/addFeedBack", FeedBackAdd);
}
function* AssetRquestSaga() {
  yield all([
    fork(watchGetAssetRequestList),
    fork(watchAssetRequestAdd),
    fork(watchGetAssetRequestByID),
    fork(watchAssetRequestDelete),
    fork(watchGetAssetImageUpload),
    fork(watchAddFeedBack),
    fork(watchGetAssetApproveList),
    fork(watchGetAssetApproveByID),
    fork(watchAssetApprove),
  ]);
}

export default AssetRquestSaga;
