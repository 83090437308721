import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const personSlice = createSlice({
  name: "person",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    personlist: [],
    model: false,
    viewModel: false,
    drawer: false,
    error_msg: null,
    rowdata: {
      is_defaultor: false
    },
    customerViewData: {},
    listCount: 0,
    searchPersonId: 0,
    personParams: {
      start_date: "",
      end_date: "",

      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    OTPModel: false,
    OTPData: {},
    filterStatus: false,
  },
  reducers: {
    // getPerson: (state, action) => {
    //   if (state.personParams?.page == 1) {
    //     return {
    //       ...state,
    //       loading: true,
    //       personlist: [],
    //       // searchPersonId: action.payload.searchPersonId == undefined ? 0 : action.payload.searchPersonId
    //     };
    //   } else {
    //     return {
    //       ...state,
    //       loading: true,
    //       // searchPersonId: action.payload.searchPersonId == undefined ? 0 : action.payload.searchPersonId
    //     };
    //   }
    // },
    // personListSuccessful: (state, action) => {
    //   var response = action.payload.response;
    //   // var searchPersonId = action.payload.searchPersonId
    //   var list = [];
    //   if (state.personParams?.page == 1) {
    //     list = response.results;
    //   } else {
    //     list = [...state.personlist, ...response.results];
    //   }
    //   // if(searchPersonId == state.searchPersonId){
    //   var noofpages = Math.ceil(response.count / state.personParams?.page_size);
    //   return {
    //     ...state,
    //     listCount: action.payload.response.count,
    //     personlist: list,
    //     // personlist: [...state.personlist, ...response.results],
    //     loading: false,
    //     personParams: {
    //       ...state.personParams,
    //       no_of_pages: noofpages,
    //     },
    //   };
    //   // }
    // },

    getPerson: (state, action) => {
      if (state.personParams?.page == 1) {
        return {
          ...state,
          loading: true,
          personlist: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    personListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.personParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.personlist, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.personParams?.page_size
      );
      return {
        ...state,
        personlist: list,
        listCount: response.count,
        loading: false,
        personParams: {
          ...state.personParams,
          no_of_pages: noofpages,
        },
      };
    },

    clearPersonList: (state, action) => {
      return {
        ...state,
        loading: true,
        personlist: [],
      };
    },
    clearPersonData: (state, action) => {
      return {
        ...state,
        loading: false,
        customerViewData: {},
      };
    },
    checkAadharnoValidate: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    checkMobileValidate: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    personAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    personAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        OTPModel: false,
        model: false,
        rowdata: { is_defaultor: false },
      };
    },

    getcustomerByID: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getcustomerByIDSuccessful: (state, action) => {
      // console.log("moment(action.payload.response.dom)", action.payload.response.dom != null ? moment(action.payload.response.dom).format("YYYY-MM-DD") : "", (action.payload.response.dom));
      return {
        ...state,
        loading: false,
        // model: true,
        rowdata: {
          // id: action.payload.id,
          ...action.payload.response,
          firstname: action.payload.response.firstname,
          middlename: action.payload.response.middlename,
          lastname: action.payload.response.lastname,
          mobile: action.payload.response.mobile,
          alternatemobile: action.payload.response.alternatemobile,
          is_phone_verified: action.payload.response.is_phone_verified,
          aadharno: action.payload.response.aadharno,
          gender: action.payload.response.gender,
          housetype: action.payload.response.housetype,
          qualification: action.payload.response.qualification,
          addressline1: action.payload.response.addressline1,
          addressline2: action.payload.response.addressline2,
          ifsccode: action.payload.response.ifsccode,
          income: action.payload.response.income,
          accono: action.payload.response.accono,
          pincode: action.payload.response.pincode,
          state_id: action.payload.response.state?.id,
          district_id: action.payload.response.district?.id,
          city_id: action.payload.response.city?.id,
          bank_id: action.payload.response.bank?.id,
          area_id: action.payload.response.area?.id,
          occupation_id: action.payload.response.occupation?.id,
          dob: action.payload.response.dob == null ? "" : moment(action.payload.response.dob),
          defaultor: action.payload.response.is_defaultor,
          dom:
            action.payload.response.dom != null
              ? moment(action.payload.response.dom)
              : null,
          maritalstatus: action.payload.response.maritalstatus,
        },
        model: action.payload.type == "update" ? true : false,
        viewModel: action.payload.type == "view" ? true : false,
        customerViewData: action.payload.response,
      };
    },
    personDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      if (action.payload.key == "state_id") {
        return {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
            district_id: "",
            city_id: "",
            area_id: "",
          },
        };
      } else if (action.payload.key == "district_id") {
        return {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
            city_id: "",
            area_id: "",
          },
        };
      } else if (action.payload.key == "city_id") {
        return {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
            area_id: "",
          },
        };
      } else if (action.payload.key == "mobile") {
        return {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
            is_phone_verified: false,
          },
        };
      } else {
        return {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
          },
        };
      }
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: { is_defaultor: false },
        };
      } else {
        return {
          ...state,
          model: action.payload,
          rowdata: { is_defaultor: false },
        };
      }
    },
    SetPersonParams: (state, action) => {
      return {
        ...state,
        personParams: action.payload,
      };
    },
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
          // enquiryData: {},
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },

    FilterInputChangeValue: (state, action) => {
      if (action.payload.key == "state") {
        return {
          ...state,
          personParams: {
            ...state.personParams,
            [action.payload.key]: action.payload.value,
            district: "",
            city: "",
            area: "",
          },
        };
      } else if (action.payload.key == "district") {
        return {
          ...state,
          personParams: {
            ...state.personParams,
            [action.payload.key]: action.payload.value,
            city: "",
            area: "",
          },
        };
      } else if (action.payload.key == "city") {
        return {
          ...state,
          personParams: {
            ...state.personParams,
            [action.payload.key]: action.payload.value,
            area: "",
          },
        };
      } else {
        return {
          ...state,
          personParams: {
            ...state.personParams,
            [action.payload.key]: action.payload.value,
          },
        };
      }
    },
    customerViewModel: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          viewModel: action.payload,
        };
      } else {
        return {
          ...state,
          viewModel: action.payload,
          customerViewData: {},
        };
      }
    },
    // SetPersonFilterParams: (state, action) => {
    //   return {
    //     ...state,
    //     personFilterParams: action.payload,
    //   };
    // },
    personOTPModel: (state, action) => {
      return {
        ...state,
        loading: false,
        OTPModel: false,
      };
    },
    personOTPRequest: (state, action) => {
      return {
        ...state,
        loading: true,
        OTPModel: false,
        rowdata: {
          ...state.rowdata,
          is_phone_verified: false,
        },
      };
    },
    personOTPRequestSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        OTPModel: true,
        OTPData: action.payload,
      };
    },
    personOTPResend: (state, action) => {
      return {
        ...state,
        loading: true,
        rowdata: {
          ...state.rowdata,
          is_phone_verified: false,
        },
      };
    },
    personOTPResendSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    otpInputChangeValue: (state, action) => {
      return {
        ...state,
        OTPData: {
          ...state.OTPData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    verifyPersonOTP: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    verifyPersonOTPSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        OTPData: {},
        // rowdata: {
        //   ...state.rowdata,
        //   mobile: action.payload.mobile,
        //   is_phone_verified: true,
        // },
        OTPModel: false,
      };
    },
    fsyncStatusUpdate: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    checkFilterStatus: (state, action) => {
      return {
        ...state,
        filterStatus: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  checkAadharnoValidate,
  checkMobileValidate,
  getPerson,
  personListSuccessful,
  personAdd,
  personAddSuccessful,
  // personEdit,
  // personEditSuccessful,
  personDelete,
  InputChangeValue,
  SetPersonParams,
  apiError,
  isModelVisible,
  isDrawerVisible,
  FilterInputChangeValue,
  customerViewModel,
  getcustomerByID,
  getcustomerByIDSuccessful,
  clearPersonList,
  // SetPersonFilterParams,
  personOTPModel,
  personOTPRequest,
  personOTPRequestSuccessful,
  personOTPResend,
  personOTPResendSuccessful,
  otpInputChangeValue,
  verifyPersonOTP,
  verifyPersonOTPSuccessful,
  clearPersonData,
  fsyncStatusUpdate,
  checkFilterStatus,
} = personSlice.actions;

export default personSlice.reducer;
