import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  apiError,
  getFvQuestions,
  fvQuestionsListSuccessful,
  fvQuestionsAddSuccessful,
  isModelVisible,
  fvQuestionsByIDSuccessful
} from "./fvQuestionSlice";

// Auth Related Methods

import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
  getList
} from "../../../helpers/Helper";

import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

function* FvQuestions({ payload }) {
  console.log("payload", payload);
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/questions/", params);
    yield put(fvQuestionsListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddFvQuestions({ payload }) {
  try {
    if (payload.id == 0) {
      const response = yield call(postAdd, "/masters/questions/", payload);
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Field Verification Questions Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getFvQuestions({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Field Verification Questions. Please try again!
              </p>
            </div>
          ),
        });
      }
      yield put(fvQuestionsAddSuccessful(null));
    } else {
      const response = yield call(
        postEdit,
        "/masters/questions/" + payload.id,
        payload
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Field Verification Questions Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getFvQuestions({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Field Verification Questions. Please try again!
              </p>
            </div>
          ),
        });
      }
      yield put(fvQuestionsAddSuccessful(null));
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* DeleteFvQuestions({ payload }) {
  var areaID = payload;
  try {
    const response = yield call(postDelete, "/masters/questions/" + areaID);

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Field Verification Questions has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getFvQuestions({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Field Verification Questions has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

function* FvQuestionsByID({ payload }) {
  try {
    const response = yield call(
      getList,
      "/masters/questions/" + payload,
      {}
    );
    yield put(fvQuestionsByIDSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* watchGetFvQuestions() {
  yield takeEvery("fvQuestion/getFvQuestions", FvQuestions);
}

export function* watchAddfvQuestions() {
  yield takeEvery("fvQuestion/fvQuestionsAdd", AddFvQuestions);
}

export function* watchGetFvQuestionstByID() {
  yield takeEvery("fvQuestion/getFvQuestionsByID", FvQuestionsByID);
}

export function* watchFvQuestionsDelete() {
  yield takeEvery("fvQuestion/fvQuestionsDelete", DeleteFvQuestions);
}



function* FvQuestionSaga() {
  yield all([
    fork(watchGetFvQuestions),
    fork(watchAddfvQuestions),
    fork(watchGetFvQuestionstByID),
    fork(watchFvQuestionsDelete)
  ]);
}

export default FvQuestionSaga;
