import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  manualAttendanceListSuccessful,
  userAttendanceListSuccessful,
  getUserAttendanceList,
  userAttendanceByIdSuccessful,
  clearData,
  getManualAttendanceList,
} from "./manualAttendanceSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

//If user is login then dispatch redux action's are directly from here.
function* UserAttendanceList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/users/manualattendence/userlist/",
      params
    );
    yield put(userAttendanceListSuccessful( response ));
    yield put(getManualAttendanceList({ page: 1, search: "", page_size: 10 }));
  } catch (error) {
    yield put(apiError(error));
  }
}



function* ManualAttendanceList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/attendance/manualattendence/listcreate",
      params
    );
    yield put(manualAttendanceListSuccessful( response ));
  } catch (error) {
    yield put(apiError(error));
  }
}



// Date & User ById get api

function* UserAttendanceById({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/attendance/manualattendence/listcreate",
      params
    );
    yield put(userAttendanceByIdSuccessful( response ));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* AddAttendance({ payload }) {
  try {
    if (payload.id === 0 || payload.id === null || payload.id === undefined) {
      const response = yield call(postAdd, "/attendance/manualattendence/listcreate", payload);
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Manual Attendance Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getManualAttendanceList({ page: 1, search: "", page_size: 10 }));
        yield put(clearData());
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Manual Attendance. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response = yield call(
        postEdit,
        "/attendance/manualattendence/" + payload.id,
        payload
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Manual Attendance Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getManualAttendanceList({ page: 1, search: "", page_size: 10 }));
        yield put(clearData());
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Manual Attendance. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
















export function* watchGetUserAttendanceList() {
  yield takeEvery("manualAttendance/getUserAttendanceList", UserAttendanceList);
}
export function* watchGetManualAttendanceList() {
  yield takeEvery("manualAttendance/getManualAttendanceList", ManualAttendanceList);
}
export function* watchGetUserAttendanceById() {
  yield takeEvery("manualAttendance/getUserAttendanceById", UserAttendanceById);
}
export function* watchAttendanceAdd() {
  yield takeEvery("manualAttendance/attendanceAdd", AddAttendance);
}
function* manualAttendanceSaga() {
  yield all([
    fork(watchGetManualAttendanceList),
    fork(watchGetUserAttendanceList),
    fork(watchAttendanceAdd),
    fork(watchGetUserAttendanceById),
  ]);
}

export default manualAttendanceSaga;
