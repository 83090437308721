import { createSlice } from "@reduxjs/toolkit";

export const versionfeaturesSlice = createSlice({
  name: "versionfeatures",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    versionfeatureslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    versionfeaturesParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getVersionfeatures: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    versionfeaturesListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.versionfeaturesParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        versionfeatureslist: action.payload.response.results,
        loading: false,
        versionfeaturesParams: {
          ...state.versionfeaturesParams,
          no_of_pages: noofpages,
        },
      };
    },
    versionfeaturesAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    versionfeaturesAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    versionfeaturesEdit: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: action.payload,
      };
    },
    versionfeaturesEditSuccessful: (state) => {
      return { ...state, loading: false, rowdata: {} };
    },
    versionfeaturesDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetVersionfeaturesParams: (state, action) => {
      return {
        ...state,
        versionfeaturesParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getVersionfeatures,
  versionfeaturesListSuccessful,
  versionfeaturesAdd,
  versionfeaturesAddSuccessful,
  versionfeaturesEdit,
  versionfeaturesEditSuccessful,
  versionfeaturesDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetVersionfeaturesParams
} = versionfeaturesSlice.actions;

export default versionfeaturesSlice.reducer;
