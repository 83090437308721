import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
// Login Redux States

import {
  apiError,
  stockListSuccessful,
  stockByIDSuccessful,
  isModelVisible,
} from "./stockSlice";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

// AUTH related methods
import {
  getList,
  postAdd,
  postDownloadFile,
  getParamsList,
} from "../../../helpers/Helper";

function* StockList({ payload }) {
  var data = payload;
  var params = {};
  // for (const k in data) {
  //   if (Object.hasOwnProperty.call(data, k)) {
  //     if (k === "currentSort" || k === "sortOrder") {
  //       params.ordering = data.sortOrder + data.currentSort;
  //     } else {
  //       if (data[k] != "" && k != null && k != "no_of_pages") {
  //         params[k] = data[k];
  //       }
  //     }
  //   }
  // }

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        // console.log("stockdata", data, data[k], k, data[k] !== "" , data[k]  !== null , k !== "no_of_pages");
        if (data[k] !== "" && data[k] !== null && k !== "no_of_pages") {
          if (k == "vehicle__year") {
            params[k] = moment(data[k]).format("YYYY");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/purchase/allstock/", params);
    yield put(stockListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* StockById({ payload }) {
  try {
    const response = yield call(getList, "/purchase/" + payload, {});
    yield put(stockByIDSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddStockRequest({ payload }) {
  try {
    if (payload.id == 0) {
      const response = yield call(
        postAdd,
        "/stocktransfer/stockrequestout/",
        payload
      );
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Stock Request Added Successfully.!
              </p>
            </div>
          ),
        });
        // yield put(getEnquirys({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Stock Request. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    if(error.response.status == 400){
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    }else{
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}

export function* watchGetStockList() {
  yield takeEvery("stock/getStockList", StockList);
}

export function* watchGetStockById() {
  yield takeEvery("stock/getStockById", StockById);
}
export function* watchStockRequestAdd() {
  yield takeEvery("stock/stockRequestAdd", AddStockRequest);
}
function* stockSaga() {
  yield all([
    fork(watchGetStockList),
    fork(watchGetStockById),
    fork(watchStockRequestAdd),
  ]);
}

export default stockSaga;
