import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  idealStockListSuccessful,
  idealStockVersionListSuccessful,
  getIdealStockList,
  updateIdealStockListSuccessful,
  updateIdealStockList
} from "./idealStockSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

function* IdealStockList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/purchase/groupedidealstock/", params);
    yield put(idealStockListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}



function* IdealStockVehicles({ payload }) {
  var params = {};
  var data = payload.params;
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "type") {
        params.type = data.type.join(",");
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/idealstock/versions/", params);
    yield put(idealStockVersionListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* IdealStockAdd({ payload }) {
  try {
      const response = yield call(postAdd, "/purchase/idealstock/listcreate/", payload.obj );
      if (response.status === 200 || response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                   Ideal Stock Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getIdealStockList({ page: 1, search: "", page_size: 10, counter: payload.obj.counter_id  }));
        yield put(updateIdealStockList({page: 1, search: "", page_size: 10}));
        payload.history.push("/pages/ideal-stock");
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to add Ideal Stock. Please try again!
              </p>
            </div>
          ),
        });
      }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}


function* IdealStockUpdateList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/purchase/idealstockcounterlist/", params);
    yield put(updateIdealStockListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetIdealStockList() {
  yield takeEvery("idealStock/getIdealStockList", IdealStockList);
}
export function* watchAddIdealStock() {
  yield takeEvery("idealStock/addIdealStock", IdealStockAdd);
}
export function* watchGetIdealStockVersion() {
  yield takeEvery("idealStock/getIdealStockVersion", IdealStockVehicles);
}
export function* watchUpdateIdealStockList() {
  yield takeEvery("idealStock/updateIdealStockList", IdealStockUpdateList);
}
function* idealStockSaga() {
  yield all([
    fork(watchGetIdealStockList),
    fork(watchGetIdealStockVersion),
    fork(watchAddIdealStock),
    fork(watchUpdateIdealStockList),
  ]);
}

export default idealStockSaga;
