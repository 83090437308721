import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { retry } from "redux-saga/effects";

export const qrSlice = createSlice({
  name: "qr",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    imageLooading: false,
    model: false,
    drawer: false,
    error_msg: null,
    listCount: 0,
    QRList: [],
    QRParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    rowdata: {},
    visitViewData: {},
    vehicleDetails: {},
    viewModel: false,


    QRCodeModel: false,
    QRCodeStatus: true,
  },
  reducers: {
    clearVisitData: (state, action) => {
      return{
        ...state,
        loading: false,
        QRList: [],
        rowdata: {},
      }
    },
    getQRList: (state, action) => {
      if (state.QRParams?.page == 1) {
        return {
          ...state,
          loading: true,
          QRList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
   QRListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.QRParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.QRParams?.page_size
      );
      return {
        ...state,
        QRList: list,
        listCount: action.payload.count,
        loading: false,
        QRParams: {
          ...state.QRParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetQRParams: (state, action) => {
      return {
        ...state,
        QRParams: action.payload,
      };
    },
    qrAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getQRCodes:(state, action) => {
      return {
        ...state,
        loading: true,
        // params : action.payload,
      }
    },
    getQRCodeParams: (state, action) => {
      return {
        ...state,
        loading: false,
        params: action.payload,
      }
    },
    getGeneratePDF:(state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      }
    },
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        QRParams: {
          ...state.QRParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
   
    isViewModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          viewModel: action.payload,
          visitViewData: {},
        };
      } else {
        return {
          ...state,
          viewModel: action.payload,
        };
      }
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    },
  },
});
export const {
  getQRList,
  QRListSuccessful,
  SetQRParams,
  qrAdd,
  InputChangeValue,
  isModelVisible,
  isDrawerVisible,
  FilterInputChangeValue,
  isViewModelVisible,
  clearVisitData,
  getQRCodes,
  getGeneratePDF,
  apiError,
  getQRCodeParams,
} = qrSlice.actions;

export default qrSlice.reducer;
