import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { message, Modal  } from "antd";
import { RiCheckboxCircleLine } from "react-icons/ri";
// Login Redux States
import { apiError, notificationListSuccessful, getNotificationList, notificationActive } from "./notificationSlice";

// AUTH related methods
import {
  getParamsList,
  simplePost,
  postAdd,
  postLogout,
  postEdit
} from "../../helpers/Helper";

//If user is login then dispatch redux action's are directly from here.

function* NotificationList({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/system/Notification/", params);
    yield put(notificationListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* NotifitionRead({ payload }) {
  console.log("payload", payload);
  try {
    const response = yield call(postEdit, "/system/Notification/Clear/" + payload, {});
    if (response.status === 200) {
      // Modal.success({
      //   icon: (
      //     <span className="remix-icon">
      //       <RiCheckboxCircleLine />
      //     </span>
      //   ),
      //   title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
      //   content: (
      //     <div>
      //       <p className="hp-p1-body hp-text-color-black-80">
      //         Notification Read Successfully.!
      //       </p>
      //     </div>
      //   ),
      // });
      yield put(notificationActive(false));
      yield put(getNotificationList({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to update Notification. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (errors) {
    yield put(apiError(errors));
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
  }
}

export function* watchGetNotificationList() {
  yield takeEvery("notification/getNotificationList", NotificationList);
}
export function* watchReadNotifition() {
  yield takeEvery("notification/readNotifition", NotifitionRead);
}

// export function* watchUserLogout() {
//   yield takeEvery("login/logoutUser", logoutUser);
// }

function* notificationSaga() {
  yield all([
    fork(watchGetNotificationList),
    fork(watchReadNotifition),
    // fork(watchUserLogout),
  ]);
}

export default notificationSaga;
