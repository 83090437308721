import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
var date = new Date(),
  y = date.getFullYear(),
  m = date.getMonth();
export const versionsSlice = createSlice({
  name: "versions",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    versionslist: [],
    model: false,
    drawer: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    versionsParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getVersions: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    versionsListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.count / state.versionsParams.page_size
      );

      return {
        ...state,
        listCount: action.payload.count,
        versionslist: action.payload.results,
        loading: false,
        versionsParams: {
          ...state.versionsParams,
          no_of_pages: noofpages,
        },
      };
    },
    versionsAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    versionsAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    versionsEdit: (state, action) => {
      var versionfeature = [];
      versionfeature = action.payload.versionfeature.map((versionfeature) => {
        return versionfeature.id;
      });
      return {
        ...state,
        model: true,
        rowdata: {
          id: action.payload.id,
          name: action.payload.name,
          brand_id: action.payload.brand?.id,
          brandfamily_id: action.payload.brandfamily?.id,
          model_id: action.payload.model?.id,
          vehicletype_id: action.payload.vehicletype?.id,
          versionfeature_ids: versionfeature,
          priority_id: action.payload.priority?.id,
          fueltype: action.payload.fueltype,
          enginedisplacement: action.payload.enginedisplacement,
          firstparty: action.payload.firstparty,
          secondparty: action.payload.secondparty,
          thirdparty: action.payload.thirdparty,
          defaulter: action.payload.defaulter,
        },
      };
    },
    versionsEditSuccessful: (state) => {
      return {
        ...state,
        loading: false,
        rowdata: {},
      };
    },
    versionsDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      if (action.payload.key == "brand_id") {
        return {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
            brandfamily_id: "",
            model_id: "",
          },
        };
      } else if (action.payload.key == "brandfamily_id") {
        return {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
            model_id: "",
          },
        };
      } else {
        return {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
          },
        };
      }
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetVersionsParams: (state, action) => {
      return {
        ...state,
        versionsParams: action.payload,
      };
    },
    FilterInputChangeValue: (state, action) => {
      if (action.payload.key == "brand") {
        return {
          ...state,
          versionsParams: {
            ...state.versionsParams,
            [action.payload.key]: action.payload.value,
            brandfamily: "",
            model: "",
          },
        };
      } else if (action.payload.key == "brandfamily") {
        return {
          ...state,
          versionsParams: {
            ...state.versionsParams,
            [action.payload.key]: action.payload.value,
            model: "",
          },
        };
      } else {
        return {
          ...state,
          versionsParams: {
            ...state.versionsParams,
            [action.payload.key]: action.payload.value,
          },
        };
      }

    },
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
          // enquiryData: {},
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    },
  },
});
export const {
  getVersions,
  versionsListSuccessful,
  versionsAdd,
  versionsAddSuccessful,
  versionsEdit,
  versionsEditSuccessful,
  versionsDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetVersionsParams,
  isDrawerVisible,
  FilterInputChangeValue
} = versionsSlice.actions;

export default versionsSlice.reducer;
