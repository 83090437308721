import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
export const targetSlice = createSlice({
  name: "target",
  initialState: {
    loginError: "aaa",
    targetModel: false,
    saleAgentList: [],
    saleAgentParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    targetData: {},
    activeTab: 1,
    counterList: [],
    counterParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    targetDataFilter: {
      start_date: "",
      end_date: "",
    },
    loading: false,
    drawer: false,
    apiError: {},
    listCount: 0,

    achievedSaleAgentList: [],
    achievedSaleAgentParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      date: moment().startOf("month"),
    },
    achievedListCount: 0,
    achievedActiveTab: 1,
    achievedCounterList: [],
    achievedCounterParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      date: moment().startOf("month"),
    },
    counterListCount: 0,
    achieveDrawer: false,
    achievedDataFilter: {
      start_date: "",
      end_date: "",
      date: moment().startOf("month"),
    },
    saleAgentData: {},
    saleAgentModel: false,
    saleAgentCount: {},
  },
  reducers: {
    getSaleAgentList: (state) => {
      if (state.saleAgentParams?.page == 1) {
        return {
          ...state,
          loading: true,
          saleAgentList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    saleAgentListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.saleAgentParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.saleAgentParams?.page_size
      );
      return {
        ...state,
        saleAgentList: list,
        listCount: action.payload.count,
        loading: false,
        saleAgentParams: {
          ...state.saleAgentParams,
          no_of_pages: noofpages,
        },
      };
    },
    setSaleAgentListParams: (state, action) => {
      return {
        ...state,
        saleAgentParams: action.payload,
      };
    },
    isModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          targetModel: action.payload,
        };
      } else {
        return {
          ...state,
          loading: false,
          targetModel: action.payload,
          targetData: {},
        };
      }
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        targetData: {
          ...state.targetData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    SelectChangeValue: (state, action) => {
      return {
        ...state,
        targetData: {
          ...state.targetData,
          [action.payload.key]: {
            id: action.payload.value,
            name: action.payload.name,
          },
        },
      };
    },
    TargetsActiveTab: (state, action) => {
      return {
        ...state,
        // loading: true,
        activeTab: action.payload,
        targetDataFilter: {},
      };
    },
    saleAgentTargetAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    saleAgentTargetSuccessfully: (state, action) => {
      return {
        ...state,
        loading: false,
        targetModel: false,
        targetData: {},
      };
    },
    counterTargetAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    counterTargetSuccessfully: (state, action) => {
      return {
        ...state,
        loading: false,
        targetModel: false,
        targetData: {},
      };
    },
    getSaleAgentByID: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    saleAgentByIDSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        targetData: {
          ...action.payload,
          type: 1,
          date: moment(action.payload.date),
          // user_id: action.payload.user?.id,
          user: {
            id: action.payload.user?.id,
            name:
              action.payload.user?.fullname +
              " - " +
              action.payload.user?.phone,
            // first_name: action.payload.user?.first_name,
          },
        },
        targetModel: true,
      };
    },
    SaleAgentDelete: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    // Counter
    getCounterList: (state) => {
      if (state.counterParams?.page == 1) {
        return {
          ...state,
          loading: true,
          counterList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    counterListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.counterParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.counterParams?.page_size
      );
      return {
        ...state,
        counterList: list,
        listCount: action.payload.count,
        loading: false,
        counterParams: {
          ...state.counterParams,
          no_of_pages: noofpages,
        },
      };
    },
    setCounterListParams: (state, action) => {
      return {
        ...state,
        counterParams: action.payload,
      };
    },
    getCounterByID: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    counterByIDSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        targetData: {
          ...action.payload,
          type: 2,
          date: moment(action.payload.date),
          counter_id: action.payload.counter?.id,
        },
        targetModel: true,
      };
    },
    CounterDelete: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    setFilterParams: (state, action) => {
      return {
        ...state,
        loading: false,
        targetDataFilter: { start_date: "", end_date: "" },
      };
    },

    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        targetDataFilter: {
          ...state.targetDataFilter,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },

    // Target Achieve Api's
    getAchievedSaleAgentList: (state) => {
      if (state.achievedSaleAgentParams?.page == 1) {
        return {
          ...state,
          loading: true,
          achievedSaleAgentList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    achievedSaleAgentListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.achievedSaleAgentParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.achievedSaleAgentParams?.page_size
      );
      return {
        ...state,
        achievedSaleAgentList: list,
        achievedListCount: action.payload.count,
        loading: false,
        achievedSaleAgentParams: {
          ...state.achievedSaleAgentParams,
          no_of_pages: noofpages,
        },
      };
    },
    setAchievedSaleAgentParams: (state, action) => {
      return {
        ...state,
        achievedSaleAgentParams: action.payload,
      };
    },
    TargetAchievedActiveTab: (state, action) => {
      return {
        ...state,
        // loading: true,
        achievedActiveTab: action.payload,
        // achievedDataFilter: {},
      };
    },

    getAchievedCounterList: (state) => {
      if (state.achievedCounterParams?.page == 1) {
        return {
          ...state,
          loading: true,
          achievedCounterList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    achievedCounterListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.achievedCounterParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.achievedCounterParams?.page_size
      );
      return {
        ...state,
        achievedCounterList: list,
        counterListCount: action.payload.count,
        loading: false,
        achievedCounterParams: {
          ...state.achievedCounterParams,
          no_of_pages: noofpages,
        },
      };
    },
    setAchievedCounterParams: (state, action) => {
      return {
        ...state,
        achievedCounterParams: action.payload,
      };
    },
    isAchievedDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          achieveDrawer: action.payload,
        };
      } else {
        return {
          ...state,
          achieveDrawer: action.payload,
        };
      }
    },
    AchievedFilterInputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "counter__zone":
          return {
            ...state,
            achievedDataFilter: {
              ...state.achievedDataFilter,
              [action.payload.key]: action.payload.value,
              counter__cluster: null,
              counter__division: null,
              counter: null,
            },
          };

        case "counter__division":
          return {
            ...state,
            achievedDataFilter: {
              ...state.achievedDataFilter,
              [action.payload.key]: action.payload.value,
              counter__cluster: null,
              counter: null,
            },
          };

        case "counter__cluster":
          return {
            ...state,
            achievedDataFilter: {
              ...state.achievedDataFilter,
              [action.payload.key]: action.payload.value,
              counter: null,
            },
          };
        default:
          return {
            ...state,
            achievedDataFilter: {
              ...state.achievedDataFilter,
              [action.payload.key]: action.payload.value,
            },
          };
      }
      // return {
      //   ...state,
      //   achievedDataFilter: {
      //     ...state.achievedDataFilter,
      //     [action.payload.key]: action.payload.value,
      //   },
      // };
    },
    setAchievedFilterParams: (state, action) => {
      return {
        ...state,
        loading: false,
        achievedDataFilter: { start_date: "", end_date: "" },
      };
    },
    isSaleAgentViewModel: (state, action) => {
      if (action.payload.model) {
        return {
          ...state,
          loading: false,
          saleAgentData: action.payload.saleAgentData,
          saleAgentModel: action.payload.model,
        };
      } else {
        return {
          ...state,
          loading: false,
          saleAgentData: {},
          saleAgentModel: action.payload.model,
        };
      }
    },
    getSaleAgentAchieveCount: (state, action) => {
      return {
        ...state,
        loading: true,
        saleAgentCount: {},
      };
  },
  saleAgentAchieveCountSuccessful: (state, action) => {
    return {
      ...state,
      saleAgentCount: action.payload.response,
      loading: false,
    };
  },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  isModelVisible,
  getSaleAgentList,
  saleAgentListSuccessful,
  setSaleAgentListParams,
  InputChangeValue,
  TargetsActiveTab,
  saleAgentTargetAdd,
  saleAgentTargetSuccessfully,
  getSaleAgentByID,
  saleAgentByIDSuccessful,
  SaleAgentDelete,
  counterTargetAdd,
  counterTargetSuccessfully,
  getCounterList,
  counterListSuccessful,
  setCounterListParams,
  getCounterByID,
  counterByIDSuccessful,
  CounterDelete,
  isDrawerVisible,
  FilterInputChangeValue,
  setFilterParams,
  SelectChangeValue,

  getAchievedSaleAgentList,
  achievedSaleAgentListSuccessful,
  setAchievedSaleAgentParams,
  TargetAchievedActiveTab,
  getAchievedCounterList,
  achievedCounterListSuccessful,
  setAchievedCounterParams,
  isAchievedDrawerVisible,
  AchievedFilterInputChangeValue,
  setAchievedFilterParams,
  isSaleAgentViewModel,
  getSaleAgentAchieveCount,
  saleAgentAchieveCountSuccessful,
  apiError,
} = targetSlice.actions;

export default targetSlice.reducer;
