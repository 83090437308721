import { createSlice } from "@reduxjs/toolkit";

export const fvQuestionSlice = createSlice({
  name: "fvQuestion",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    fvQuestionsList: [],
    model: false,
    drawer: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    rowDataView: {},
    fvQuestionsParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getFvQuestions: (state) => {
      if (state.fvQuestionsParams.page == 1) {
        return {
          ...state,
          loading: true,
          fvQuestionsList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    fvQuestionsListSuccessful: (state, action) => {
      var noofpages = Math.ceil(action.payload.count / state.fvQuestionsParams.page_size);
      return {
        ...state,
        listCount: action.payload.count,
        fvQuestionsList: action.payload.results,
        loading: false,
        fvQuestionsParams: {
          ...state.fvQuestionsParams,
          no_of_pages: noofpages,
        },
      };
    },
    fvQuestionsAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    fvQuestionsAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    getFvQuestionsByID: (state) => {
      return {
        ...state,
        loading: false,
        // rowdata: {},
      };
     
    },
    fvQuestionsByIDSuccessful: (state, action) => {
      console.log("reEstimationByIDSuccessful", action.payload);
      return {
        ...state,
        // model: true,
        rowdata: {
          id: action.payload.id,
          question: action.payload.question,
        },
        rowDataView: action.payload
      };
    },
    fvQuestionsDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetFvQuestionsParams: (state, action) => {
      return {
        ...state,
        fvQuestionsParams: action.payload,
      };
    },
    
    
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    },
  },
});
export const {
  getFvQuestions,
  fvQuestionsListSuccessful,
  fvQuestionsAdd,
  fvQuestionsAddSuccessful,
  getFvQuestionsByID,
  fvQuestionsByIDSuccessful,
  fvQuestionsDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetFvQuestionsParams,
} = fvQuestionSlice.actions;

export default fvQuestionSlice.reducer;
