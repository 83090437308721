import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  getVehicles,
  vehiclesListSuccessful,
  isModelVisible,
  InputChangeValue,
  vehicleByIDSuccessful,
} from "./vehiclesSlice";
import {
  getPurchaseDoc,
  getPurchaseCheckApprove,
} from "../../../store/sideMenu/purchases/purchasesSlice";
import {
  getVehicleSaleDoc,
  getSalesCheckApprove,
} from "../../../store/sideMenu/vehicleCounterSales/vehicleCounterSalesSlice";

import { getMiniVehicles, getMiniVehiclesNonStock } from "../../mini/miniSlice";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
// Auth Related Methods
import {
  getMiniBrandFamily,
  getMiniModels,
  getMiniVersion,
} from "../../../store/mini/miniSlice";
import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
  getList,
} from "../../../helpers/Helper";

// function* VehiclesGet({ payload }) {
//   var data = payload;
//   var params = {};

//   for (const k in data) {
//     if (Object.hasOwnProperty.call(data, k)) {
//       if (k === "currentSort" || k === "sortOrder") {
//         params.ordering = data.sortOrder + data.currentSort;
//       } else {
//         if (data[k] != "" && k != null && k != "no_of_pages") {
//           params[k] = data[k];
//         }
//       }
//     }
//   }
//   try {
//     const response = yield call(getParamsList, "/masters/vehicles/", params);
//     yield put(vehiclesListSuccessful(response));
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }

function* VehiclesGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/vehicles/", params);
    yield put(vehiclesListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddVehicles({ payload }) {
  try {
    if (payload.obj.id == 0) {
      const response = yield call(postAdd, "/masters/vehicles/", payload.obj);
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Vehicle Added Successfully.!
              </p>
            </div>
          ),
        });

        yield put(getVehicles({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Vehicle. Please try again!
              </p>
            </div>
          ),
        });
      }
      yield put(getMiniVehicles({ page: 1, search: "", page_size: 10 }));
      yield put(
        getMiniVehiclesNonStock({ page: 1, search: "", page_size: 10 })
      );
    } else {
      const response = yield call(
        postEdit,
        "/masters/vehicles/" + payload.obj.id,
        payload.obj
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Vehicle Edited Successfully.!
              </p>
            </div>
          ),
        });
        if (payload.type === "purchase") {
          yield put(getPurchaseDoc(payload.typeId));
          yield put(
            getPurchaseCheckApprove({
              purchase_id: payload.typeId,
            })
          );
        } else if (payload.type === "sale") {
          yield put(getVehicleSaleDoc(payload.typeId));
          yield put(
            getSalesCheckApprove({
              sale_id: payload.typeId,
            })
          );
        } else {
          yield put(getVehicles({ page: 1, search: "", page_size: 10 }));
        }
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Vehicle. Please try again!
              </p>
            </div>
          ),
        });
      }
      // yield put(vehiclesAddSuccessful(null));
    }
  } catch (error) {
    {
      error.vehicleno
        ? Modal.error({
            icon: (
              <span className="remix-icon">
                <RiAlertLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  {error.vehicleno[0]}
                </p>
              </div>
            ),
          })
        : Modal.error({
            icon: (
              <span className="remix-icon">
                <RiAlertLine />
              </span>
            ),

            title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  Sorry! Getting from server side issue!
                </p>
              </div>
            ),
          });
    }

    yield put(apiError(error));
  }
}
function* DeleteVehicles({ payload }) {
  var areaID = payload;
  try {
    const response = yield call(postDelete, "/masters/vehicles/" + areaID);

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Vehicle has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getVehicles({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Vehicle has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

function* VehicleValidate({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/masters/vehicleno/validation/",
      payload
    );
  } catch (error) {
    if (error.response.status == 400) {
      yield put(
        InputChangeValue({
          key: "vehicleno",
          value: "",
        })
      );
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    } else if (error.response.status == 500) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}

function* EngineValidate({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/masters/engineno/validation/",
      payload
    );
  } catch (error) {
    if (error.response.status == 400) {
      yield put(
        InputChangeValue({
          key: "engineno",
          value: "",
        })
      );
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    } else if (error.response.status == 500) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}

function* ChassisValidate({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/masters/chassisno/validation/",
      payload
    );
  } catch (error) {
    if (error.response.status == 400) {
      yield put(
        InputChangeValue({
          key: "chassisno",
          value: "",
        })
      );
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    } else if (error.response.status == 500) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}

function* VehicleByID({ payload }) {
  try {
    const response = yield call(
      getParamsList,
      "/masters/vehicles/" + payload,
      {}
    );
    yield put(getMiniBrandFamily({ brand: response.brand?.id }));
    yield put(getMiniModels({ brandfamily: response.brandfamily?.id }));
    yield put(getMiniVersion({ model: response.model?.id }));
    yield put(vehicleByIDSuccessful(response));

    // yield put(isModelVisible(true));
  } catch (error) {
    yield put(apiError(error));
  }
}


// function* UpdateFsyncStatus({ payload }) {
//   try {
//     const response = yield call(
//       postEdit,
//       "/masters/vehiclerepost/"  + payload.id, {}
//     );
//     if (response.status === 200 ) {
//       if( response.data.focus_post == true){
//         Modal.success({
//           icon: (
//             <span className="remix-icon">
//               <RiCheckboxCircleLine />
//             </span>
//           ),
//           title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
//           content: (
//             <div>
//               <p className="hp-p1-body hp-text-color-black-80">
//                 Selected Vehicle is successfully synchronised into focus
//               </p>
//             </div>
//           ),
//         });
//       }else{
//         Modal.error({
//           icon: (
//             <span className="remix-icon">
//               <RiAlertLine />
//             </span>
//           ),
//           title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
//           content: (
//             <div>
//               <p className="hp-p1-body hp-text-color-black-80">
//                 Sorry! selected Vehicle is not posted on focus. please try
//                 again!
//               </p>
//             </div>
//           ),
//         });
//       }
//       yield put(getVehicles({ page: 1, search: "", page_size: 10 }));
//     } else {
//       Modal.error({
//         icon: (
//           <span className="remix-icon">
//             <RiAlertLine />
//           </span>
//         ),
//         title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
//         content: (
//           <div>
//             <p className="hp-p1-body hp-text-color-black-80">
//               Sorry! selected Vehicle is not posted on focus. please try
//               again!
//             </p>
//           </div>
//         ),
//       });
//     }
//   } catch (error) {
//     Modal.error({
//       icon: (
//         <span className="remix-icon">
//           <RiAlertLine />
//         </span>
//       ),

//       title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
//       content: (
//         <div>
//           <p className="hp-p1-body hp-text-color-black-80">
//             Sorry! Getting from server side issue!
//           </p>
//         </div>
//       ),
//     });
//     yield put(apiError(error));
//   }
// }


function* UpdateFsyncStatus({ payload }) {
  let vehicleParamsData = yield select(
    (state) => state.vehicles.vehiclesParams
  );
  try {
    const response = yield call(
      getParamsList,
      "/masters/vehiclerepost/" + payload.id,
      {}
    );
    if (response.post_status == true) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Selected Vehicle is successfully synchronised into focus
            </p>
          </div>
        ),
      });
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! selected Vehicle is not posted on focus. please try again!
            </p>
          </div>
        ),
      });
    }
    yield put(getVehicles(vehicleParamsData));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}


function* watchGetVehicles() {
  yield takeEvery("vehicles/getVehicles", VehiclesGet);
}

export function* watchVehiclesAdd() {
  yield takeEvery("vehicles/vehiclesAdd", AddVehicles);
}

export function* watchVehiclesDelete() {
  yield takeEvery("vehicles/vehiclesDelete", DeleteVehicles);
}

export function* watchVehicleNumberValidate() {
  yield takeEvery("vehicles/vehicleNumberValidate", VehicleValidate);
}

export function* watchEngineNumberValidate() {
  yield takeEvery("vehicles/engineNumberValidate", EngineValidate);
}

export function* watchChassisNumberValidate() {
  yield takeEvery("vehicles/chassisNumberValidate", ChassisValidate);
}
export function* watchGetvehicleByID() {
  yield takeEvery("vehicles/getvehicleByID", VehicleByID);
}
export function* watchFsyncStatusUpdate() {
  yield takeEvery("vehicles/fsyncStatusUpdate", UpdateFsyncStatus);
}
function* VehiclesSaga() {
  yield all([
    fork(watchGetVehicles),
    fork(watchVehiclesAdd),
    fork(watchVehiclesDelete),
    fork(watchVehicleNumberValidate),
    fork(watchEngineNumberValidate),
    fork(watchChassisNumberValidate),
    fork(watchGetvehicleByID),
    fork(watchFsyncStatusUpdate),
  ]);
}

export default VehiclesSaga;
