import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
var date = new Date(),
  y = date.getFullYear(),
  m = date.getMonth();
export const versionsSlice = createSlice({
  name: "bannersAds",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    bannersAdslist: [],
    model: false,
    drawer: false,
    error_msg: null,
    imageLooading: false,
    rowdata: {},
    listCount: 0,


    bannerAdsParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getBannersAds: (state) => {
      if (state.bannerAdsParams?.page == 1) {
        return {
          ...state,
          loading: true,
          bannersAdslist: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    bannersAdsListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.bannerAdsParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.bannersAdslist, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.bannerAdsParams?.page_size
      );
      return {
        ...state,
        listCount: response.count,
        bannersAdslist: list,
        loading: false,
        bannerAdsParams: {
          ...state.bannerAdsParams,
          no_of_pages: noofpages,
        },
      };
    },
    setBannerAdsParams: (state, action) => {
      return {
        ...state,
        bannerAdsParams: action.payload,
      };
    },
    clearData: (state, action) => {
      return {
        ...state,
        bannersAdslist:[],
      }
    },

    bannerImgUpload: (state, action) => {
      return {
        ...state,
        // loading: false,
        imageLooading: false,
        rowdata: {
          ...state.rowdata,
          [action.payload.imageType]: action.payload.file,
          // banner_img_url: null,
        },
      };
    },
    setPreviewImg: (state, action) => {
        return{
          ...state,
          loading: false,
          rowdata: {
            ...state.rowdata,
            previewFile: action.payload,
          }
        }
    },
    // bannerImgUploadSuccessful: (state, action) => {
    //   return {
    //     ...state,
    //     loading: false,
    //     imageLooading: false,
    //     rowdata: {
    //       ...state.rowdata,
    //       [action.payload.ImageType]: action.payload.response.data,
    //       [action.payload.ImageType + "_id"]: action.payload.response.data.id,
    //     },
    //   };
    // },

    getBannerAdsById: (state, action) =>{
      return{
        ...state,
        loading: true,
      }
    },

    bannerAdsByIdSuccessful: (state, action) => {
      return{
        ...state,
        // model: true,
        loading: false,
        rowdata: {
          id: action.payload.id,
          name: action.payload.name,
          bannertype: action.payload.bannertype,
          is_active: action.payload.is_active,
          banner_img: action.payload.banner_img,
          previewImg: action.payload.banner_img,
          // is_video: action.payload.is_video,
          // video_url: action.payload.video_url,
        }
      }
    },

    bannerAdsAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    bannerAdsDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      // if (action.payload.key == "brand_id") {
      //   return {
      //     ...state,
      //     rowdata: {
      //       ...state.rowdata,
      //       [action.payload.key]: action.payload.value,
      //       brandfamily_id: "",
      //       model_id: "",
      //     },
      //   };
      // } else if (action.payload.key == "brandfamily_id") {
      //   return {
      //     ...state,
      //     rowdata: {
      //       ...state.rowdata,
      //       [action.payload.key]: action.payload.value,
      //       model_id: "",
      //     },
      //   };
      // } else {
      //   return {
      //     ...state,
      //     rowdata: {
      //       ...state.rowdata,
      //       [action.payload.key]: action.payload.value,
      //     },
      //   };
      // }
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    

    FilterInputChangeValue: (state, action) => {
      if (action.payload.key == "brand") {
        return {
          ...state,
          versionsParams: {
            ...state.versionsParams,
            [action.payload.key]: action.payload.value,
            brandfamily: "",
            model: "",
          },
        };
      } else if (action.payload.key == "brandfamily") {
        return {
          ...state,
          versionsParams: {
            ...state.versionsParams,
            [action.payload.key]: action.payload.value,
            model: "",
          },
        };
      } else {
        return {
          ...state,
          versionsParams: {
            ...state.versionsParams,
            [action.payload.key]: action.payload.value,
          },
        };
      }

    },
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
          // enquiryData: {},
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    },
  },
});
export const {
  getBannersAds,
  bannersAdsListSuccessful,
  setBannerAdsParams,
  bannerImgUpload,
  // bannerImgUploadSuccessful,
  getBannerAdsById,
  bannerAdsByIdSuccessful,
  InputChangeValue,
  apiError,
  isModelVisible,
  bannerAdsAdd,
  bannerAdsDelete,
  isDrawerVisible,
  FilterInputChangeValue,
  clearData,
  setPreviewImg,
} = versionsSlice.actions;

export default versionsSlice.reducer;
