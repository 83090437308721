import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States

import {
  apiError,
  ReportListSuccessful,
  loadingStatus,
  materialItemSuccessful,
  reportStatus,
  reportDownload,
  delayReport,
  reportDownloadSuccessful,
  getReportList,
  getScheduleReports,
  ScheduleReportsSuccess,
  getScheduleReportsSuccess,
  startDeleteScheduleReportById,
  DeleteScheduleReportByIdSuccess,
  startPostScheduleReport,
  postScheduleReportSucess,
} from "./reportsSlice";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

// AUTH related methods
import {
  getList,
  postAdd,
  postDownloadFile,
  getParamsList,
  postExcelDownloadFile,
  postDelete,
} from "../../helpers/Helper";
import moment from "moment";

// function* ReportList({ payload }) {
//   console.log("ReportList", payload.ParamsData);
//   var data = payload.ParamsData;
//   var params = {};

//   for (const k in data) {
//     if (Object.hasOwnProperty.call(data, k)) {
//       if (k === "currentSort" || k === "sortOrder") {
//         params.ordering = data.sortOrder + data.currentSort;
//       } else {
//         if (data[k] !== "" && k !== "no_of_pages") {
//           params[k] = data[k];
//         }
//       }
//     }
//   }
//   console.log("params", params);
//   try {
//     if (payload.objData.file_format == 5) {
//       const response = yield call(
//         postAdd,
//         "/reports/generic_export/",
//         payload.objData,
//         params
//       );
//       yield put(ReportListSuccessful(response.data));
//     } else {
//       yield call(
//         postDownloadFile,
//         "/reports/generic_export/",
//         payload.objData,
//         params
//       );
//       Modal.success({
//         icon: (
//           <span className="remix-icon">
//             <RiCheckboxCircleLine />
//           </span>
//         ),
//         title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
//         content: (
//           <div>
//             <p className="hp-p1-body hp-text-color-black-80">
//               Your Report Generated Successfully.!
//             </p>
//           </div>
//         ),
//       });
//       yield put(loadingStatus(false));
//     }
//   } catch (error) {
//     Modal.error({
//       icon: (
//         <span className="remix-icon">
//           <RiAlertLine />
//         </span>
//       ),
//       title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
//       content: (
//         <div>
//           <p className="hp-p1-body hp-text-color-black-80">{error}</p>
//         </div>
//       ),
//     });

//     yield put(apiError(error));
//   }
// }

function* ReportList({ payload }) {
  var data = payload.ParamsData;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    // if (payload.objData.file_format == 5) {
    //   const response = yield call(
    //     postAdd,
    //     "/reports/generic_export/",
    //     payload.objData,
    //     params
    //   );
    //   // yield put(reportStatus())
    //   yield put(ReportListSuccessful(response.data));
    // } else {

    // }
    let response = yield call(
      postAdd,
      "/reports/generic_export/",
      payload.objData,
      params
    );
    if (!response.status) {
      params.page = 1;
      response = yield call(
        postAdd,
        "/reports/generic_export/",
        payload.objData,
        params
      );
      yield put(
        getReportList({ objData: payload.objData, ParamsData: params })
      );
    }
    if (response.status == 201) {
      yield put(
        reportStatus({
          request_id: response.data.request_id,
        })
      );
    } else if (response.status == 200) {
      yield put(ReportListSuccessful(response.data));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! failed to generate the report!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* StatusReport({ payload }) {
  try {
    var response = yield call(
      postAdd,
      "/reports/generic_export/status/" + payload.request_id + "/",
      {}
    );
    if (response.status === 201) {
      yield put(reportDownload({ request_id: payload.request_id }));
    } else if (response.status === 202) {
      yield put(delayReport({ request_id: payload.request_id }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! failed to generate the process report!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* DownloadReport({ payload }) {
  try {
    var response = yield call(
      postDownloadFile,
      "/reports/generic_export/download/" + payload.request_id + "/",
      {}
    );
    // yield put(loadingStatus(false));
    // if(response.status == 200){
    Modal.success({
      icon: (
        <span className="remix-icon">
          <RiCheckboxCircleLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Your Report Generated Successfully.!
          </p>
        </div>
      ),
    });
    yield put(reportDownloadSuccessful());
    // }
    // else{
    //   Modal.error({
    //     icon: (
    //       <span className="remix-icon">
    //         <RiAlertLine />
    //       </span>
    //     ),
    //     title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
    //     content: (
    //       <div>
    //         <p className="hp-p1-body hp-text-color-black-80">
    //           Sorry! Unable to generate report. Please try again!
    //         </p>
    //       </div>
    //     ),
    //   });
    // }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Gettting from server side issue!
          </p>
        </div>
      ),
    });
    // yield put(setActionTimeout());
    yield put(apiError(error));
  }
}

const delay = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      // here is where I want to specify my timeout logic
      resolve();
    }, ms);
  });

function* ReportDelay({ payload }) {
  let timeout;
  timeout = yield delay(10000);
  yield put(reportStatus({ request_id: payload.request_id }));
}

function* MaterialItemsGet({ payload }) {
  var params = {};
  var data = payload.params;
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "type") {
        params.type = data.type.join(",");
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mrq/material/items/" + payload.URLData.warehouse_id,
      params
    );
    yield put(materialItemSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* GetScheduleReports({ payload }) {
  const params = payload.paramsData;
  console.log(params);
  try {
    const response = yield call(
      getParamsList,
      "/reports/scheduledemail/",
      params
    );
    if (response.results) {
      yield put(getScheduleReportsSuccess(response));
    } else {
      throw new Error("Server Error");
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error.message}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* DeleteScheduleReports({ payload }) {
  try {
    const response = yield call(
      postDelete,
      `/reports/ScheduledEmail/${payload}`
    );
    if (response.status > 200 && response.status < 300) {
      yield put(DeleteScheduleReportByIdSuccess(payload));
    } else {
      throw new Error("Server Error");
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error.message}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* PostScheduleReport({ payload }) {
  try {
    const params = payload.paramsData;
    const response = yield call(
      postAdd,
      "/reports/scheduledemail/",
      payload.obj,
      params
    );
    if (response.data) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Schedule Added Successfully
            </p>
          </div>
        ),
      });
      yield put(postScheduleReportSucess(response.data));
    } else {
      throw new Error("Server Response Error");
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error.message}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

export function* watchGetReportList() {
  yield takeEvery("reports/getReportList", ReportList);
}
export function* watchReportStatus() {
  yield takeEvery("reports/reportStatus", StatusReport);
}
export function* watchDelayReport() {
  yield takeEvery("reports/delayReport", ReportDelay);
}
export function* watchReportDownload() {
  yield takeEvery("reports/reportDownload", DownloadReport);
}
export function* watchGetMaterialItem() {
  yield takeEvery("reports/getMaterialItem", MaterialItemsGet);
}
export function* watchGetScheduleReports() {
  yield takeEvery("reports/getScheduleReports", GetScheduleReports);
}
export function* watchDeleteScheduleReports() {
  yield takeEvery(
    "reports/startDeleteScheduleReportById",
    DeleteScheduleReports
  );
}
export function* watchPostScheduleReport() {
  yield takeEvery("reports/startPostScheduleReport", PostScheduleReport);
}

function* reportsSaga() {
  yield all([
    fork(watchGetReportList),
    fork(watchGetMaterialItem),
    fork(watchReportStatus),
    fork(watchReportDownload),
    fork(watchDelayReport),
    fork(watchGetScheduleReports),
    fork(watchDeleteScheduleReports),
    fork(watchPostScheduleReport),
  ]);
}

export default reportsSaga;
