import React, { useState, createElement, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

import { useSelector } from "react-redux";
import { connect } from "react-redux";

import {
  Layout,
  Button,
  Badge,
  Row,
  Col,
  Dropdown,
  Divider,
  Avatar,
} from "antd";
import { Document, TickSquare, CloseSquare, Danger } from "react-iconly";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";
// import MenuFooter from "./footer";
// import MenuItem from "./item";
// import MenuMobile from "../mobile";
import { sidebarEnable } from "../../../store/customise/customiseSlice";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

function HeaderSideMenu(props) {
  // Menu
  function toggle() {
    props.sidebarEnable(!props.sidebarMenuEnable);
  }

  const trigger = createElement(
    props.sidebarMenuEnable ? MenuFoldOutlined : MenuUnfoldOutlined,
    {
      className: "trigger hp-text-color-black-60 hp-text-color-white",

      style: { fontSize: "20px" },
      onClick: toggle,
    }
  );
  return (
    <Col className="hp-d-flex-center hp-mr-sm-12 hp-mr-16">
      {props.sidebarCollapseButton && (
        <Button
          icon={trigger}
          type="text"
          className="hp-float-right hp-text-color-black-60"
        ></Button>
      )}
    </Col>
  );
}

function mapStateToProps({ customise }) {
  return {
    sidebarMenuEnable: customise.sidebarMenuEnable,
    sidebarCollapseButton: customise.sidebarCollapseButton,
  };
}

export default connect(mapStateToProps, {
  sidebarEnable,
})(HeaderSideMenu);
