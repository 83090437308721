import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  vehicleListSuccessful,
  SaleDataQuotationByIdSuccessful,
  OVFSaleQuotationByIdSuccessful,
  salesCheckApproveSuccessful,
  isRejectModelVisible,
  getVehiclesList,
  addSaleImageUploadSuccessful,
  addFinanceSaleImageUploadSuccessful,
  FormulaExecuterSuccessful,
  purchaseHistoryListSuccessful,
  saleHistoryListSuccessful,
  getVehicleSaleDoc,
  vehicleSaleDocSuccessful,
  MCApplicableSuccessful,
  getSalesCheckApprove,
  fieldRequestVerificationSuccessful,
  isRequestModelVisible,
  getTotalReestimationAmountByPurchase,
  TotalReestimationAmountByPurchaseSuccessful,
  getTotalRepairAmountByPurchase,
  TotalRepairAmountByPurchaseSuccessful,
  OVFQuotationPendingListSuccessful,
  ovfSaleInputChangeValue,
  getFieldRequestVerification,
  getPurchaseById,
  PurchaseByIdSuccessful,
  FVRequestDataSuccessful,
  getFormulaExecuter,
  getMinSaleAmount,
  getEMIAmount,
  getMaxSaleAmount,
  getMaxSaleAmountInMinSale,
  getGSTAmount,
  getSaleProfit,
  getIncentiveAmount,
  getDocumentCharges,
  getSaleCustomerPayAmount,
  getMinDownPaymentAmount,
  getFinanceAmount,
  getCustomerCashAmount,
  getMaxFinanceAmount,
  tenuresDataSuccessful,
  miniEMIdatesSuccessful,
} from "./vehicleCounterSalesSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
import { select } from "redux-saga/effects";
import { evaluate, log } from "mathjs";

export const getSaleData = (state) => state.counterSales;

//If user is login then dispatch redux action's are directly from here.
function* VehicleList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        // params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/sale/AllList/", params);
    yield put(vehicleListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

//  Add Sale

function* SaleAdd({ payload }) {
  var data = {};
  for (const k in payload.addSaleObj) {
    if (Object.hasOwnProperty.call(payload.addSaleObj, k)) {
      if (payload.addSaleObj[k] != null && payload.addSaleObj[k] != "") {
        data[k] = payload.addSaleObj[k];
      }
    }
  }
  if (payload.addSaleObj.financesale !== null) {
    data.financesale = {};
    for (const k in payload.addSaleObj.financesale) {
      if (Object.hasOwnProperty.call(payload.addSaleObj.financesale, k)) {
        if (
          payload.addSaleObj.financesale[k] !== null &&
          payload.addSaleObj.financesale[k] !== ""
        ) {
          data.financesale[k] = payload.addSaleObj.financesale[k];
        }
      }
    }
  }
  if (payload.addSaleObj.ovfdetails !== null) {
    data.ovfdetails = {};
    for (const k in payload.addSaleObj.ovfdetails) {
      if (Object.hasOwnProperty.call(payload.addSaleObj.ovfdetails, k)) {
        if (
          payload.addSaleObj.ovfdetails[k] !== null &&
          payload.addSaleObj.ovfdetails[k] !== ""
        ) {
          console.log(payload.addSaleObj.ovfdetails[k], k);
          data.ovfdetails[k] = payload.addSaleObj.ovfdetails[k];
        }
      }
    }
  }
  try {
    if (data.id == 0) {
      const response = yield call(postAdd, "/sale/create/", data);
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Sale Added Successfully.!
              </p>
            </div>
          ),
        });

        payload.history.push("/pages/Vechicle-Sales");
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Sale. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response = yield call(postEdit, "/sale/" + data.id, data);
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Sale Edited Successfully.!
              </p>
            </div>
          ),
        });

        payload.history.push("/pages/Vechicle-Sales");
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Sale. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

// Purchase History List
function* PurchaseHistory({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/purchase/byvehicleid/" + payload.id,
      {}
    );
    yield put(purchaseHistoryListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

// Sale History List

function* SaleHistory({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/sale/byvehicleid/" + payload.id,
      {}
    );
    yield put(saleHistoryListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* saleDataQuotationById({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/sale/quotation/" + payload,
      {}
    );
    yield put(SaleDataQuotationByIdSuccessful(response));
    yield put(getTotalReestimationAmountByPurchase(response.purchase?.id));
    yield put(getTotalRepairAmountByPurchase(response.purchase?.id));
    yield put(getPurchaseById(response.purchase?.id));
    if (parseInt(response.purchase?.minimumsaleamount) == 0) {
      yield put(
        getMaxSaleAmount({
          key: "purchaserate",
          value: response.purchase?.purchaserate,
        })
      );
      yield put(
        getMinSaleAmount({
          key: "purchaserate",
          value: response.purchase?.purchaserate,
        })
      );
    } else {
      yield put(
        getMaxSaleAmountInMinSale({
          key: "minSaleAmount",
          value: response.purchase?.minimumsaleamount,
        })
      );
    }
  } catch (error) {
    yield put(apiError(error));
  }
}
function* OVFSaleQuotationById({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/sale/ovf/quotation/" + payload,
      {}
    );
    yield put(OVFSaleQuotationByIdSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* SalesCheckApprove({ payload }) {
  try {
    const response = yield call(postAdd, "/sale/checkapprove/", payload);
    if (response.status === 200) {
      yield put(salesCheckApproveSuccessful(true));
    }
  } catch (error) {
    yield put(salesCheckApproveSuccessful(false));
    yield put(apiError(error));
  }
}
function* SalesApproval({ payload }) {
  try {
    const response = yield call(postAdd, "/sale/approve/create/", payload);
    if (response.status === 200) {
      if (payload.approvalstatus == 2) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Sale has been approved successfully.
              </p>
            </div>
          ),
        });
      } else if (payload.approvalstatus == 3) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Sale has been Rejected successfully.
              </p>
            </div>
          ),
        });
      }

      yield put(getSalesCheckApprove({ sale_id: payload.sale_id }));
      // yield put(getSalesRequestVerification({ sale_id: payload.sale_id }));
      yield put(getVehicleSaleDoc({ id: payload.sale_id }));
      yield put(isRejectModelVisible(false));
      // yield put(isRequestModelVisible(false));
    } else {
      if (payload.approvalstatus == 2) {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to Approve Sales. Please try again!
              </p>
            </div>
          ),
        });
      } else if (payload.approvalstatus == 3) {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to Reject Sales. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* VehicleSaleDoc({ payload }) {
  try {
    const response = yield call(getList, "/sale/" + payload.id, {});
    yield put(vehicleSaleDocSuccessful(response));

    if (payload.type == "sale_edit") {
      if (response.saletype == 1 || response.saletype == 2) {
        yield put(getTotalReestimationAmountByPurchase(response.purchase?.id));
        yield put(getTotalRepairAmountByPurchase(response.purchase?.id));
        console.log(
          "parseInt",
          parseFloat(response.purchase?.minimumsaleamount)
        );
        if (parseInt(response.purchase?.minimumsaleamount) == 0) {
          yield put(
            getMinSaleAmount({
              key: "purchaserate",
              value: response.purchase?.purchaserate,
            })
          );
          yield put(
            getMaxSaleAmount({
              key: "purchaserate",
              value: response.purchase?.purchaserate,
            })
          );
        } else {
          yield put(
            getMaxSaleAmountInMinSale({
              key: "minSaleAmount",
              value: response.purchase?.minimumsaleamount,
            })
          );
        }
      }
      yield put(
        getEMIAmount({
          key: "interestpercentage",
          value: response.interestpercentage?.value,
        })
      );
    }

    // yield put(getSaleProfit({key:"saleamount",value:response.saleamount}));
    // yield put(getIncentiveAmount({key:"saleamount",value:response.saleamount}));
    // yield put(getMinDownPaymentAmount({key:"saleamount",value:response.saleamount}));
    // yield put(getSaleCustomerPayAmount({key:"saleamount",value:response.saleamount}));
    // yield put(getFinanceAmount({key:"saleamount",value:response.saleamount}));
    // yield put(getCustomerCashAmount({key:"total",value:response.total}));
    // yield put(getMaxFinanceAmount({key:"estimationamount",value:response.estimationamount}));
    // yield put(getGSTAmount({key:"saleamount",value:response.saleamount}));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* VehicleSaleApproval({ payload }) {
  try {
    const response = yield call(postAdd, "/purchase/approve/", payload);
    if (response.status === 201) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Vehicle Sale Approve Successfully.!
            </p>
          </div>
        ),
      });

      yield put(getVehicleSaleDoc(payload.purchase_id));
      yield put(isRejectModelVisible(false));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),

        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to Approve Vehicle Sale. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* FormulaExecuter({ payload }) {
  let systemFormulasList = yield select(
    (state) => state.system.systemFormulasList
  );

  try {
    var formulaObj = systemFormulasList?.find((formula) => {
      return formula?.code === payload.code;
    });

    if (formulaObj) {
      var result = evaluate(formulaObj.formula, payload.variables);

      if (result !== "NaN") {
        yield put(
          FormulaExecuterSuccessful({
            response: result,
            formulaCode: payload.code,
          })
        );
        if (payload.code == "FINANCEAMOUNT") {
          yield put(
            getSaleCustomerPayAmount({
              key: "financeamount",
              value: result,
            })
          );
          yield put(
            getDocumentCharges({
              key: "financeamount",
              value: result,
            })
          );
        } else if (payload.code == "CUSTOMERPAYAMOUNT") {
          yield put(
            getMinDownPaymentAmount({
              key: "financeamount",
              value: result,
            })
          );
          yield put(getEMIAmount({ key: "financeamount", value: result }));
        } else if (payload.code == "OVFFINANCEAMOUNT") {
          yield put(
            getDocumentCharges({ key: "financeamount", value: result })
          );
          yield put(
            getSaleCustomerPayAmount({ key: "financeamount", value: result })
          );
        }
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to calculator. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
    // const response = yield call(postAdd, "/system/formulaexecuter/", payload);
    // if (response.status === 200) {
    //   yield put(
    //     FormulaExecuterSuccessful({
    //       response: response.data,
    //       formulaCode: payload.code,
    //     })
    //   );
    //   if(payload.code == "FINANCEAMOUNT"){
    //     yield put(getSaleCustomerPayAmount({key:"financeamount",value:response.data.result}));
    //     yield put(getDocumentCharges({key:"financeamount",value:response.data.result}));
    //   }else if(payload.code == "CUSTOMERPAYAMOUNT"){
    //     yield put(getMinDownPaymentAmount({key:"financeamount",value:response.data.result}));
    //     yield put(getEMIAmount({key:"financeamount",value:response.data.result}));
    //   }
    // } else {
    //   Modal.error({
    //     icon: (
    //       <span className="remix-icon">
    //         <RiAlertLine />
    //       </span>
    //     ),

    //     title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
    //     content: (
    //       <div>
    //         <p className="hp-p1-body hp-text-color-black-80">
    //           Sorry! Unable to calculator. Please try again!
    //         </p>
    //       </div>
    //     ),
    //   });
    // }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* AddSaleImageUpload({ payload }) {
  try {
    const response = yield call(postFormData, "/system/attachment/", {
      file: payload.file,
    });

    yield put(
      addSaleImageUploadSuccessful({
        response,
        ImageType: payload.imageType,
        saletype: payload.saletype,
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}
function* AddFinanceSaleImageUpload({ payload }) {
  try {
    const response = yield call(postFormData, "/system/attachment/", {
      file: payload.file,
    });

    yield put(
      addFinanceSaleImageUploadSuccessful({
        response,
        ImageType: payload.imageType,
        saletype: payload.saletype,
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

function* VechicleSaleDelete({ payload }) {
  var saleId = payload;
  try {
    const response = yield call(postDelete, "/sale/" + saleId);

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Sale has been deleted!
            </p>
          </div>
        ),
      });
      yield put(
        getVehiclesList({
          page: 1,
          search: "",
          saletype: payload.saletype,
          page_size: 10,
        })
      );
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),

        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Sale has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* MCApplicable({ payload }) {
  var params = {};
  var data = payload;
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/employees/mcapplicable",
      params
    );
    yield put(MCApplicableSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

// Request Field Verification

function* RequestVerificationGet({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/sale/checkfieldverfication/",
      payload
    );
    if (response.status === 200) {
      yield put(fieldRequestVerificationSuccessful(true));
    }
  } catch (error) {
    yield put(fieldRequestVerificationSuccessful(false));
    yield put(apiError(error));
  }
}

function* RequestFieldVerification({ payload }) {
  try {
    const response = yield call(postAdd, "/sale/fieldverfication/", payload);
    if (response.status === 201) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Field Verification Request Successfully.!
            </p>
          </div>
        ),
      });

      yield put(getVehicleSaleDoc(payload.sale_id));
      yield put(getSalesCheckApprove({ sale_id: payload.sale_id }));
      yield put(getFieldRequestVerification({ sale_id: payload.sale_id }));
      yield put(isRequestModelVisible(false));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),

        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to Request Field Verification . Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* TotalReestimationAmountByPurchase({ payload }) {
  try {
    const response = yield call(
      getList,
      "/stocktransfer/reestimationamount/total/bypurchase/" + payload,
      {}
    );
    yield put(TotalReestimationAmountByPurchaseSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* TotalRepairAmountByPurchase({ payload }) {
  try {
    const response = yield call(
      getList,
      "/stocktransfer/repairamount/total/bypurchase/" + payload,
      {}
    );
    if (response.pendingcount !== 0) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),

        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Some Repair amounts are in still Pending
            </p>
          </div>
        ),
      });
      yield put(TotalRepairAmountByPurchaseSuccessful(response));
    } else {
      yield put(TotalRepairAmountByPurchaseSuccessful(response));
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* OVFQuotationPendingList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/sale/ovf/quotation/pendinglist/",
      params
    );
    yield put(OVFQuotationPendingListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* InsuranceValidate({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/purchase/insurancenumber/validation/",
      payload
    );
  } catch (error) {
    if (error.response.status == 400) {
      yield put(
        ovfSaleInputChangeValue({
          key: "insurancenumber",
          value: "",
        })
      );
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response?.data?.error[0]}
            </p>
          </div>
        ),
      });
    } else if (error.response.status == 500) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}

function* PurchaseById({ payload }) {
  try {
    const response = yield call(getList, "/purchase/" + payload, {});
    yield put(PurchaseByIdSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* FVRequestData({ payload }) {
  try {
    const response = yield call(
      getList,
      "/sale/fieldverfication/sale/" + payload,
      {}
    );
    yield put(FVRequestDataSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MinSaleAmount({ payload }) {
  // let saleData = yield select(getSaleData.addSaleData);
  let saleData = yield select((state) => state.counterSales.addSaleData);
  try {
    var variablesData = {
      PURCHASEAMOUNT:
        saleData.purchaserate == null ? 0 : parseInt(saleData.purchaserate),
    };
    if (payload.key == "purchaserate") {
      variablesData.PURCHASEAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    }
    var minSaleAmountObj = {
      code: "MINSALEAMOUNT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(minSaleAmountObj));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* EMIAmount({ payload }) {
  let saleData = yield select((state) => state.counterSales.addSaleData);
  let counterSales = yield select((state) => state.counterSales);
  try {
    var variablesData = {
      CUSTOMERPAYAMOUNT:
        saleData.total == null || saleData.total == ""
          ? 0
          : parseInt(saleData.total),
      INTERESTRATE:
        saleData?.financesale?.interestpercentage == null ||
        saleData?.financesale?.interestpercentage == ""
          ? 0
          : parseInt(saleData?.financesale?.interestpercentage?.value),
      TENURES:
        saleData?.financesale?.tenures == null ||
        saleData?.financesale?.tenures == ""
          ? 0
          : parseInt(saleData?.financesale?.tenures),

      // EMIDIFFDAYS: counterSales.emiDifferenceDays,
    };
    if (payload.key == "interestpercentage") {
      variablesData.INTERESTRATE = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "tenures") {
      variablesData.TENURES = parseInt(payload.value == "" ? 0 : payload.value);
    }
    // else if (payload.key == "emiDifferenceDays") {
    //   variablesData.EMIDIFFDAYS = parseInt(payload.value == "" ? 0 : payload.value);
    // }
    var emiAmountObj = {
      code: "EMIAMOUNT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(emiAmountObj));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MaxSaleAmount({ payload }) {
  // let saleData = yield select(getSaleData.addSaleData);
  let saleData = yield select((state) => state.counterSales.addSaleData);
  try {
    var variablesData = {
      PURCHASEAMOUNT:
        saleData.purchaserate == null ? 0 : parseInt(saleData.purchaserate),
    };
    if (payload.key == "purchaserate") {
      variablesData.PURCHASEAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    }
    var maxSaleAmountObj = {
      code: "MAXSALEAMOUNT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(maxSaleAmountObj));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MaxSaleAmountInMinSale({ payload }) {
  // let saleData = yield select((state) => state.counterSales.addSaleData);
  let minSaleAmount = yield select((state) => state.counterSales.minSaleAmount);

  // let minSaleAmount = yield select(getSaleData);
  try {
    var variablesData = {
      MINSALEAMOUNT: minSaleAmount == 0 ? 0 : parseInt(minSaleAmount),
    };
    if (payload.key == "minSaleAmount") {
      variablesData.MINSALEAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    }
    var minSaleAmountInMaxSaleObj = {
      code: "MAXSALEAMOUNT_MINISALEAMOUNT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(minSaleAmountInMaxSaleObj));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* GSTAmount({ payload }) {
  let saleData = yield select((state) => state.counterSales.addSaleData);
  try {
    var variablesData = {
      SALEAMOUNT:
        saleData.saleamount == null ? 0 : parseInt(saleData.saleamount),
      PURCHASEAMOUNT:
        saleData.purchaserate == null ? 0 : parseInt(saleData.purchaserate),
      REPAIRAMOUNT:
        saleData.repair_amount == null || saleData.repair_amount == ""
          ? 0
          : parseInt(saleData.repair_amount),
      REESTIMATIONAMOUNT:
        saleData.re_estimation_amount == null
          ? 0
          : parseInt(saleData.re_estimation_amount),
      DOCUMENTCHARGES:
        saleData.saletype == 1
          ? 0
          : saleData?.financesale?.customerdocumentationcharges == null
          ? 0
          : parseInt(saleData?.financesale?.customerdocumentationcharges),
      CUSTOMERGSTAMOUNT:
        saleData?.gst_customer_amount == null
          ? 0
          : parseInt(saleData?.gst_customer_amount),
    };

    if (payload.key == "saleamount") {
      variablesData.SALEAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "purchaserate") {
      variablesData.PURCHASEAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "repair_amount") {
      variablesData.REPAIRAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "re_estimation_amount") {
      variablesData.REESTIMATIONAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "gst_customer_amount") {
      variablesData.CUSTOMERGSTAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "customerdocumentationcharges") {
      variablesData.DOCUMENTCHARGES = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    }
    var gstAmountObj = {
      code: "GSTAMOUNT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(gstAmountObj));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* SaleProfit({ payload }) {
  let saleData = yield select((state) => state.counterSales.addSaleData);
  try {
    var variablesData = {
      SALEAMOUNT:
        saleData.saleamount == null ? 0 : parseInt(saleData.saleamount),
      PURCHASEAMOUNT:
        saleData.purchaserate == null ? 0 : parseInt(saleData.purchaserate),
      REPAIRAMOUNT:
        saleData.repair_amount == null || saleData.repair_amount == ""
          ? 0
          : parseInt(saleData.repair_amount),
      REESTIMATIONAMOUNT:
        saleData.re_estimation_amount == null
          ? 0
          : parseInt(saleData.re_estimation_amount),
    };

    if (payload.key == "saleamount") {
      variablesData.SALEAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "purchaserate") {
      variablesData.PURCHASEAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "repair_amount") {
      variablesData.REPAIRAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "re_estimation_amount") {
      variablesData.REESTIMATIONAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    }
    var saleProfitObj = {
      code: "SALEPROFIT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(saleProfitObj));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* IncentiveAmount({ payload }) {
  let saleData = yield select((state) => state.counterSales.addSaleData);
  try {
    var variablesData = {
      SALEAMOUNT:
        saleData.saleamount == null ? 0 : parseInt(saleData.saleamount),
      PURCHASEAMOUNT:
        saleData.purchaserate == null ? 0 : parseInt(saleData.purchaserate),
      REPAIRAMOUNT:
        saleData.repair_amount == null || saleData.repair_amount == ""
          ? 0
          : parseInt(saleData.repair_amount),
      REESTIMATIONAMOUNT:
        saleData.re_estimation_amount == null
          ? 0
          : parseInt(saleData.re_estimation_amount),
      DOCUMENTCHARGES:
        saleData?.financesale?.customerdocumentationcharges == null
          ? 0
          : parseInt(saleData?.financesale?.customerdocumentationcharges),
    };
    if (payload.key == "saleamount") {
      variablesData.SALEAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "purchaserate") {
      variablesData.PURCHASEAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "repair_amount") {
      variablesData.REPAIRAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "re_estimation_amount") {
      variablesData.REESTIMATIONAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    }
    var incentiveChargesObj = {
      code: "INCENTIVECHARGES",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(incentiveChargesObj));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* DocumentCharges({ payload }) {
  let saleData = yield select((state) => state.counterSales.addSaleData);
  try {
    var variablesData = {
      FINANCEAMOUNT:
        saleData.financesale?.financeamount == null
          ? 0
          : parseInt(saleData.financesale?.financeamount),
    };
    if (payload.key == "financeamount") {
      variablesData.FINANCEAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    }
    var documentChargesObj = {
      code: "DOCUMENTCHARGES",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(documentChargesObj));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* SaleCustomerPayAmount({ payload }) {
  let saleData = yield select((state) => state.counterSales.addSaleData);
  try {
    var variablesData = {
      SALEAMOUNT:
        saleData.saletype == 1
          ? saleData.saleamount == null
            ? 0
            : parseFloat(saleData.saleamount)
          : saleData?.financesale?.financeamount == null
          ? 0
          : parseInt(saleData?.financesale?.financeamount),
      RTOAMOUNT: saleData.rtaamount == null ? 0 : parseInt(saleData.rtaamount),
      INSURANCECHARGES:
        saleData.insuranceamount == null
          ? 0
          : parseInt(saleData.insuranceamount),
      CONSULTANCYCHARGES:
        saleData.consultancycharges == null
          ? 0
          : parseInt(saleData.consultancycharges),
      CUSTOMERGSTAMOUNT:
        saleData.gst_customer_amount == null
          ? 0
          : parseInt(saleData.gst_customer_amount),
      CUSTOMERDOCUMENTAMOUNT:
        saleData.financesale?.customerdocumentationcharges == null
          ? 0
          : parseInt(saleData.financesale?.customerdocumentationcharges),
      ECHALLANAMOUNT:
        saleData.saletype == 3
          ? 0
          : saleData.ovfdetails?.echallan == null
          ? 0
          : parseInt(saleData.addSaleData?.ovfdetails?.echallan),
      DISCOUNTAMOUNT:
        saleData.discountamount == null ? 0 : parseInt(saleData.discountamount),
    };

    switch (payload.key) {
      case "saleamount":
        variablesData.SALEAMOUNT = parseInt(
          payload.value == "" ? 0 : payload.value
        );
        break;
      case "financeamount":
        variablesData.SALEAMOUNT = parseInt(
          payload.value == "" ? 0 : payload.value
        );
        break;
      case "rtaamount":
        variablesData.RTOAMOUNT = parseInt(
          payload.value == "" ? 0 : payload.value
        );
        break;
      case "insuranceamount":
        variablesData.INSURANCECHARGES = parseInt(
          payload.value == "" ? 0 : payload.value
        );
        break;
      case "consultancycharges":
        variablesData.CONSULTANCYCHARGES = parseInt(
          payload.value == "" ? 0 : payload.value
        );

        break;
      case "gst_customer_amount":
        variablesData.CUSTOMERGSTAMOUNT = parseInt(
          payload.value == "" ? 0 : payload.value
        );
        break;
      case "customerdocumentationcharges":
        variablesData.CUSTOMERDOCUMENTAMOUNT = parseInt(
          payload.value == "" ? 0 : payload.value
        );
        break;
      case "echallan":
        variablesData.ECHALLANAMOUNT = parseInt(
          payload.value == "" ? 0 : payload.value
        );
        break;
      case "discountamount":
        variablesData.DISCOUNTAMOUNT = parseInt(
          payload.value == "" ? 0 : payload.value
        );
        break;
      default:
        break;
    }

    var directSaleCustomerPayObj = {
      code: "CUSTOMERPAYAMOUNT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(directSaleCustomerPayObj));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MinDownPaymentAmount({ payload }) {
  let saleData = yield select((state) => state.counterSales.addSaleData);
  try {
    var variablesData = {
      SALEAMOUNT:
        saleData.saleamount == null ? 0 : parseInt(saleData.saleamount),
    };

    var minSaleAmountObj = {
      code: "MINDOWNPAYMENTAMOUNT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(minSaleAmountObj));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* FinanceAmount({ payload }) {
  let saleData = yield select((state) => state.counterSales.addSaleData);
  try {
    var variablesData = {
      SALEAMOUNT:
        saleData.saleamount == null ? 0 : parseInt(saleData.saleamount),
      DOWNPAYMENTAMOUNT:
        saleData.downpaymentamount == null
          ? 0
          : parseInt(saleData.downpaymentamount),
    };
    if (payload.key == "saleamount") {
      variablesData.SALEAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "downpaymentamount") {
      variablesData.DOWNPAYMENTAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    }
    var financeAmountObj = {
      code: "FINANCEAMOUNT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(financeAmountObj));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* CustomerCashAmount({ payload }) {
  let saleData = yield select((state) => state.counterSales.addSaleData);
  try {
    var variablesData = {
      TOTALAMOUNT:
        saleData.saletype == 1
          ? saleData.total == null || saleData.total == ""
            ? 0
            : parseInt(saleData?.total)
          : saleData.saletype == 2
          ? saleData.financesale?.downpaymentamount == null ||
            saleData.financesale?.downpaymentamount == ""
            ? 0
            : parseInt(saleData.financesale?.downpaymentamount)
          : saleData?.ovfdetails?.ovfCustomerPayAmount == null ||
            saleData?.ovfdetails?.ovfCustomerPayAmount == ""
          ? 0
          : parseInt(saleData?.ovfdetails?.ovfCustomerPayAmount),
      CREDITAMOUNT:
        saleData.ovfdetails?.customercreditamount == null ||
        saleData.ovfdetails?.customercreditamount == ""
          ? 0
          : parseInt(saleData?.ovfdetails?.customercreditamount),
      BANKAMOUNT:
        saleData.customerbankamount == null || saleData.customerbankamount == ""
          ? 0
          : parseInt(saleData?.customerbankamount),
      DPDUEAMOUNT:
        saleData.saletype == 2
          ? saleData?.financesale == null
            ? 0
            : saleData?.financesale?.downpayment_due_amount == null ||
              saleData?.financesale?.downpayment_due_amount == ""
            ? 0
            : parseInt(saleData?.financesale?.downpayment_due_amount)
          : 0,
    };
    if (payload.key == "customerbankamount") {
      variablesData.BANKAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "customercreditamount") {
      variablesData.CREDITAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "downpaymentamount") {
      variablesData.TOTALAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "downpayment_due_amount") {
      variablesData.DPDUEAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    }
    var cashAmountObj = {
      code: "CASHAMOUNT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(cashAmountObj));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MaxFinanceAmount({ payload }) {
  let saleData = yield select((state) => state.counterSales.addSaleData);
  try {
    var variablesData = {
      ESTIMATIONAMOUNT:
        saleData?.ovfdetails?.estimationamount == null
          ? 0
          : parseInt(saleData?.ovfdetails?.estimationamount),
    };
    if (payload.key == "estimationamount") {
      variablesData.ESTIMATIONAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    }
    var maxFinanceAmountObj = {
      code: "MAXFINANCEAMOUNT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(maxFinanceAmountObj));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* TenuresData() {
  try {
    const response = yield call(getParamsList, "/masters/mini/tenures/", {});
    yield put(tenuresDataSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getEMIdates() {
  let addSaleData = yield select((state) => state.counterSales.addSaleData);
  console.log("getEMIdates", addSaleData?.date);
  try {
    const response = yield call(getParamsList, "/masters/mini/emidates/", {});
    yield put(miniEMIdatesSuccessful(response));
    var emiDates = response.map((data) => data.name);
    // var currentDate = moment();
    var next25Day = moment(addSaleData?.date?.format("YYYY-MM-DD")).add(
      19,
      "days"
    );
    var next40Day = moment(addSaleData?.date?.format("YYYY-MM-DD")).add(
      39,
      "days"
    );
    var next40Month = next40Day.format("MM");
    var next25Month = next25Day.format("MM");
    // var datesList = []
    if (parseInt(next25Month) == parseInt(next40Month)) {
      var datesList = emiDates.map((date, i) => {
        var d = moment(date + "-" + next25Day.format("MM-YYYY"), "DD-MM-YYYY");
        return d;
      });
      datesList = datesList.filter((d) => {
        return d >= next25Day && d <= next40Day;
      });
      datesList = datesList.map((d) => d.format("DD-MM-YYYY"));
      yield put(miniEMIdatesSuccessful(datesList));
    } else {
      var datesList = emiDates.map((date, i) => {
        var d = moment(date + "-" + next25Day.format("MM-YYYY"), "DD-MM-YYYY");
        return d;
      });

      var datesList2 = emiDates.map((date, i) => {
        var d = moment(date + "-" + next40Day.format("MM-YYYY"), "DD-MM-YYYY");
        return d;
      });

      datesList = [...datesList, ...datesList2];

      datesList = datesList.filter((d) => {
        return d >= next25Day && d <= next40Day;
      });
      datesList = datesList.map((d) => d.format("DD-MM-YYYY"));
      yield put(miniEMIdatesSuccessful(datesList));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* OVFCustomerPayAmount({ payload }) {
  let saleData = yield select((state) => state.counterSales.addSaleData);
  try {
    var variablesData = {
      REQUIREDCUSTOMERAMOUNT:
        saleData?.ovfdetails?.customeramount == null ||
        saleData?.ovfdetails?.customeramount == ""
          ? 0
          : parseInt(saleData?.ovfdetails?.customeramount),
      LOANAMOUNT:
        saleData?.ovfdetails?.loanamount == null ||
        saleData?.ovfdetails?.loanamount == ""
          ? 0
          : parseInt(saleData?.ovfdetails?.loanamount),
      ECHALLANAMOUNT:
        saleData?.ovfdetails?.echallan == null ||
        saleData?.ovfdetails?.echallan == ""
          ? 0
          : parseInt(saleData?.ovfdetails?.echallan),
    };
    if (payload.key == "customeramount") {
      variablesData.REQUIREDCUSTOMERAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "loanamount") {
      variablesData.LOANAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "echallan") {
      variablesData.ECHALLANAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    }
    var ovfCustomerPayAmountObj = {
      code: "OVFCUSTOMERPAYAMOUNT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(ovfCustomerPayAmountObj));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* MaxOVFDPDueAMount({ payload }) {
  let saleData = yield select((state) => state.counterSales.addSaleData);
  try {
    var variablesData = {
      CUSTOMERAMOUNT:
        saleData.ovfdetails?.customeramount == null
          ? 0
          : parseInt(saleData.ovfdetails?.customeramount),
    };
    if (payload.key == "customeramount") {
      variablesData.CUSTOMERAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    }
    var maxOVFDPDueAmountObj = {
      code: "MAXOVFDPDUEAMOUNT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(maxOVFDPDueAmountObj));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* OVFFinanceAmount({ payload }) {
  let saleData = yield select((state) => state.counterSales.addSaleData);
  try {
    var variablesData = {
      CUSTOMERAMOUNT:
        saleData.ovfdetails.customeramount == null
          ? 0
          : parseInt(saleData.ovfdetails?.customeramount),
      OVFDPDUEAMOUNT:
        saleData.financesale.downpayment_due_amount == null
          ? 0
          : parseInt(saleData.financesale.downpayment_due_amount),
    };
    if (payload.key == "customeramount") {
      variablesData.CUSTOMERAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "downpayment_due_amount") {
      variablesData.OVFDPDUEAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    }
    var OVFFinanceAmountObj = {
      code: "OVFFINANCEAMOUNT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(OVFFinanceAmountObj));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* UpdateFsyncStatus({ payload }) {
  let saleParamsData = yield select(
    (state) => state.counterSales.vehicleSaleParams
  );
  try {
    const response = yield call(postEdit, "/sale/reapprove/" + payload.id, {
      approvalstatus: payload.approvalstatus,
    });
    if (response.status === 200) {
      if (response.data.post_status == true) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Selected Sale is successfully synchronised into focus
              </p>
            </div>
          ),
        });
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! selected Sale is not posted on focus. please try again!
              </p>
            </div>
          ),
        });
      }
      yield put(getVehiclesList(saleParamsData));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! selected Sale is not posted on focus. please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* CloudSubmit({ payload }) {
  let saleParamsData = yield select(
    (state) => state.counterSales.vehicleSaleParams
  );
  try {
    const response = yield call(
      postEdit,
      "/sale/loancreate/" + payload.id + "/",
      {
        approvalstatus: payload.approvalstatus,
        saletype: payload.saletype,
      }
    );
    if (response.status === 200) {
      console.log("responseresponseresponse", response);
      if(response.data.post_status == true){
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Loan account created successfully
              </p>
            </div>
          ),
        });
      }else if(response.data.post_status == false || response.data.post_status == null){
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! selected Loan loan account is not created. please try again!
              </p>
            </div>
          ),
        });
      }
      // yield put(getVehiclesList(saleParamsData));
      yield put(getVehicleSaleDoc({ id: payload.id, type: "sale_view" }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
                Sorry! selected Loan loan account is not created. please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

export function* watchGetVehicleList() {
  yield takeLatest("counterSales/getVehiclesList", VehicleList);
}

export function* watchAddSale() {
  yield takeEvery("counterSales/addSale", SaleAdd);
}
export function* watchVechicleSaleDelete() {
  yield takeEvery("counterSales/vechicleSaleDelete", VechicleSaleDelete);
}
export function* watchGetSaleQuotationById() {
  yield takeEvery(
    "counterSales/getSaleDataQuotationById",
    saleDataQuotationById
  );
}
export function* watchGetOVFSaleQuotationById() {
  yield takeEvery("counterSales/getOVFSaleQuotationById", OVFSaleQuotationById);
}
export function* watchGetSalesCheckApprove() {
  yield takeEvery("counterSales/getSalesCheckApprove", SalesCheckApprove);
}
export function* watchGetSalesApproval() {
  yield takeEvery("counterSales/salesApproval", SalesApproval);
}
// export function* watchGetVehicleData() {
//   yield takeEvery("purchase/getVehicleData", VehicleDataGet);
// }
export function* watchGetFormulaExecuter() {
  yield takeEvery("counterSales/getFormulaExecuter", FormulaExecuter);
}
export function* watchGetAddSaleImageUpload() {
  yield takeEvery("counterSales/getAddSaleImageUpload", AddSaleImageUpload);
}
export function* watchGetAddFinanceSaleImageUpload() {
  yield takeEvery(
    "counterSales/getAddFinanceSaleImageUpload",
    AddFinanceSaleImageUpload
  );
}

export function* watchGetPurchaseHistory() {
  yield takeEvery("counterSales/getPurchaseHistory", PurchaseHistory);
}

export function* watchGetSaleHistory() {
  yield takeEvery("counterSales/getSaleHistory", SaleHistory);
}

export function* watchGetVehicleSaleDoc() {
  yield takeEvery("counterSales/getVehicleSaleDoc", VehicleSaleDoc);
}
export function* watchVehicleSaleApproval() {
  yield takeEvery("counterSales/vehicleSaleApproval", VehicleSaleApproval);
}
export function* watchGetMCApplicableApproval() {
  yield takeEvery("counterSales/getMCApplicable", MCApplicable);
}
export function* watchGetFieldRequestVerification() {
  yield takeEvery(
    "counterSales/getFieldRequestVerification",
    RequestVerificationGet
  );
}

export function* watchGetFieldVerificationRequest() {
  yield takeEvery(
    "counterSales/FieldVerificationRequest",
    RequestFieldVerification
  );
}
export function* watchGetTotalReestimationAmountByPurchase() {
  yield takeEvery(
    "counterSales/getTotalReestimationAmountByPurchase",
    TotalReestimationAmountByPurchase
  );
}
export function* watchGetTotalRepairAmountByPurchase() {
  yield takeEvery(
    "counterSales/getTotalRepairAmountByPurchase",
    TotalRepairAmountByPurchase
  );
}
export function* watchGetOVFQuotationPendingList() {
  yield takeEvery(
    "counterSales/getOVFQuotationPendingList",
    OVFQuotationPendingList
  );
}
export function* watchGetPurchaseById() {
  yield takeEvery("counterSales/getPurchaseById", PurchaseById);
}
export function* watchInsuranceNumberValidate() {
  yield takeEvery("counterSales/insuranceNumberValidate", InsuranceValidate);
}
export function* watchGetFVRequestData() {
  yield takeEvery("counterSales/getFVRequestData", FVRequestData);
}
export function* watchGetMinSaleAmount() {
  yield takeEvery("counterSales/getMinSaleAmount", MinSaleAmount);
}
export function* watchGetEMIAmount() {
  yield takeEvery("counterSales/getEMIAmount", EMIAmount);
}
export function* watchGetMaxSaleAmount() {
  yield takeEvery("counterSales/getMaxSaleAmount", MaxSaleAmount);
}
export function* watchGetMaxSaleAmountInMinSale() {
  yield takeEvery(
    "counterSales/getMaxSaleAmountInMinSale",
    MaxSaleAmountInMinSale
  );
}
export function* watchGetGSTAmount() {
  yield takeEvery("counterSales/getGSTAmount", GSTAmount);
}
export function* watchGetSaleProfit() {
  yield takeEvery("counterSales/getSaleProfit", SaleProfit);
}
export function* watchGetIncentiveAmount() {
  yield takeEvery("counterSales/getIncentiveAmount", IncentiveAmount);
}
export function* watchGetDocumentCharges() {
  yield takeEvery("counterSales/getDocumentCharges", DocumentCharges);
}
export function* watchGetSaleCustomerPayAmount() {
  yield takeEvery(
    "counterSales/getSaleCustomerPayAmount",
    SaleCustomerPayAmount
  );
}
export function* watchGetMinDownPaymentAmount() {
  yield takeEvery("counterSales/getMinDownPaymentAmount", MinDownPaymentAmount);
}
export function* watchGetFinanceAmount() {
  yield takeEvery("counterSales/getFinanceAmount", FinanceAmount);
}
export function* watchGetCustomerCashAmount() {
  yield takeEvery("counterSales/getCustomerCashAmount", CustomerCashAmount);
}
export function* watchGetMaxFinanceAmount() {
  yield takeEvery("counterSales/getMaxFinanceAmount", MaxFinanceAmount);
}
export function* watchGetTenuresData() {
  yield takeEvery("counterSales/getTenuresData", TenuresData);
}
export function* watchGetMiniEMIdates() {
  yield takeEvery("counterSales/getMiniEMIdates", getEMIdates);
}
export function* watchGetOVFCustomerPayAmount() {
  yield takeEvery("counterSales/getOVFCustomerPayAmount", OVFCustomerPayAmount);
}
export function* watchGetMaxOVFDPDueAMount() {
  yield takeEvery("counterSales/getMaxOVFDPDueAMount", MaxOVFDPDueAMount);
}
export function* watchGetOVFFinanceAmount() {
  yield takeEvery("counterSales/getOVFFinanceAmount", OVFFinanceAmount);
}
export function* watchFsyncStatusUpdate() {
  yield takeEvery("counterSales/fsyncStatusUpdate", UpdateFsyncStatus);
}
export function* watchSubmitCould() {
  yield takeEvery("counterSales/submitCould", CloudSubmit);
}
function* vehicleCounterSalesSaga() {
  yield all([
    fork(watchGetVehicleList),
    fork(watchVechicleSaleDelete),
    fork(watchAddSale),
    fork(watchGetSaleQuotationById),
    fork(watchGetOVFSaleQuotationById),
    fork(watchGetSalesApproval),
    fork(watchGetSalesCheckApprove),
    fork(watchGetAddSaleImageUpload),
    fork(watchGetAddFinanceSaleImageUpload),
    fork(watchGetFormulaExecuter),
    fork(watchGetPurchaseHistory),
    fork(watchGetSaleHistory),
    fork(watchGetVehicleSaleDoc),
    fork(watchVehicleSaleApproval),
    fork(watchGetMCApplicableApproval),
    fork(watchGetFieldRequestVerification),
    fork(watchGetFieldVerificationRequest),
    fork(watchGetTotalReestimationAmountByPurchase),
    fork(watchGetTotalRepairAmountByPurchase),
    fork(watchGetOVFQuotationPendingList),
    fork(watchInsuranceNumberValidate),
    fork(watchGetPurchaseById),
    fork(watchGetFVRequestData),
    fork(watchGetMinSaleAmount),
    fork(watchGetEMIAmount),
    fork(watchGetMaxSaleAmount),
    fork(watchGetMaxSaleAmountInMinSale),
    fork(watchGetGSTAmount),
    fork(watchGetIncentiveAmount),
    fork(watchGetDocumentCharges),
    fork(watchGetSaleCustomerPayAmount),
    fork(watchGetMinDownPaymentAmount),
    fork(watchGetCustomerCashAmount),
    fork(watchGetMaxFinanceAmount),
    fork(watchGetSaleProfit),
    fork(watchGetFinanceAmount),
    fork(watchGetTenuresData),
    fork(watchGetMiniEMIdates),
    fork(watchGetOVFCustomerPayAmount),
    fork(watchGetMaxOVFDPDueAMount),
    fork(watchGetOVFFinanceAmount),
    fork(watchFsyncStatusUpdate),
    fork(watchSubmitCould),
  ]);
}

export default vehicleCounterSalesSaga;
