import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { Layout, Row, Col } from "antd";
import { connect } from "react-redux";

import Sidebar from "./components/menu/Sidebar";
import MenuHeader from "./components/header";
import MenuFooter from "./components/footer";
import ScrollTop from "./components/scroll-to-top";
import { getUserPermissions } from "../store/system/systemSlice";

const { Content } = Layout;

function VerticalLayout(props) {
  const { children } = props;
  useEffect(() => {
    props.getUserPermissions();
  }, []);
  const [visible, setVisible] = useState(false);

  // Redux
  const customise = useSelector((state) => state.customise);

  return (
    <Layout className="hp-app-layout">
      <MenuHeader setVisible={setVisible} />
      <Layout className="hp-bg-color-dark-90">
        <Sidebar visible={visible} setVisible={setVisible} />
        <Content className="hp-content-main">
          <Row justify="center">
            {customise.contentWidth == "full" && (
              <Col span={24}>{children}</Col>
            )}

            {customise.contentWidth == "boxed" && (
              <Col xxl={20} xl={22} span={24}>
                {children}
              </Col>
            )}
          </Row>
        </Content>

        {/* <MenuFooter /> */}
      </Layout>

      <ScrollTop />
    </Layout>
  );
}
function mapAreaToProps({}) {
  return {};
}
export default connect(mapAreaToProps, { getUserPermissions })(VerticalLayout);
