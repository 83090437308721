import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  apiError,
  getUserSuccessful,
  changePasswordModalShow,
  getActivityLogSuccessful,
  getUserDeviceListSuccessful,
} from "./profileSlice";
import { getList, postEdit, getParamsList } from "../../helpers/Helper";
import Swal from "sweetalert2";
import { Modal } from "antd";
import {
  RiAlertLine,
} from "react-icons/ri";

//If user is login then dispatch redux action's are directly from here.
function* getUserlist() {
  try {
    const response = yield call(getList, "/users/iamuser/" + 0, {});
    yield put(getUserSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* UserChangePassword({ payload }) {
  try {
    const response = yield call(postEdit, "/users/changepassword/", payload);
    console.log(response);
    if (response.status === 200) {
      Swal.fire({
        title: "Your Password Change Successfully.!",
        icon: "success",
        timer: 1500,
      });
      yield put(changePasswordModalShow(false));
    } else {
      Swal.fire({
        title: "Sorry! Unable to Change Password. Please try again!",
        icon: "error",
      });
    }
  } catch (error) {
  console.log('eeeeeeeeeeeee', error)
    // if(error.response.status === 400){
    //   Modal.error({
    //     icon: (
    //       <span className="remix-icon">
    //         <RiAlertLine />
    //       </span>
    //     ),
    //     title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
    //     content: (
    //       <div>
    //         <p className="hp-p1-body hp-text-color-black-80">
    //           {error.response.data.password[0]}
    //           {/* {error.response.data.old_password.old_password} */}
    //         </p>
    //       </div>
    //     ),
    //   });
    // }else{
    //   Modal.error({
    //     icon: (
    //       <span className="remix-icon">
    //         <RiAlertLine />
    //       </span>
    //     ),
    //     title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
    //     content: (
    //       <div>
    //         <p className="hp-p1-body hp-text-color-black-80">
    //           Sorry! Getting from server side issue!
    //         </p>
    //       </div>
    //     ),
    //   });
    // }
    yield put(apiError(error));
  }
}

// function* addMenu({ payload: { history } }) {
//   try {
//     localStorage.removeItem("authUser");

//     history.push("/login");
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }

function* ActivityListGet({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/users/devicelog/me/", params);
    yield put(getActivityLogSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* UserDeviceListGet({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/users/userdevices/me", params);
    yield put(getUserDeviceListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserList() {
  yield takeEvery("userProfile/getUser", getUserlist);
}
export function* watchUserChangePassword() {
  yield takeEvery("userProfile/ChangePasswordSubmit", UserChangePassword);
}
export function* watchGetActivityList() {
  yield takeEvery("userProfile/getActivityLog", ActivityListGet);
}
export function* watchGetUserDeviceList() {
  yield takeEvery("userProfile/getUserDeviceList", UserDeviceListGet);
}

// export function* watchAddMenu() {
//   yield takeEvery(ADD_MENU, addMenu);
// }

function* userProfileSaga() {
  yield all([
    fork(watchUserList),
    fork(watchUserChangePassword),
    fork(watchGetActivityList),
    fork(watchGetUserDeviceList),
  ]);
}

export default userProfileSaga;
