import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const locationManagerSlice = createSlice({
  name: "locationManager",
  initialState: {
    listCount: 0,
    locationManagerlist: [],
    locationManagerData: {},
    model: false,
    locationManageParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getLocationManager: (state, action) => {
      if (state.locationManageParams.page == 1) {
        return {
          ...state,
          loading: true,
          locationManagerlist: [],
          // locationManageParams: {
          //   ...state.locationManageParams,
          //   ...action.payload 
          // },
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    locationManagerSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.count / state.locationManageParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.count,
        locationManagerlist: action.payload.results,
        loading: false,
        locationManageParams: {
          ...state.locationManageParams,
          no_of_pages: noofpages,
        },
      };
    },
    locationManagerAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    locationManagerbyId: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    locationManagerbyIdSuccessful: (state, action) => {
      return {
        ...state,
        locationManagerData: {
          ...action.payload,
          manager_id: action.payload.manager.id,
          user_id: action.payload.user.id,
        },
      };
    },

    InputChangeValue: (state, action) => {
      return {
        ...state,
        locationManagerData: {
          ...state.locationManagerData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    // SelecteChangeValue: (state, action) => {
    //   return {
    //     ...state,
    //     locationManagerData: {
    //       ...state.locationManagerData,
    //       [action.payload.key]: action.payload.label,
    //       [action.payload.key2]: action.payload.value,
    //     },
    //   };
    // },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          locationManagerData: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    setLocationManagerParams: (state, action) => {
      return {
        ...state,
        locationManageParams: action.payload,
      };
    },
    locationManagerDelete: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    //   getUsers: (state) => {
    //     return {
    //       ...state,
    //       loading: true,
    //     };
    //   },
    FilterInputChangeValue:(state, action) => {
      return {
        ...state,
        locationManageParams: {
          ...state.locationManageParams,
          [action.payload.key]: action.payload.value
        }
      }
    },
    isDrawerVisible: (state, action) => {
      if(!action.payload){
        return{
          ...state,
          drawer: action.payload,
        };
      }else{
        return {
          ...state,
          drawer: action.payload
        }
      }
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getLocationManager,
  locationManagerSuccessful,
  locationManagerAdd,
  locationManagerbyId,
  locationManagerbyIdSuccessful,
  InputChangeValue,
  // SelecteChangeValue,
  isModelVisible,
  setLocationManagerParams,
  locationManagerDelete,
  FilterInputChangeValue,
  isDrawerVisible,
  apiError,
} = locationManagerSlice.actions;

export default locationManagerSlice.reducer;
