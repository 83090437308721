import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
export const manualAttendanceSlice = createSlice({
  name: "manualAttendance",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    model: false,
    drawer: false,
    RejectMode: false,
    apiError: {},
    listCount: 0,
    manualAttendanceList: [],
    manualAttendanceParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    attendancedata: {},
    userAttendanceList: [],
    userAttendanceParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    // Attendance type user api
    clearData: (state, action) => {
      return {
        ...state,
        loading: false,
        attendancedata: {},
        userAttendanceList: [],
      };
    },
    getUserAttendanceList: (state) => {
      if (state.userAttendanceParams?.page == 1) {
        return {
          ...state,
          loading: true,
          userAttendanceList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    userAttendanceListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.userAttendanceParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.userAttendanceParams?.page_size
      );
      return {
        ...state,
        userAttendanceList: list,
        listCount: action.payload.count,
        loading: false,
        userAttendanceParams: {
          ...state.userAttendanceParams,
          no_of_pages: noofpages,
        },
      };
    },
    setUserAttendanceParams: (state, action) => {
      return {
        ...state,
        userAttendanceParams: action.payload,
      };
    },

    getManualAttendanceList: (state) => {
      if (state.manualAttendanceParams?.page == 1) {
        return {
          ...state,
          loading: true,
          manualAttendanceList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    manualAttendanceListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.manualAttendanceParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.manualAttendanceParams?.page_size
      );
      return {
        ...state,
        manualAttendanceList: list,
        listCount: action.payload.count,
        loading: false,
        manualAttendanceParams: {
          ...state.manualAttendanceParams,
          no_of_pages: noofpages,
        },
      };
    },
    setManualAttendanceParams: (state, action) => {
      return {
        ...state,
        manualAttendanceParams: action.payload,
      };
    },

    attendanceAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    InputChangeValue: (state, action) => {
      if (action.payload.key == "counter_id") {
        return {
          ...state,
          attendancedata: {
            ...state.attendancedata,
            [action.payload.key]: action.payload.value,
            user_id: "",
            in_time: "",
            out_time: ""
          },
        };
      } else {
        return {
          ...state,
          attendancedata: {
            ...state.attendancedata,
            [action.payload.key]: action.payload.value,
          },
        };
      }
    },
    getUserAttendanceById: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    userAttendanceByIdSuccessful: (state, action) => {
      var obj = action.payload;
      if (obj.results.length > 0) {
        var res = obj.results[0];
        return {
          ...state,
          loading: false,
          attendancedata: {
            ...state.attendancedata,
            id: res.id,
            in_time: moment(res.in_time, "DD-MM-YYYY, HH:mm: A"),
            out_time:
              res.out_time == null
                ? ""
                : moment(res.out_time, "DD-MM-YYYY, HH:mm: A"),
          },
        };
      } else {
        return {
          ...state,
          loading: false,
          attendancedata: {
            ...state.attendancedata,
            id: 0,
            in_time: "",
            out_time: "",
          },
        };
      }
    },

    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        manualAttendanceParams: {
          ...state.manualAttendanceParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getUserAttendanceList,
  userAttendanceListSuccessful,
  setUserAttendanceParams,
  attendanceAdd,
  InputChangeValue,
  getManualAttendanceList,
  manualAttendanceListSuccessful,
  setManualAttendanceParams,
  getUserAttendanceById,
  userAttendanceByIdSuccessful,
  clearData,
  isDrawerVisible,
  FilterInputChangeValue,
  apiError,
} = manualAttendanceSlice.actions;

export default manualAttendanceSlice.reducer;
