import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  apiError,
  getBannersAds,
  bannersAdsListSuccessful,
  // bannerImgUploadSuccessful,
  bannerAdsByIdSuccessful,
  isModelVisible,
} from "./bannersAdSlice";
import { serialize } from "object-to-formdata";
// Auth Related Methods

import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
  postFormData,
  getList,
} from "../../../helpers/Helper";

import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

function* BannersAdsList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/banner/list", params);
    yield put(bannersAdsListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

// function* ImageUpload({ payload }) {
//   try {
//     const response = yield call(postFormData, "/system/attachment/", {
//       file: payload.file,
//     });

//     yield put(
//       bannerImgUploadSuccessful({ response, ImageType: payload.imageType })
//     );
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }


function* BannerAdsById({ payload }) {
  try {
    const response = yield call(
      getList,
      "/masters/banner/" + payload.id, {});
    yield put(bannerAdsByIdSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}




function* AddBannerAds({ payload }) {
  // const formData = serialize(data);

  try {
    if (payload.id == 0) {
      const response = yield call(postFormData, "/masters/banner/add", payload);
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Banners/Ad Add Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getBannersAds({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Banners/Ads. Please try again!
              </p>
            </div>
          ),
        });
      }
      // yield put(versionsAddSuccessful(null));

    } else {
      const formData = serialize(payload);
      const response = yield call(
        postEdit,
        "/masters/banner/" + payload.id,
        formData
      );
      if (response.status === 200) {
        if (payload.is_active == true) {
          Modal.success({
            icon: (
              <span className="remix-icon">
                <RiCheckboxCircleLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  Your Banners/Ad activated Successfully.!
                </p>
              </div>
            ),
          });
        }else if(payload.is_active == false) {
          Modal.success({
            icon: (
              <span className="remix-icon">
                <RiCheckboxCircleLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  Your Banners/Ad deactivated Successfully.!
                </p>
              </div>
            ),
          });
        }
        yield put(getBannersAds({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to activate Banners/Ad. Please try again!
              </p>
            </div>
          ),
        });
      }
      // yield put(versionsAddSuccessful(null));
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}


function* DeleteBannerAds({ payload }) {
  var areaID = payload;
  try {
    const response = yield call(postDelete, "/masters/banner/" + areaID);

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Banners/Ads has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getBannersAds({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Banners/Ads has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

function* watchGetBannersAds() {
  yield takeEvery("bannersAds/getBannersAds", BannersAdsList);
}
// function* watchBannerImgUploads() {
//   yield takeEvery("bannersAds/bannerImgUpload", ImageUpload);
// }
function* watchGetBannerAdsById() {
  yield takeEvery("bannersAds/getBannerAdsById", BannerAdsById);
}
export function* watchBannerAdsAdd() {
  yield takeEvery("bannersAds/bannerAdsAdd", AddBannerAds);
}

export function* watchBannerAdsDelete() {
  yield takeEvery("bannersAds/bannerAdsDelete", DeleteBannerAds);
}

function* BannersAdSaga() {
  yield all([
    fork(watchGetBannersAds),
    // fork(watchBannerImgUploads),
    fork(watchGetBannerAdsById),
    fork(watchBannerAdsAdd),
    fork(watchBannerAdsDelete),
  ]);
}

export default BannersAdSaga;
