import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const appsettingsSlice = createSlice({
  name: "appSettings",
  initialState: {
    listCount: 0,
    appSettingsData: {},
    model: false,
  },
  reducers: {
    getAppSettings: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    appSettingsSuccessful: (state, action) => {
      return {
        ...state,
        appSettingsData: {
          SMS__MSG_VAR: action.payload.SMS__MSG_VAR,
          SMS__NUMBER_VAR: action.payload.SMS__NUMBER_VAR,
          SMS__URL: action.payload.SMS__URL,
          SMTP__BACKEND: action.payload.SMTP__BACKEND,
          SMTP__HOST: action.payload.SMTP__HOST,
          SMTP__PASSWORD: action.payload.SMTP__PASSWORD,
          SMTP__PORT: action.payload.SMTP__PORT,
          SMTP__USER: action.payload.SMTP__USER,
          SMTP__USE_TLS: action.payload.SMTP__USE_TLS,
          THIRDPARTY__URL: action.payload.THIRDPARTY__URL,
          THIRDPARTY__TOKEN: action.payload.THIRDPARTY__TOKEN,
        },
        loading: false,
      };
    },
    appSettingsAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    appSettingsAddSuccessful: (state, action) => {
      return {
        ...state,
        appSettingsData: {
          ...action.payload,
          SMS__MSG_VAR: action.payload.SMS__MSG_VAR,
          SMS__NUMBER_VAR: action.payload.SMS__NUMBER_VAR,
          SMS__URL: action.payload.SMS__URL,
          SMTP__BACKEND: action.payload.SMTP__BACKEND,
          SMTP__HOST: action.payload.SMTP__HOST,
          SMTP__PASSWORD: action.payload.SMTP__PASSWORD,
          SMTP__PORT: action.payload.SMTP__PORT,
          SMTP__USER: action.payload.SMTP__USER,
          SMTP__USE_TLS: action.payload.SMTP__USE_TLS,
          THIRDPARTY__URL: action.payload.THIRDPARTY__URL,
          THIRDPARTY__TOKEN: action.payload.THIRDPARTY__TOKEN,
        },
      };
    },
    
    
    InputChangeValue: (state, action) => {
      return {
        ...state,
        appSettingsData: {
          ...state.appSettingsData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  InputChangeValue,
  appSettingsAdd,
  appSettingsAddSuccessful,
  getAppSettings,
  appSettingsSuccessful,
  apiError,
} = appsettingsSlice.actions;

export default appsettingsSlice.reducer;
