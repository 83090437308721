import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
export const customerLogSlice = createSlice({
  name: "customerLog",
  initialState: {
    loginError: "aaa",
    drawer: false,
    loading: false,
    model: false,
    apiError: {},
    customerLogCount: 0,
    customerLogData: {},
    customerLogList: [],
    customerLogParams:{
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    }
  },
  reducers: {
    clearData: (state) => {
      return {
        ...state,
        loading: false,
        customerLogData: {},
        customerLogList: [],
        customerLogCount: 0,
      };
    },
    getCustomerLog: (state, action) => {
      if (state.customerLogParams?.page == 1) {
        return {
          ...state,
          loading: true,
          customerLogList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    customerLogSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.customerLogParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.customerLogParams?.page_size
      );
      return {
        ...state,
        customerLogList: list,
        customerLogCount: action.payload.count,
        loading: false,
        customerLogParams: {
          ...state.customerLogParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetCustomerLogParams: (state, action) => {
      return {
        ...state,
        customerLogParams: action.payload,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        customerLogData: {
          ...state.customerLogData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getCustomerLog,
  customerLogSuccessful,
  SetCustomerLogParams,
  InputChangeValue,
  clearData,
  apiError,
} = customerLogSlice.actions;

export default customerLogSlice.reducer;
