export const MENU_ITEMS = "Menu_Items";
export const INPUT_CHANGEVALUE = "Menu_Items_input_ChangeValue";
export const SELECT_CHANGEVALUE = "Menu_Items_Select_ChangeValue";

export const MENU_ITEMS_ADD = "Menu_Items_add";
export const MENU_ITEMS_ROW_DATA = "Menu_Items_Row_data";

export const MENU_ITEMS_DELETE = "Menu_Items_deleted";

export const MENU_ITEMS_SETROWDATA = "Menu_Items_set_rowdata";
export const MODEL_OPEN = "Menu_Items_model_open";
export const MODEL_CLOSE = "Menu_Items_model_close";

export const MENU_ITEMS_LIST_SUCCESSFUL = "Menu_Items_list_successfull";
export const MENU_ITEMS_ADD_SUCCESSFUL = "Menu_Items_add_successfull";
export const MENU_ITEMS_ROW_DATA_SUCCESSFUL =
  "Menu_Items_row_data_successfull";
export const SET_PARAMS = "MenuItem_set_params";


export const API_ERROR = "api_failed";
