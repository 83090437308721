import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const approvalSlice = createSlice({
  name: "approval",
  initialState: {
    listCount: 0,
    approvalList: [],
    approvalData: {finalapproval: false},
    model: false,
    approvalParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getApprovalList: (state) => {
      return {
        ...state,
        loading: true,
        approvalList: [],
      };
    },
    approvalListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.count / state.approvalParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.count,
        approvalList: action.payload.results,
        loading: false,
        approvalParams: {
          ...state.approvalParams,
          no_of_pages: noofpages,
        },
      };
    },
    approvalAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    approvalbyId: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    approvalbyIdSuccessful: (state, action) => {
      return {
        ...state,
        approvalData: {
          ...action.payload,
          manager_id: action.payload.manager?.id,
        },
      };
    },

    InputChangeValue: (state, action) => {
      return {
        ...state,
        approvalData: {
          ...state.approvalData,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          approvalData: {finalapproval: false},
          
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    setApprovalParams: (state, action) => {
      return {
        ...state,
        approvalParams: action.payload,
      };
    },
    approvalDelete: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    //   getUsers: (state) => {
    //     return {
    //       ...state,
    //       loading: true,
    //     };
    //   },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getApprovalList,
  approvalListSuccessful,
  approvalAdd,
  approvalbyId,
  approvalbyIdSuccessful,
  InputChangeValue,
  // SelecteChangeValue,
  isModelVisible,
  setApprovalParams,
  approvalDelete,
  apiError,
} = approvalSlice.actions;

export default approvalSlice.reducer;
