import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  getRtoList,
  rtoListSuccessful,
  isModelVisible,
  rtoByIdSuccessful,
} from "./rtoSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

//If user is login then dispatch redux action's are directly from here.
function* RtoListGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/insurancerto/rta/list/",
      params
    );
    yield put(rtoListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* rtoById({ payload }) {
  console.log("payload", payload);
  try {
    const response = yield call(
      getList,
      "/insurancerto/rtaupdate/" + payload,
      {}
    );
    yield put(rtoByIdSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddRto({ payload }) {
  try {
    const response = yield call(
      postEdit,
      "/insurancerto/rtaupdate/" + payload.id,
      payload.rtoObj
    );
    if (response.status === 200) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your RTO Update Successfully.!
            </p>
          </div>
        ),
      });
      yield put(getRtoList({ page: 1, search: "", page_size: 10 }));
      yield put(isModelVisible(false));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to RTO Update. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

export function* watchGetRtoList() {
  yield takeEvery("rto/getRtoList", RtoListGet);
}
export function* watchRtoAdd() {
  yield takeEvery("rto/rtoAdd", AddRto);
}

export function* watchGetRtoById() {
  yield takeEvery("rto/getRtoById", rtoById);
}

function* rtoSaga() {
  yield all([fork(watchGetRtoList), fork(watchRtoAdd), fork(watchGetRtoById)]);
}

export default rtoSaga;
