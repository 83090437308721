import { createSlice } from "@reduxjs/toolkit";

export const repairAmountSlice = createSlice({
  name: "repairAmount",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    repairAmountList: [],
    model: false,
    repairViewModel: false,
    drawer: false,
    error_msg: null,
    repairTypeImgLooading: false,
    rowdata: {
      repairamountitems: [
        {
          index: 0,
          repair: {},
          amount: "",
          description: "",
          dodelete: false,
        },
      ],
      majorOrMinorRepair: "is_major_repair",
      amount: "",
    },
    activeRepairAmountItemsCount: 1,
    listCount: 0,
    rowDataView: {},
    reapirAmountParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    // TotalRepirAmount: 0,
    repairTypeList: [],
    originalRepairTypeList: [],
    pendingRepairAmountList: [],
    pendingReapirAmountParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    pendingListCount: 0,
  },
  reducers: {
    clearReapirAmountList: (state) => {
      return {
        ...state,
        loading: true,
        repairAmountList: [],
      };
    },
    getRepairAmount: (state, action) => {
      if (state.reapirAmountParams.page == 1) {
        return {
          ...state,
          loading: true,
          repairAmountList: [],
          // reapirAmountParams: {
          //   no_of_pages: 0,
          //   page_size: action.payload.page_size,
          //   page: 1,
          //   search: "",
          // },
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    repairAmountListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.count / state.reapirAmountParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.count,
        repairAmountList: action.payload.results,
        loading: false,
        reapirAmountParams: {
          ...state.reapirAmountParams,
          no_of_pages: noofpages,
        },
      };

      // var response = action.payload;
      // var list = [];
      // if (state.reapirAmountParams?.page == 1) {
      //   list = response.results;
      // } else {
      //   list = [...state.repairAmountList, ...response.results];
      // }
      // var noofpages = Math.ceil(
      //   action.payload.count / state.reapirAmountParams?.page_size
      // );
      // return {
      //   ...state,
      //   repairAmountList: list,
      //   listCount: response.count,
      //   loading: false,
      //   reapirAmountParams: {
      //     ...state.reapirAmountParams,
      //     no_of_pages: noofpages,
      //   },
      // };
    },
    SetRepairAmountParams: (state, action) => {
      return {
        ...state,
        reapirAmountParams: action.payload,
      };
    },

    repairAmountAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    repairAmountAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {
          repairamountitems: [
            {
              index: 0,
              repair: {},
              amount: "",
              description: "",
              dodelete: false,
            },
          ],
        },
      };
    },
    getRepairAmountByID: (state) => {
      return {
        ...state,
        loading: true,
        // rowdata: {},
      };
    },
    repairAmountByIDSuccessful: (state, action) => {
      console.log(action.payload.response)
      var repairItems = action.payload.response.repairamountitems?.map(
        (item, index) => {
          return {
            index: index + 1,
            id: item.id,
            repair: item.repair,
            amount: item.amount,
            description: item.description,
            dodelete: false,
          };
        }
      );
      return {
        ...state,
        // model: true,
        loading: false,
        rowdata: {
          id: action.payload.response.id,
          amount: action.payload.response.amount,
          description: action.payload.response.description,
          purchase_id: action.payload.response.purchase?.id,
          counter_id: action.payload.response.counter?.id,
          // repair: action.payload.repair?.id,
          attachment: action.payload.response.attachment,
          attachment_id: action.payload.response.attachment?.id,
          paymentmode: action.payload.response.paymentmode,
          bank_id: action.payload.response.bank?.id,
          acholdername: action.payload.response.acholdername,
          bankacno: action.payload.response.bankacno,
          ifsccode: action.payload.response.ifsccode,
          repairamountitems: repairItems,
          repairtype: action.payload.response.repairtype,
          repairtype_name: action.payload.response.repairtype_name,
          guarage_id: action.payload.response?.guarage?.id,
          guarage_name: action.payload.response?.guarage?.name,
          majorOrMinorRepair: action.payload.response.is_major_repair ? "is_major_repair" : action.payload.response.is_minor_repair ? "is_minor_repair" : null
        },
        activeRepairAmountItemsCount: repairItems.length,
        rowDataView: action.payload.response,
        model: action.payload.type == "update" ? true : false,
        repairViewModel: action.payload.type == "view" ? true : false,
      };
    },
    isRepairAmountViewModelVisible: (state, action) => {
      if (action.payload == true) {
        return {
          ...state,
          repairViewModel: action.payload,
        };
      } else {
        return {
          ...state,
          repairViewModel: action.payload,
          rowdata: {
            repairamountitems: [
              {
                index: 0,
                repair: {},
                amount: "",
                description: "",
                dodelete: false,
              },
            ],
          },
        };
      }
    },
    repairAmountDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    checkTotalAmount: (state) => {
      var repaitItems = state.rowdata?.repairamountitems.filter(
        (item) => item.dodelete == false
      );
      const sum = repaitItems.reduce(
        (partialSum, a) =>
          partialSum + parseInt(a.amount == "" ? "0" : a.amount),
        0
      );

      return {
        ...state,
        loading: false,
        rowdata: {
          ...state.rowdata,
          amount: sum,
        },
      };
    },
    InputChangeValue: (state, action) => {
      if (action.payload.key == "paymentmode") {
        return {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
            bank_id: null,
            acholdername: null,
            bankacno: null,
            ifsccode: null,
          },
        };
      } else {
        return {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
          },
        };
      }
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {
            repairamountitems: [
              {
                index: 0,
                repair: {},
                amount: "",
                description: "",
                dodelete: false,
              },
            ],
            majorOrMinorRepair: "is_major_repair",
            amount: "",
          },

        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },

    AddRepairAmountRow: (state, action) => {
      // var removeItemList = [];
      if (action.payload.repair?.id != 0) {
        // removeItemList = state.repairTypeList.filter((item) => {
        //   return item.id != action.payload.repair?.id;
        // });
        return {
          ...state,
          rowdata: {
            ...state.rowdata,
            repairamountitems: [
              ...state.rowdata.repairamountitems,
              {
                index:
                  action.payload.index == null ? 0 : action.payload.index + 1,
                repair: {},
                amount: "",
                description: "",
                dodelete: false,
              },
            ],
          },
          activeRepairAmountItemsCount:
            parseInt(state.activeRepairAmountItemsCount) + 1,
          // repairTypeList: removeItemList,
        };
      }
    },
    RemoveRepairAmountRow: (state, action) => {
      // var addItemList = {};
      // state.originalRepairTypeList.filter((item) => {
      //   if (item.id == action.payload.repair?.id) {
      //     addItemList = item;
      //   }
      // });

      var RepairItemList = [];
      if (action.payload.id != null) {
        RepairItemList = state.rowdata.repairamountitems.map((reItem) => {
          if (reItem.index == action.payload.index) {
            return { ...reItem, dodelete: true };
          }
          return reItem;
        });
      } else {
        RepairItemList = state.rowdata.repairamountitems.filter((reItem) => {
          return reItem.index != action.payload.index;
        });
      }

      var activeList = RepairItemList.filter((item) => {
        return !item.dodelete;
      });
      return {
        ...state,
        // repairTypeList: [...state.repairTypeList, addItemList],
        rowdata: {
          ...state.rowdata,
          repairamountitems: RepairItemList,
        },
        activeRepairAmountItemsCount: activeList.length,
      };
    },

    repairAmountApproveStatus: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getRepairTypeImageUpload: (state, action) => {
      return {
        ...state,
        loading: true,
        repairTypeImgLooading: true,
        rowdata: {
          ...state.rowdata,
          [action.payload.imageType]: null,
        },
      };
    },
    RepairTypeInputChangeValue: (state, action) => {
      var RAItems = [];

      RAItems = state.rowdata.repairamountitems.map((RAItem) => {
        if (RAItem.index == action.payload.index) {
          if (action.payload.key == "repair") {
            return {
              ...RAItem,
              repair: { id: action.payload.value, name: action.payload.repair },
            };
          } else {
            return {
              ...RAItem,
              [action.payload.key]: action.payload.value,
            };
          }
        }
        return RAItem;
      });
      return {
        ...state,
        loading: false,
        // repairTypeList: action.payload,
        rowdata: {
          ...state.rowdata,
          repairamountitems: RAItems,
        },
      };
    },
    getRepairTypes: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    repairTypesSuccessful: (state, action) => {
      var repairTypeIds = [];
      if (state.rowdata?.repairamountitems.length != 0) {
        repairTypeIds = state.rowdata?.repairamountitems
          ?.filter((item) => !item.dodelete)
          ?.map((repairType) => {
            return repairType.repair?.id;
          });
      }
      var repairTypeListData = action.payload.filter((item) => {
        return repairTypeIds.indexOf(item.id) == -1;
      });
      return {
        ...state,
        loading: false,
        repairTypeList: repairTypeListData,
        originalRepairTypeList: action.payload,
      };
    },
    repairTypeImageUploadSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        repairTypeImgLooading: false,
        rowdata: {
          ...state.rowdata,
          [action.payload.ImageType]: action.payload.response.data,
          [action.payload.ImageType + "_id"]: action.payload.response.data.id,
        },
      };
    },

    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        reapirAmountParams: {
          ...state.reapirAmountParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    fsyncStatusUpdate: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },

    // Repair Amount Approvals

    getPendingRepairAmountList: (state, action) => {
      if (state.pendingReapirAmountParams.page == 1) {
        return {
          ...state,
          loading: true,
          pendingRepairAmountList: [],
          pendingReapirAmountParams: {
            ...state.pendingReapirAmountParams,
            page_size: 10,
            page: 1,
            ...action.payload,
          },
          // pendingReapirAmountParams: {
          //   ...state.pendingReapirAmountParams,
          //   page_size: 10,
          //   page: 1,
          //   search: "",
          // },
          rowDataView: {},
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    pendingRepairAmountListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count /
        state.pendingReapirAmountParams.page_size
      );
      return {
        ...state,
        pendingListCount: action.payload.response.count,
        // pendingRepairAmountList: action.payload.response.results,
        pendingRepairAmountList: [
          ...state.pendingRepairAmountList,
          ...action.payload.response.results,
        ],
        loading: false,
        pendingReapirAmountParams: {
          ...state.pendingReapirAmountParams,
          no_of_pages: noofpages,
        },
      };
    },
    setPendingRepairAmountParams: (state, action) => {
      return {
        ...state,
        pendingReapirAmountParams: action.payload,
      };
    },

    clearRepairAmountApprovals: (state, action) => {
      return {
        ...state,
        loading: true,
        pendingRepairAmountList: [],
        pendingReapirAmountParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
          currentSort: "code",
          sortOrder: "-",
        },
      };
    },

    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    },
  },
});
export const {
  getRepairAmount,
  repairAmountListSuccessful,
  repairAmountAdd,
  repairAmountAddSuccessful,
  getRepairAmountByID,
  repairAmountByIDSuccessful,
  repairAmountDelete,
  checkTotalAmount,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetRepairAmountParams,
  isRepairAmountViewModelVisible,
  repairAmountApproveStatus,
  clearReapirAmountList,
  getRepairTypeImageUpload,
  repairTypeImageUploadSuccessful,
  AddRepairAmountRow,
  RemoveRepairAmountRow,
  RepairTypeInputChangeValue,
  getRepairTypes,
  repairTypesSuccessful,
  isDrawerVisible,
  FilterInputChangeValue,
  fsyncStatusUpdate,

  getPendingRepairAmountList,
  pendingRepairAmountListSuccessful,
  setPendingRepairAmountParams,
  clearRepairAmountApprovals,
} = repairAmountSlice.actions;

export default repairAmountSlice.reducer;
