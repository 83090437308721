import { createSlice } from "@reduxjs/toolkit";

export const dealersSlice = createSlice({
  name: "dealers",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    dealerslist: [],
    model: false,
    error_msg: null,
    userdata: {},
    listCount: 0,
    searchDealerId: 0,
    dealersParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getDealers: (state, action) => {
      if (state.dealersParams?.page == 1) {
        return {
          ...state,
          loading: true,
          dealerslist: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    dealersListSuccessful: (state, action) => {
      var dealerslist = [];
      var response = action.payload.response;

      if (state.dealersParams?.page == 1) {
        dealerslist = response.results;
      } else {
        dealerslist = [...state.dealerslist, ...response.results];
      }

      var noofpages = Math.ceil(response.count / state.dealersParams.page_size);
      return {
        ...state,
        listCount: response.count,
        dealerslist: dealerslist,

        loading: false,
        dealersParams: {
          ...state.dealersParams,
          no_of_pages: noofpages,
        },
      };
      // }
    },
    clearDealerList: (state, action) => {
      return {
        ...state,
        loading: true,
        dealerslist: [],
      };
    },
    dealerAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    dealerAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        userdata: {},
      };
    },
    dealerEdit: (state, action) => {
      return {
        ...state,
        model: true,
        userdata: {
          id: action.payload?.id,
          username: action.payload?.user.username,
          email: action.payload?.user.email,
          phone: action.payload?.user.phone,
          // group_ids: groupIds,
          first_name: action.payload?.user?.first_name,
          last_name: action.payload?.user?.last_name,
          state_id: action.payload?.user?.state?.id,
          district_id: action.payload?.user.district?.id,
          city_id: action.payload?.user?.city?.id,
          area_id: action.payload?.user?.area?.id,
          address: action.payload?.user?.address,
          pincode: action.payload?.user?.pincode,
          password: action.payload?.user.password,
          bank_id: action.payload?.bank?.id,
          ifsccode: action.payload?.ifsccode,
          gstno: action.payload?.gstno,
          aadharno: action.payload?.aadharno,
          accono: action.payload?.accono,
          // dob: action.payload?.dob,
        },
      };
    },
    dealerEditSuccessful: (state) => {
      return {
        ...state,
        loading: false,
        userdata: {},
      };
    },
    dealerDelete: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    InputChangeValue: (state, action) => {
      if (action.payload.key == "state_id") {
        return {
          ...state,
          userdata: {
            ...state.userdata,
            [action.payload.key]: action.payload.value,
            district_id: null,
            city_id: null,
            area_id: null,
          },
        };
      } else if (action.payload.key == "district_id") {
        return {
          ...state,
          userdata: {
            ...state.userdata,
            [action.payload.key]: action.payload.value,
            city_id: null,
            area_id: null,
          },
        };
      } else if (action.payload.key == "city_id") {
        return {
          ...state,
          userdata: {
            ...state.userdata,
            [action.payload.key]: action.payload.value,
            area_id: null,
          },
        };
      } else {
        return {
          ...state,
          userdata: {
            ...state.userdata,
            [action.payload.key]: action.payload.value,
          },
        };
      }
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          userdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetDealersParams: (state, action) => {
      return {
        ...state,
        dealersParams: action.payload,
      };
    },
    userMobileValidation: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    userEmailValidation: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getDealers,
  dealersListSuccessful,
  dealerAdd,
  dealerAddSuccessful,
  dealerEdit,
  dealerEditSuccessful,
  dealerDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetDealersParams,
  userMobileValidation,
  userEmailValidation,
  clearDealerList,
} = dealersSlice.actions;

export default dealersSlice.reducer;
