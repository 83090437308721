import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
export const stockPriceSlice = createSlice({
  name: "stockPrice",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    model: false,
    drawer: false,
    apiError: {},
    listCount: 0,
    priceCount:0,
    stockPriceList: [],
    stockPriceCount: {},
    stockPriceParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    commentList: [],
    commentParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 5,
      page: 1,
      search: "",
    },
    commentCount: 0,
    ratingViewModel: false,
    ratingList: [],
    ratingParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 5,
      page: 1,
      search: "",
    },
    ratingCount: 0,
    minimumSaleObj: {
      id: ""
    },
    miniModel: false,
    incentiveObj:{
      id: ""
    },
    incentiveModel: false
  },
  reducers: {
    getStockPrice: (state) => {
      if (state.stockPriceParams?.page == 1) {
        return {
          ...state,
          loading: true,
          stockPriceList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    stockPriceSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.stockPriceParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.stockPriceParams?.page_size
      );
      return {
        ...state,
        stockPriceList: list,
        listCount: action.payload.count,
        loading: false,
        stockPriceParams: {
          ...state.stockPriceParams,
          no_of_pages: noofpages,
        },
      };
    },
    setStockPriceParams: (state, action) => {
      return {
        ...state,
        stockPriceParams: action.payload,
      };
    },

    getStockPriceCount: (state, action) => {
        return {
          ...state,
          // loading: true,
          stockPriceCount: {},
        };
    },
    stockPriceCountSuccessful: (state, action) => {
      // var noofpages = Math.ceil(
      //   action.payload.response.count / state.onSiteParams.page_size
      // );
      return {
        ...state,
        // priceCount: action.payload.response.count,
        // leaveList: [
        //   ...state.leaveList,
        //   ...action.payload.response.results,
        // ],
        stockPriceCount: action.payload.response,
        // loading: false,
        // onSiteParams: {
        //   ...state.onSiteParams,
        //   no_of_pages: noofpages,
        // },
      };
    },
    
    

    FilterInputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "counter__zone":
          return {
            ...state,
            stockPriceParams: {
              ...state.stockPriceParams,
              [action.payload.key]: action.payload.value,
              counter__cluster: null,
              counter__division: null,
              counter: null,
            },
          };

        case "counter__division":
          return {
            ...state,
            stockPriceParams: {
              ...state.stockPriceParams,
              [action.payload.key]: action.payload.value,
              counter__cluster: null,
              counter: null,
            },
          };

        case "counter__cluster":
          return {
            ...state,
            stockPriceParams: {
              ...state.stockPriceParams,
              [action.payload.key]: action.payload.value,
              counter: null,
            },
          };
        case "vehicle__version__brand":
          return {
            ...state,
            stockPriceParams: {
              ...state.stockPriceParams,
              [action.payload.key]: action.payload.value,
              vehicle__version__brandfamily: null,
              vehicle__version__model__name: null,
              vehicle__version: null,
            },
          };
        case "vehicle__version__brandfamily":
          return {
            ...state,
            stockPriceParams: {
              ...state.stockPriceParams,
              [action.payload.key]: action.payload.value,
              vehicle__version__model__name: null,
              vehicle__version: null,
            },
          };
        case "vehicle__version__model__name":
          return {
            ...state,
            stockPriceParams: {
              ...state.stockPriceParams,
              [action.payload.key]: action.payload.value,
              vehicle__version: null,
            },
          };
        default:
          return {
            ...state,
            stockPriceParams: {
              ...state.stockPriceParams,
              [action.payload.key]: action.payload.value,
            },
          };
      }
      // return {
      //   ...state,
      //   stockPriceParams: {
      //     ...state.stockPriceParams,
      //     [action.payload.key]: action.payload.value,
      //   },
      // };
    },
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },

    // Comments List API
    getCommentList: (state) => {
      if (state.commentParams?.page == 1) {
        return {
          ...state,
          loading: true,
          commentList: [],
          model: true
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    commentListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.commentParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.commentParams?.page_size
      );
      return {
        ...state,
        commentList: list,
        commentCount: action.payload.count,
        loading: false,
        commentParams: {
          ...state.commentParams,
          no_of_pages: noofpages,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          commentList: [],
        };
      } else {
        return {
          ...state,
          model: action.payload,
          commentList: [],
        };
      }
    },
    setCommentsParams: (state, action) => {
      return {
        ...state,
        commentParams: action.payload,
      };
    },

    // Rating list API
    getRatingList: (state) => {
      if (state.ratingParams?.page == 1) {
        return {
          ...state,
          loading: true,
          ratingList: [],
          ratingViewModel: true
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    ratingListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.ratingParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.ratingParams?.page_size
      );
      return {
        ...state,
        ratingList: list,
        ratingCount: action.payload.count,
        loading: false,
        ratingParams: {
          ...state.ratingParams,
          no_of_pages: noofpages,
        },
      };
    },
    setRatingParams: (state, action) => {
      return {
        ...state,
        ratingParams: action.payload,
      };
    },
    isRatingViewModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          ratingViewModel: action.payload,
          ratingList: [],
        };
      } else {
        return {
          ...state,
          ratingViewModel: action.payload,
          ratingList: [],
        };
      }
    },
    // Update Minimumsale Amount
    InputChangeValue: (state, action) => {
      return {
        ...state,
        minimumSaleObj: {
          ...state.minimumSaleObj,
          [action.payload.key]: action.payload.value
        }
      }
    },
    isMiniAmountModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          miniModel: action.payload.status,
          minimumSaleObj: {
            id: action.payload.id,
          },
        };
      } else {
        return {
          ...state,
          miniModel: action.payload.status,
          minimumSaleObj: {
            id: action.payload.id,
          },
        };
      }
    },
     // Update Special Incentive Amount
     IncentiveInputChangeValue: (state, action) => {
      return {
        ...state,
        incentiveObj: {
          ...state.incentiveObj,
          [action.payload.key]: action.payload.value
        }
      }
    },
    isIncentiveModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          incentiveModel: action.payload.status,
          incentiveObj: {
            id: action.payload.id,
          },
        };
      } else {
        return {
          ...state,
          incentiveModel: action.payload.status,
          incentiveObj: {
            id: action.payload.id,
          },
        };
      }
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getStockPrice,
  stockPriceSuccessful,
  setStockPriceParams,
  isDrawerVisible,
  FilterInputChangeValue,
  getStockPriceCount,
  stockPriceCountSuccessful,
  getCommentList,
  commentListSuccessful,
  setCommentsParams,
  isModelVisible,
  getRatingList,
  ratingListSuccessful,
  setRatingParams,
  isRatingViewModelVisible,
  InputChangeValue,
  isMiniAmountModelVisible,
  IncentiveInputChangeValue,
  isIncentiveModelVisible,
  apiError,
} = stockPriceSlice.actions;

export default stockPriceSlice.reducer;
