import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const backupDatabaseSlice = createSlice({
  name: "backupDatabase",
  initialState: {
    listCount: 0,
    backUpDataBaselist: [],
    locationManagerData: {},
    backupData: {},
    model: false,
    passwordModel: false,
    dataBaseId: 0,
    backUpDataBaseParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getBackUpDataBaseList: (state) => {
      return {
        ...state,
        loading: true,
        backUpDataBaselist: [],
      };
    },
    backUpDataBaseListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.count / state.backUpDataBaseParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.count,
        backUpDataBaselist: action.payload.results,
        loading: false,
        backUpDataBaseParams: {
          ...state.backUpDataBaseParams,
          no_of_pages: noofpages,
        },
      };
    },
    getDataBaseBackUP: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    loadingStatus: (state, action) => {
      return { ...state, loading: action.payload };
    },
    setBackUpDataBaseParams: (state, action) => {
      return {
        ...state,
        backUpDataBaseParams: action.payload,
      };
    },
    isPasswordModel: (state, action) => {
      console.log("action.payload.db_file", action.payload.Id);
      if (!action.payload.model) {
        return {
          ...state,
          passwordModel: action.payload.model,
          backupData: {},
          dataBaseId: 0,
          verifyPwdloading: false
        };
      } else {
        return {
          ...state,
          passwordModel: action.payload.model,
          dataBaseId: action.payload.Id
       
        };
      }
    },
    verifyPassword: (state) => {
      return {
        ...state,
        verifyPwdloading: true,
      };
    },
    InputChangeValue: (state, action) => {
      return{
        ...state,
        backupData: {
          ...state.backupData,
          [action.payload.key]: action.payload.value,
        },
      }
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload, verifyPwdloading: false };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getBackUpDataBaseList,
  backUpDataBaseListSuccessful,
  setBackUpDataBaseParams,
  getDataBaseBackUP,
  loadingStatus,
  isPasswordModel,
  verifyPassword,
  InputChangeValue,
  apiError,
} = backupDatabaseSlice.actions;

export default backupDatabaseSlice.reducer;
