import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
export const rtoSlice = createSlice({
  name: "requestStock",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    model: false,
    drawer: false,
    apiError: {},
    listCount: 0,
    stockRequestList: [],
    stockRequestParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    rtoData: {},
    rtoUpdateData: {},
  },
  reducers: {
    getStockRequestList: (state) => {
      if (state.stockRequestParams?.page == 1) {
        return {
          ...state,
          loading: true,
          stockRequestList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    stockRequestListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.stockRequestParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.stockRequestParams?.page_size
      );
      return {
        ...state,
        stockRequestList: list,
        listCount: action.payload.count,
        loading: false,
        stockRequestParams: {
          ...state.stockRequestParams,
          no_of_pages: noofpages,
        },
      };
    },
    setStockRequestParams: (state, action) => {
      return {
        ...state,
        stockRequestParams: action.payload,
      };
    },






    // InputChangeValue: (state, action) => {
    //   return {
    //     ...state,
    //     rtoUpdateData: {
    //       ...state.rtoUpdateData,
    //       [action.payload.key]: action.payload.value,
    //     },
    //   };
    // },
    // isModelVisible: (state, action) => {
    //   if (!action.payload) {
    //     return {
    //       ...state,
    //       model: action.payload,
    //       rtoUpdateData: {},
    //     };
    //   } else {
    //     return {
    //       ...state,
    //       model: action.payload,
    //     };
    //   }
    // },
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        stockRequestParams: {
          ...state.stockRequestParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getStockRequestList,
  stockRequestListSuccessful,
  setStockRequestParams,
  // InputChangeValue,
  // isModelVisible,
  isDrawerVisible,
  FilterInputChangeValue,
  apiError,
} = rtoSlice.actions;

export default rtoSlice.reducer;
