import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Select, Card, Progress, Typography } from "antd";
import Chart from "react-apexcharts";
import {
  dashboardSlice,
  getPurchaseAnalytics,
  getSaleAnalytics,
  InputChangeValue,
} from "../../store/dashboard/dashboardSlice";

function AnalyticsCard(props) {
  const { Text, Link } = Typography;
  const [cardType, setCardType] = useState(1);

  var dataList = [
    { id: 1, name: "Sale" },
    { id: 2, name: "Purchase" },
  ];

  return (
    <>
      <Card>
        <Row gutter={[0, 16]} align="middle" justify="space-between">
          <Col sm={12} span={24}>
            <Text className="h5" strong>
              {cardType == 1 ? "Sale" : "Purchase"} {props.title}
            </Text>
          </Col>
          <Col>
            <Select
              // showSearch

              style={{ width: 160 }}
              defaultActiveFirstOption={false}
              showArrow={true}
              filterOption={false}
              placeholder="Select"
              onChange={(value) => {
                setCardType(value);
                if (parseInt(value) == 1) {
                  props.getSaleAnalytics();
                } else {
                  props.getPurchaseAnalytics();
                }
              }}
              defaultValue={cardType}
            >
              {dataList.map((item, index) => {
                return (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>

        <Row gutter={[0, 12]} className="hp-mt-12 hp-p-0">
          <Col span={24}>
            <div id="chart">
              <Chart
                options={{
                  chart: {
                    fontFamily: "Manrope, sans-serif",
                    type: "line",

                    toolbar: {
                      show: false,
                    },
                    zoom: {
                      enabled: false,
                    },
                  },
                  stroke: {
                    curve: "smooth",
                    lineCap: "round",
                    width: 2,
                  },
                  colors: [
                    "#148DE6",
                    "#CB66DA",
                    "#5DC4A1",
                    "#B96A6F",
                    "#7B3DB9",
                  ],
                  labels: {
                    style: {
                      fontSize: "14px",
                    },
                  },

                  dataLabels: {
                    enabled: false,
                  },

                  grid: {
                    borderColor: "#DFE6E9",
                    row: {
                      opacity: 0.5,
                    },
                  },

                  markers: {
                    strokeWidth: 0,
                    size: 4,
                    colors: [
                      "#148DE6",
                      "#CB66DA",
                      "#5DC4A1",
                      "#B96A6F",
                      "#7B3DB9",
                    ],
                    hover: {
                      sizeOffset: 1,
                    },
                  },
                  xaxis: {
                    axisTicks: {
                      show: false,
                      borderType: "solid",
                      color: "#78909C",
                      height: 6,
                      offsetX: 0,
                      offsetY: 0,
                    },

                    tickPlacement: "between",
                    labels: {
                      style: {
                        colors: ["636E72"],
                        fontSize: "14px",
                      },
                    },
                    categories: props.categoryData?.map((d) => d),
                  },
                  responsive: [
                    {
                      breakpoint: 426,
                      options: {
                        legend: {
                          itemMargin: {
                            horizontal: 16,
                            vertical: 8,
                          },
                        },
                      },
                    },
                  ],
                  legend: {
                    horizontalAlign: "right",
                    offsetX: 40,
                    position: "top",
                  },
                  yaxis: {
                    labels: {
                      style: {
                        colors: ["636E72"],
                        fontSize: "14px",
                      },
                      formatter: (value) => {
                        return value;
                      },
                    },
                    tickAmount: 5,
                  },
                }}
                series={props.data.map((d) => d)}
                type="line"
                height={350}
                legend="legend"
              />
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
}
function mapStateToProps({ dashboard }) {
  return {
    analyticsData: dashboard.analyticsData,
  };
}

export default connect(mapStateToProps, {
  getPurchaseAnalytics,
  getSaleAnalytics,
  InputChangeValue,
})(AnalyticsCard);
