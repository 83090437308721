import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const salesquotationlistSlice = createSlice({
  name: "salequotation",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    salequotList: [],
    OVFSalequotList: [],
    model: false,
    viewModel: false,
    drawer: false,
    activeTab: "1",
    error_msg: null,
    rowdata: { discountamount: 0 },
    enquiryByIdData: {},
    vehicleVersion: {},
    purchaseData: {},
    minSaleAmount: 0,
    maxSaleAmount: 0,
    maxFinanceAmount: 0,
    minDownPaymentAmount: 0,
    listCount: 0,
    saleQuotationParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    tenuresObjData: {},
    miniEMIdatesList: [],
    emiDifferenceDays: 0,
    maxOVFDPDUEAmount: 0,
  },
  reducers: {
    getSalequotation: (state, action) => {
      if (state.payload?.page == 1) {
        return {
          ...state,
          loading: true,
          salequotList: [],
          // saleQuotationParams: {
          //   no_of_pages: 0,
          //   page_size: action.payload.page_size,
          //   page: 1,
          //   search: "",
          //   currentSort: "code",
          //   sortOrder: "-",
          // },
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
      // return {
      //   ...state,
      //   loading: true,
      //   salequotList: [],
      // };
    },
    saleQuotationListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload?.count / state.saleQuotationParams.page_size
      );

      return {
        ...state,
        listCount: action.payload?.count,
        salequotList: action.payload?.results,
        loading: false,
        saleQuotationParams: {
          ...state.saleQuotationParams,
          no_of_pages: noofpages,
        },
      };
    },
    clearSalequotation: (state) => {
      return {
        ...state,
        loading: false,
        salequotList: [],
      };
    },
    clearSaleQuotationParams: (state, action) => {
      return {
        ...state,
        loading: false,
        saleQuotationParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
          currentSort: "code",
          sortOrder: "-",
        },
      };
    },
    clearOVFSalequotation: (state) => {
      return {
        ...state,
        loading: false,
        OVFSalequotList: [],
      };
    },
    getOVFSalequotation: (state, action) => {
      if (state.saleQuotationParams?.page == 1) {
        return {
          ...state,
          loading: true,
          OVFSalequotList: [],
          saleQuotationParams: {
            no_of_pages: 0,
            page_size: action.payload.page_size,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
          },
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    OVFSaleQuotationListSuccessful: (state, action) => {
      var response = action.payload.response;
      var list = [];
      if (state.saleQuotationParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.OVFSalequotList, ...response.results];
      }
      var noofpages = Math.ceil(
        response.count / state.saleQuotationParams?.page_size
      );

      return {
        ...state,
        listCount: response.count,
        OVFSalequotList: list,
        // OVFSalequotList: action.payload?.results,
        loading: false,
        saleQuotationParams: {
          ...state.saleQuotationParams,
          no_of_pages: noofpages,
        },
      };
    },
    salequotationAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    saleQuotationAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
        enquiryByIdData: {},
        vehicleVersion: {},
        purchaseData: {},
        minSaleAmount: 0,
        maxSaleAmount: 0,
        maxFinanceAmount: 0,
        minDownPaymentAmount: 0,
      };
    },

    getSaleQuotationByID: (state) => {
      return {
        ...state,
        loading: true,
        rowdata: {},
        enquiryByIdData: {},
        vehicleVersion: {},
        purchaseData: {},
        minSaleAmount: 0,
        maxSaleAmount: 0,
        maxFinanceAmount: 0,
        minDownPaymentAmount: 0,
      };
    },
    saleQuotationByIDSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        rowdata: {
          ...action.payload.response,
          date: moment(action.payload?.response.date),
          counter_id: action.payload.response.counter?.id,
          enquiry_id: action.payload.response.enquiry?.id,
          purchase_id: action.payload.response.purchase?.id,
          saletype:
            action.payload?.response.saletype == null
              ? 3
              : action.payload.response.saletype,
          purchaserate: action.payload.response.purchase?.purchaserate,
          vehicle_id: action.payload.response.vehicle?.id,
          version_id: action.payload.response.version?.id,
          occupation_id: action.payload.response.occupation?.id,
          interestpercentage_id: action.payload.response.interestpercentage?.id,
          vehicle_estimation_id: action.payload.response.vehicle_estimation?.id,
          downpayment_due_amount:
            action.payload.response.downpayment_due_amount,
          downpayment_due_days:
            action.payload.response.saletype != 1
              ? action.payload.response.downpayment_due_days
              : null,
          emi_firstdate:
            action.payload.response.saletype != 1
              ? moment(action.payload.response?.emi_firstdate).format(
                  "DD-MM-YYYY"
                )
              : null,
        },
        model: action.payload.type == "update" ? true : false,
        viewModel: action.payload.type == "view" ? true : false,
        vehicleVersion:
          action.payload?.response.saletype == null
            ? action.payload.response.version
            : action.payload.response.vehicle.version,
        purchaseData: action.payload.response.purchase,
      };
    },

    // get Enquiry By Id

    getEnquiryByID: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    enquiryByIdSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        rowdata: {
          ...state.rowdata,
          customername:
            state.rowdata.id != null
              ? state.rowdata.customername
              : action.payload.customername,
          customermobile:
            state.rowdata.id != null
              ? state.rowdata.customermobile
              : action.payload.customermobile,
          occupation_id:
            state.rowdata.id != null
              ? state.rowdata.occupation_id
              : action.payload.occupation?.id,
        },
        enquiryByIdData: action.payload,
      };
    },

    saleQuotationDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    getVehicleVersion: (state, action) => {
      return {
        ...state,
        loading: false,
        vehicleVersion: action.payload.version,
      };
    },
    getFormulaExecuter: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    FormulaExecuterSuccessful: (state, action, code) => {
      switch (action.payload.formulaCode) {
        case "MINSALEAMOUNT":
          return {
            ...state,
            loading: false,
            minSaleAmount: parseFloat(action.payload.response).toFixed(2),
          };
          break;
        case "MAXSALEAMOUNT":
          return {
            ...state,
            loading: false,
            maxSaleAmount: parseFloat(action.payload.response).toFixed(2),
          };
          break;
        case "MINDOWNPAYMENTAMOUNT":
          return {
            ...state,
            loading: false,
            minDownPaymentAmount: parseFloat(action.payload.response).toFixed(
              2
            ),
          };
          break;
        case "GSTAMOUNT":
          return {
            ...state,
            loading: false,
            rowdata: {
              ...state.rowdata,
              gstamount: parseFloat(action.payload.response).toFixed(2),
            },
          };
          break;
        case "DOCUMENTCHARGES":
          return {
            ...state,
            loading: false,
            rowdata: {
              ...state.rowdata,
              documentationcharges: parseFloat(action.payload.response).toFixed(
                2
              ),
            },
          };
          break;
        case "FINANCEAMOUNT":
          return {
            ...state,
            loading: false,
            rowdata: {
              ...state.rowdata,
              financeamount: parseFloat(action.payload.response).toFixed(2),
            },
          };
          break;
        case "MAXOVFDPDUEAMOUNT":
          return {
            ...state,
            loading: false,
            maxOVFDPDUEAmount: parseFloat(action.payload.response).toFixed(2),
            rowdata: {
              ...state.rowdata,
              financeamount: null,
            },
          };
          break;
        case "OVFFINANCEAMOUNT":
          return {
            ...state,
            loading: false,
            rowdata: {
              ...state.rowdata,
              financeamount: parseFloat(action.payload.response).toFixed(2),
            },
          };
          break;
        // case "EMIAMOUNT":
        //   return {
        //     ...state,
        //     loading: false,
        //     rowdata: {
        //       ...state.rowdata,
        //       emi_amount: action.payload.response.error
        //         ? null
        //         : parseFloat(action.payload.response.result).toFixed(2),
        //       totalrepaymentamount: action.payload.response.error
        //         ? null
        //         : parseFloat(
        //             action.payload.response.result *
        //               parseInt(state.rowdata.tenures)
        //           ).toFixed(2),
        //     },
        //   };
        //   break;
        // case "EMIAMOUNT":
        //   var emi_amount = action.payload.response.result;
        //   var interstPerDay =
        //     (emi_amount * parseInt(state.rowdata.tenures) -
        //       state.rowdata.total) /
        //     parseInt(state.rowdata.tenures) /
        //     30;
        //   var extraInterst = interstPerDay * state.emiDifferenceDays;
        //   var totalEmiAmount =
        //     emi_amount + extraInterst / parseInt(state.rowdata.tenures);
        //   if (state.rowdata.saletype != 1) {
        //     return {
        //       ...state,
        //       loading: false,
        //       rowdata: {
        //         ...state.rowdata,
        //         emi_amount: parseFloat(totalEmiAmount).toFixed(),
        //         // emi_amount: parseFloat(action.payload.response.result).toFixed(
        //         //   2
        //         // ),
        //         totalrepaymentamount: action.payload.response.error
        //           ? null
        //           : parseFloat(
        //               totalEmiAmount * parseInt(state.rowdata.tenures)
        //             ).toFixed(),
        //         interest_amount: parseFloat(
        //           state.rowdata.totalrepaymentamount -
        //             state.rowdata.total +
        //             extraInterst
        //         ).toFixed(),
        //       },
        //     };
        //   }
        // else {
        //   return {
        //     ...state,
        //     loading: false,
        //     rowdata: {
        //       ...state.rowdata,
        //       emi_amount: parseFloat(totalEmiAmount).toFixed(),
        //       totalrepaymentamount: action.payload.response.error
        //         ? null
        //         : parseFloat(
        //             totalEmiAmount * parseInt(state.rowdata.tenures)
        //           ).toFixed(),
        //     },
        //   };
        // }

        case "EMIAMOUNT":
          var emi_amount = action.payload.response;
          var interstPerDay =
            (emi_amount * parseInt(state.rowdata.tenures) -
              state.rowdata.total) /
            parseInt(state.rowdata.tenures) /
            30;
          var extraInterst = interstPerDay * state.emiDifferenceDays;
          var totalEmiAmount =
            emi_amount + extraInterst / parseInt(state.rowdata.tenures);
          // return {
          //   ...state,
          //   loading: false,
          //   rowdata: {
          //     ...state.rowdata,
          //       emi_amount: parseFloat(totalEmiAmount).toFixed(2),
          //       totalrepaymentamount: action.payload.response.error
          //         ? null
          //         : parseFloat(
          //           totalEmiAmount * parseInt(state.rowdata.tenures)).toFixed(2),
          //   },
          // };
          if (state.rowdata.saletype != 1) {
            return {
              ...state,
              loading: false,
              rowdata: {
                ...state.rowdata,
                emi_amount: parseFloat(totalEmiAmount).toFixed(),
                totalrepaymentamount: parseFloat(
                  totalEmiAmount * parseInt(state.rowdata.tenures)
                ).toFixed(),
                interest_amount: parseFloat(
                  parseFloat(totalEmiAmount * parseInt(state.rowdata.tenures)) -
                    state.rowdata.total
                ).toFixed(),
              },
            };
          }

          break;
        case "MAXSALEAMOUNT_MINISALEAMOUNT":
          return {
            ...state,
            loading: false,
            maxSaleAmount: parseFloat(action.payload.response).toFixed(2),
          };
          break;
        case "MAXFINANCEAMOUNT":
          return {
            ...state,
            loading: false,
            maxFinanceAmount: parseFloat(action.payload.response).toFixed(2),
          };
          break;

        case "SALEPROFIT":
          return {
            ...state,
            loading: false,
            rowdata: {
              ...state.rowdata,
              profit: parseFloat(action.payload.response).toFixed(2),
            },
          };
          break;
        case "CUSTOMERPAYAMOUNT":
          if (state.rowdata.saletype == 1) {
            return {
              ...state,
              loading: false,
              rowdata: {
                ...state.rowdata,
                total: parseFloat(action.payload.response).toFixed(2),
              },
            };
          } else {
            return {
              ...state,
              loading: false,
              rowdata: {
                ...state.rowdata,
                total: parseFloat(action.payload.response).toFixed(2),
                tenures: null,
                interestpercentage_id: null,
                interesttype: null,
                emi_firstdate: null,
                emi_amount: null,
                interest_amount: null,
                totalrepaymentamount: null,
              },
            };
          }
          break;
        default:
          break;
      }
    },
    getPurchaseByID: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          vehicle_id: action.payload?.vehicle?.id,
          stockvalue: action.payload.stockvalue,
          purchaserate: action.payload.total,
        },
        minSaleAmount: parseInt(action.payload?.minimumsaleamount),
        vehicleVersion: action.payload.vehicle.version,
      };
    },

    InputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "counter_id":
          return {
            ...state,
            rowdata: {
              [action.payload.key]: action.payload.value,
            },
            vehicleVersion: {},
            enquiryByIdData: {},
            purchaseData: {},
            minSaleAmount: 0,
            maxSaleAmount: 0,
            maxFinanceAmount: 0,
            minDownPaymentAmount: 0,
          };
          break;
        case "saletype":
          return {
            ...state,
            rowdata: {
              discountamount: 0,
              counter_id: state.rowdata.counter_id,
              [action.payload.key]: action.payload.value,
            },
            vehicleVersion: {},
            enquiryByIdData: {},
            purchaseData: {},
            minSaleAmount: 0,
            maxSaleAmount: 0,
            maxFinanceAmount: 0,
            minDownPaymentAmount: 0,
          };
          break;
        case "tenures":
          return {
            ...state,
            rowdata: {
              ...state.rowdata,
              [action.payload.key]: action.payload.value,
              interestpercentage_id: null,
              interesttype: null,
              emi_firstdate: null,
              emi_amount: null,
              interest_amount: null,
              totalrepaymentamount: null,
            },
            emiDifferenceDays: 0,
          };
          break;
        case "interesttype":
          return {
            ...state,
            rowdata: {
              ...state.rowdata,
              [action.payload.key]: action.payload.value,
              interestpercentage_id: null,
              emi_firstdate: null,
              interestfixed: null,
              emi_amount: null,
              interest_amount: null,
              totalrepaymentamount: null,
            },
            emiDifferenceDays: 0,
          };
          break;
        case "emi_firstdate":
          return {
            ...state,
            rowdata: {
              ...state.rowdata,
              [action.payload.key]: action.payload.value,
              emi_date: parseInt(
                moment(action.payload.value, "DD-MM-YYYY").format("DD")
              ),
            },
          };
          break;

        default:
          return {
            ...state,
            rowdata: {
              ...state.rowdata,
              [action.payload.key]: action.payload.value,
            },
          };
          break;
      }
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
          enquiryByIdData: {},
          vehicleVersion: {},
          purchaseData: {},
          minSaleAmount: 0,
          maxSaleAmount: 0,
          maxFinanceAmount: 0,
          minDownPaymentAmount: 0,
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    isSaleQuotationDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
          // enquiryData: {},
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    SaleQuotationFilterInputChangeValue: (state, action) => {
      return {
        ...state,
        saleQuotationParams: {
          ...state.saleQuotationParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    setSaleQuotationParams: (state, action) => {
      return {
        ...state,
        saleQuotationParams: action.payload,
      };
    },
    saleQuotationActiveTab: (state, action) => {
      return {
        ...state,
        loading: false,
        activeTab: action.payload,
      };
    },
    saleQuotationViewModel: (state, action) => {
      if (action.payload == true) {
        return {
          ...state,
          viewModel: action.payload,
        };
      } else {
        return {
          ...state,
          viewModel: action.payload,
          rowdata: {},
          enquiryByIdData: {},
          vehicleVersion: {},
          purchaseData: {},
          minSaleAmount: 0,
          maxSaleAmount: 0,
          maxFinanceAmount: 0,
          minDownPaymentAmount: 0,
        };
      }
    },
    getTenureData: (state, action) => {
      return {
        ...state,
        loading: false,
        tenuresObjData: action.payload,
      };
    },
    getTotalReestimationAmountByPurchase: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    TotalReestimationAmountByPurchaseSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        rowdata: {
          ...state.rowdata,
          re_amount: action.payload.amount,
        },
      };
    },
    getTotalRepairAmountByPurchase: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    TotalRepairAmountByPurchaseSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        rowdata: {
          ...state.rowdata,
          repair_amount:
            action.payload.pendingcount !== 0 ? null : action.payload.amount,
        },
      };
    },
    getMiniEMIdates: (state) => {
      return {
        ...state,
        miniEMIdatesList: [],
        loading: true,
      };
    },
    miniEMIdatesSuccessful: (state, action) => {
      return {
        ...state,
        miniEMIdatesList: action.payload,
        loading: false,
      };
    },
    setEmiDifferenceDays: (state, action) => {
      return {
        ...state,
        emiDifferenceDays: action.payload,
      };
    },
    getMaxSaleAmount: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    getMinSaleAmount: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    getMaxSaleAmountInMinSale: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    getMinDownPaymentAmount: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    getGSTAmount: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getSaleProfit: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    getFinanceAmount: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    getEMIAmount: (state, action) => {
      console.log("getEMIAmount", action.payload);
      return {
        ...state,
        loading: false,
      };
    },
    getSaleCustomerPayAmount: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    getDocumentCharges: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    getMaxFinanceAmount: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    getMaxOVFDPDueAMount: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    getOVFFinanceAmount: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getSalequotation,
  saleQuotationListSuccessful,
  getOVFSalequotation,
  OVFSaleQuotationListSuccessful,
  salequotationAdd,
  saleQuotationAddSuccessful,
  getSaleQuotationByID,
  saleQuotationByIDSuccessful,
  saleQuotationDelete,
  getFormulaExecuter,
  FormulaExecuterSuccessful,
  getPurchaseByID,
  InputChangeValue,
  apiError,
  isModelVisible,
  getVehicleVersion,
  isSaleQuotationDrawerVisible,
  SaleQuotationFilterInputChangeValue,
  setSaleQuotationParams,
  saleQuotationActiveTab,
  saleQuotationViewModel,
  getEnquiryByID,
  enquiryByIdSuccessful,
  clearSalequotation,
  clearOVFSalequotation,
  clearSaleQuotationParams,
  getTenureData,
  getTotalReestimationAmountByPurchase,
  TotalReestimationAmountByPurchaseSuccessful,
  getTotalRepairAmountByPurchase,
  TotalRepairAmountByPurchaseSuccessful,
  getMiniEMIdates,
  miniEMIdatesSuccessful,
  setEmiDifferenceDays,
  getMaxSaleAmount,
  getMinSaleAmount,
  getMaxSaleAmountInMinSale,
  getMinDownPaymentAmount,
  getGSTAmount,
  getSaleProfit,
  getEMIAmount,
  getFinanceAmount,
  getSaleCustomerPayAmount,
  getDocumentCharges,
  getMaxFinanceAmount,
  getMaxOVFDPDueAMount,
  getOVFFinanceAmount,
} = salesquotationlistSlice.actions;

export default salesquotationlistSlice.reducer;
