import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  apiError,
  getRepairAmount,
  repairAmountListSuccessful,
  repairAmountAddSuccessful,
  isModelVisible,
  repairAmountByIDSuccessful,
  isRepairAmountViewModelVisible,
  repairTypeImageUploadSuccessful,
  repairTypesSuccessful,
  pendingRepairAmountListSuccessful,
  getPendingRepairAmountList,
  getRepairAmountByID,
} from "./repairAmountSlice";

// Auth Related Methods

import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
  getList,
  postFormData,
} from "../../../helpers/Helper";
import moment from "moment";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

function* RepairAmount({ payload }) {
  // var data = payload;
  // var params = {};

  // for (const k in data) {
  //   if (Object.hasOwnProperty.call(data, k)) {
  //     if (data[k] !== "" && k !== null && k !== "no_of_pages") {
  //       params[k] = data[k];
  //     }
  //   }
  // }
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/stocktransfer/repairamount/",
      params
    );
    yield put(repairAmountListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddRepairAmount({ payload }) {
  try {
    if (payload.id == 0) {
      const response = yield call(
        postAdd,
        "/stocktransfer/repairamount/",
        payload
      );
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Repair Amount Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getRepairAmount({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Repair Amount. Please try again!
              </p>
            </div>
          ),
        });
      }
      yield put(repairAmountAddSuccessful(null));
    } else {
      const response = yield call(
        postEdit,
        "/stocktransfer/repairamount/" + payload.id,
        payload
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Repair Amount Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getRepairAmount({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Repair Amount. Please try again!
              </p>
            </div>
          ),
        });
      }
      yield put(repairAmountAddSuccessful(null));
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* DeleteRepairAmount({ payload }) {
  var areaID = payload;
  try {
    const response = yield call(
      postDelete,
      "/stocktransfer/repairamount/" + areaID
    );

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Repair Amount has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getRepairAmount({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Repair Amount has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

function* RepairAmountByID({ payload }) {
  console.log(payload)
  try {
    const response = yield call(
      getList,
      "/stocktransfer/repairamount/" + payload.id,
      {}
    );
    console.log(response);
    yield put(repairAmountByIDSuccessful({ response, type: payload.type }));
  } catch (error) {
    console.log(error);
    yield put(apiError(error));
  }
}

function* RepairAmountApprove({ payload }) {
  try {
    const response = yield call(
      postEdit,
      "/stocktransfer/repairsapprove/" + payload.id,
      payload.repairAmountObj
    );
    if (response.status === 200) {
      if (payload.repairAmountObj.repairapprovalstatus == 2) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Repair Amount has been Approved Successfully!
              </p>
            </div>
          ),
        });
      } else if (payload.repairAmountObj.repairapprovalstatus == 3) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Repair Amount has been Rejected Successfully!
              </p>
            </div>
          ),
        });
      }
      if (payload.type == "update") {
        yield put(getRepairAmount({ page: 1, search: "", page_size: 10 }));
      } else {
        // yield put(getRepairAmountByID({ id: payload, type: payload.type }));
        yield put(
          getPendingRepairAmountList({ page: 1, search: "", page_size: 10 })
        );
      }
      yield put(isRepairAmountViewModelVisible(false));
    } else {
      if (payload.repairAmountObj.repairapprovalstatus == 2) {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Your Repair Amount has been not Approved !
              </p>
            </div>
          ),
        });
      } else if (payload.repairAmountObj.repairapprovalstatus == 3) {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Your Repair Amount has been not Rejected!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

function* RepairTypeImageUpload({ payload }) {
  try {
    const response = yield call(postFormData, "/system/attachment/", {
      file: payload.file,
    });

    yield put(
      repairTypeImageUploadSuccessful({
        response,
        ImageType: payload.imageType,
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}
function* RepairType({ payload }) {
  try {
    const response = yield call(getList, "/masters/mini/repairs/", {});

    yield put(repairTypesSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* UpdateFsyncStatus({ payload }) {
  try {
    const response = yield call(
      postEdit,
      "/stocktransfer/repairsreapprove/" + payload.id,
      { repairapprovalstatus: payload.repairapprovalstatus }
    );
    if (response.status === 200) {
      if (response.data.focus_post == true) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Selected repair amount is successfully synchronised into focus
              </p>
            </div>
          ),
        });
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! selected repair amount is not posted on focus. please try
                again!
              </p>
            </div>
          ),
        });
      }

      yield put(getRepairAmount({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! selected repair amount is not posted on focus. please try
              again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

// Pending Repair Amount Approvals

function* PendingRepairAmountList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/stocktransfer/repairspendingstatuslist",
      params
    );
    yield put(pendingRepairAmountListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* watchGetRepairAmount() {
  yield takeEvery("repairAmount/getRepairAmount", RepairAmount);
}

export function* watchAddRepairAmount() {
  yield takeEvery("repairAmount/repairAmountAdd", AddRepairAmount);
}

export function* watchGetRepairAmountByID() {
  yield takeEvery("repairAmount/getRepairAmountByID", RepairAmountByID);
}

export function* watchRepairAmountDelete() {
  yield takeEvery("repairAmount/repairAmountDelete", DeleteRepairAmount);
}

export function* watchRepairAmountApproveStatus() {
  yield takeEvery(
    "repairAmount/repairAmountApproveStatus",
    RepairAmountApprove
  );
}

export function* watchGetRepairTypeImageUpload() {
  yield takeEvery(
    "repairAmount/getRepairTypeImageUpload",
    RepairTypeImageUpload
  );
}

export function* watchGetRepairTypes() {
  yield takeEvery("repairAmount/getRepairTypes", RepairType);
}
export function* watchFsyncStatusUpdate() {
  yield takeEvery("repairAmount/fsyncStatusUpdate", UpdateFsyncStatus);
}
export function* watchGetPendingRepairAmountList() {
  yield takeEvery(
    "repairAmount/getPendingRepairAmountList",
    PendingRepairAmountList
  );
}
function* RepairAmountSaga() {
  yield all([
    fork(watchGetRepairAmount),
    fork(watchAddRepairAmount),
    fork(watchGetRepairAmountByID),
    fork(watchRepairAmountDelete),
    fork(watchRepairAmountApproveStatus),
    fork(watchGetRepairTypeImageUpload),
    fork(watchGetRepairTypes),
    fork(watchFsyncStatusUpdate),
    fork(watchGetPendingRepairAmountList),
  ]);
}

export default RepairAmountSaga;
