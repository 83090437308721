import { createSlice } from "@reduxjs/toolkit";

export const citySlice = createSlice({
  name: "city",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    citieslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    cityParams: {
      no_of_pages: 0,
      listCount: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getCities: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    cityListSuccessful: (state, action) => {
      var noofpages = Math.ceil(action.payload.count / state.cityParams.page_size);

      return {
        ...state,
        listCount: action.payload.count,
        citieslist: action.payload.results,
        loading: false,
        cityParams: {
          ...state.cityParams,
          no_of_pages: noofpages,
        },
      };
    },
    cityAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    cityAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    cityEdit: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: {
         ...action.payload,
         district_id: action.payload.district.id
        }

      };
    },
    cityEditSuccessful: (state) => {
      return {
        ...state,
        loading: false,
        rowdata: {},
      };
    },
    cityDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetCityParams: (state, action) => {
      return {
        ...state,
        cityParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    },
  },
});
export const {
  getCities,
  cityListSuccessful,
  cityAdd,
  cityAddSuccessful,
  cityEdit,
  cityEditSuccessful,
  cityDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetCityParams
} = citySlice.actions;

export default citySlice.reducer;
