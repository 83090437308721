import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States

import {
  apiError,
  getApprovalList,
  approvalListSuccessful,
  approvalbyIdSuccessful,
  isModelVisible,
} from "./approvalSlice";

// AUTH related methods
import {
  postAdd,
  getList,
  getParamsList,
  postEdit,
  patchEdit,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

function* approvalList({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/permissions/approval/",
      params
    );
    yield put(approvalListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* addApproval({ payload }) {
  try {
    if (payload.id == 0) {
      const response = yield call(postAdd, "/permissions/approval/", payload);

      if (response.status == 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Approval Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(isModelVisible(false));
        yield put(getApprovalList({ page: 1, search: "", page_size: 10 }));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to add Approval. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response2 = yield call(
        postEdit,
        "/permissions/approval/" + payload.id,
        payload
      );

      if (response2.status == 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Approval Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(isModelVisible(false));
        yield put(getApprovalList({ page: 1, search: "", page_size: 10 }));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Approval. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    yield put(apiError(error.status));
    console.log("errordfgdsfggsd fg dsfgdfgdf df dfgdfg", error);
    if (error.response.status == 400) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
            {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    } else if (error.response.status == 500) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
  }
}
function* getApprovalbyId({ payload }) {
  try {
    const response = yield call(
      getList,
      "/permissions/approval/" + payload,
      {}
    );
    yield put(approvalbyIdSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* deleteApproval({ payload }) {
  try {
    const response = yield call(postDelete, "/permissions/approval/" + payload);

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Approval has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getApprovalList());
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Approval has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

export function* watchGetApprovalList() {
  yield takeEvery("approval/getApprovalList", approvalList);
}
export function* watchApprovalAdd() {
  yield takeEvery("approval/approvalAdd", addApproval);
}
export function* watchapprovalbyId() {
  yield takeEvery("approval/approvalbyId", getApprovalbyId);
}
export function* watchapprovalDelete() {
  yield takeEvery("approval/approvalDelete", deleteApproval);
}

function* ApprovalSaga() {
  yield all([
    fork(watchGetApprovalList),
    fork(watchApprovalAdd),
    fork(watchapprovalbyId),
    fork(watchapprovalDelete),
  ]);
}

export default ApprovalSaga;
