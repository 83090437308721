import React from "react";

import { Col, Row, Button } from "antd";
import { Link, useHistory } from "react-router-dom";
import { RiAddCircleFill, RiArrowLeftCircleFill } from "react-icons/ri";
import { isMobile } from "react-device-detect";

export default function PageTitle(props) {
  let history = useHistory();

  const {
    pageTitle,
    pageText,
    goBack,
    addModelTile,
    addModelTile2,
    setIsModalVisible,
    setIsModalVisible2,
  } = props;

  return (
    <Col span={24} className="hp-mt-12">
      <Row>
        <Col span={16} xs={16} md={12} xl={16}>
          {goBack && (
            <Link
              to="#"
              onClick={() => {
                history.goBack();
              }}
              className="action-icon text-primary  hp-mr-12 hp-d-inline-block"
            >
              <RiArrowLeftCircleFill className="remix-icon" size={24} />
            </Link>
          )}
          <Row className="hp-d-inline-block">
            <Col span={24}>
              {pageTitle && <h3 className="hp-mb-2 hp-mt-4">{pageTitle}</h3>}
            </Col>

            <Col span={24}>
              {pageText && <p className="hp-mb-0 hp-p1-body">{pageText}</p>}
            </Col>
          </Row>
        </Col>
        <Col span={8} xs={8} md={12} xl={8}>
          <div className="hp-float-right hp-d-inline-block">
            {addModelTile && (
              <div className="hp-pl-4 hp-d-inline-block">
                <Button
                  className=""
                  type="primary"
                  onClick={() => setIsModalVisible(true)}
                  // block
                  icon={<RiAddCircleFill className="remix-icon" size={22} />}
                >
                  {!isMobile && addModelTile}
                </Button>
              </div>
            )}
            {addModelTile2 && (
              <div className="hp-pl-4 hp-d-inline-block">
                <Button
                  className="hp-pr-12"
                  type="primary"
                  onClick={() => setIsModalVisible2(true)}
                  // block
                  icon={<RiAddCircleFill className="remix-icon" size={22} />}
                >
                  {!isMobile && addModelTile2}
                </Button>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Col>
  );
}
