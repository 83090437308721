import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  stockPriceSuccessful,
  stockPriceCountSuccessful,
  commentListSuccessful,
  isModelVisible,
  ratingListSuccessful,
} from "./stockPriceSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

//If user is login then dispatch redux action's are directly from here.


function* StockPriceGet({ payload }) {
  // var params = {};
  // var data = payload.params;
  // for (const k in payload) {
  //   if (Object.hasOwnProperty.call(payload, k)) {
  //     if (k === "type") {
  //       params.type = payload.type.join(",");
  //     } else {
  //       if (payload[k] !== "" && k !== "no_of_pages") {
  //         params[k] = payload[k];
  //       }
  //     }
  //   }
  // }
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/purchase/stockpricelist",
      params
    );
    yield put(stockPriceSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* StockPriceCountGet({ payload }) {
  // var data = payload;
  // var params = {};
  // for (const k in data) {
  //   if (Object.hasOwnProperty.call(data, k)) {
  //     if (k === "type") {
  //       params.type = data.type.join(",");
  //     } else {
  //       if (data[k] !== "" && k !== "no_of_pages") {
  //         params[k] = data[k];
  //       }
  //     }
  //   }
  // }
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/purchase/stockpricelistcount", params);
    yield put(stockPriceCountSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* CommentList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/purchase/stockcomment/list/",
      params
    );
    yield put(commentListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* RatingList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/purchase/stockrating/listcreate/",
      params
    );
    yield put(ratingListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetStockPrice() {
  yield takeEvery("stockPrice/getStockPrice", StockPriceGet);
}
export function* watchGetStockPriceCount() {
  yield takeEvery("stockPrice/getStockPriceCount", StockPriceCountGet);
}
export function* watchGetCommentList() {
  yield takeEvery("stockPrice/getCommentList", CommentList);
}
export function* watchGetRatingList() {
  yield takeEvery("stockPrice/getRatingList", RatingList);
}
function* StockPriceSaga() {
  yield all([
    fork(watchGetStockPrice),
    fork(watchGetStockPriceCount),
    fork(watchGetCommentList),
    fork(watchGetRatingList),
  ]);
}

export default StockPriceSaga;
