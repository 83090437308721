export const MENU = "menu";
export const INPUT_CHANGEVALUE = "menu_input_ChangeValue";

export const MENU_ADD = "menu_add";
export const MENU_ROW_DATA = "menu_Row_data";

export const MENU_DELETE = "menu_deleted";

export const MENU_SETROWDATA = "menu_set_rowdata";
export const MODEL_OPEN = "menu_model_open";
export const MODEL_CLOSE = "menu_model_close";

export const MENU_LIST_SUCCESSFUL = "menu_list_successfull";
export const MENU_ADD_SUCCESSFUL = "menu_add_successfull";
export const MENU_ROW_DATA_SUCCESSFUL = "menu_row_data_successfull";

export const API_ERROR = "api_failed";
